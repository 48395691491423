import { Empty } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import { getPage } from "../../utils";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { LoaderFull } from "../common";
import { useSelector } from "react-redux";

const CompanyTopUpDetailsReport = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const { companyId } = useParams();
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const loc = useLocation();
  const [limit, setLimit] = useState(16);

  const [topUpData, setTopUpData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTopUpDetails = useCallback(async () => {
    setTopUpData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(`${encodedUri}/topup/all?companyTopUpId=${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTopUpData({ loading: false, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setTopUpData({ loading: false, data: null });
        console.error("Promo: Error", err);
      });
  }, [companyId]);

  useEffect(() => {
    getTopUpDetails();
  }, [companyId, getTopUpDetails]);

  return (
    <React.Fragment>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {topUpData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">SL</th>
                      <th scope="col">Date</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Name</th>
                      <th scope="col">Operator</th>
                      <th scope="col">Type</th>
                      <th scope="col">TopUpId</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {topUpData?.data?.topUps?.length ? (
                      topUpData?.data?.topUps?.map((topUp: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100 cursor-pointer"
                          key={index}
                        >
                          <td>{index + 1}</td>
                          <td>
                            {" "}
                            <span className="name">
                              {moment(topUp?.createdAt).format("lll")}
                            </span>
                          </td>
                          <td>{topUp?.mobileNumber?.split("+88")}</td>
                          <td>{topUp?.name}</td>
                          <td>{topUp?.operator}</td>
                          <td>{topUp?.type}</td>
                          <td>{topUp?.topUpId}</td>
                          <td>&#2547;{parseInt(topUp?.amount)}</td>
                          <td
                            style={
                              topUp?.failedReason
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >
                            {topUp?.failedReason
                              ? topUp?.failedReason
                              : topUp?.status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...topUpData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyTopUpDetailsReport;
