import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import BrandLayout from "../Layout";
import ProductList from "../../Product/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const BrandProductList = (): ReactElement => {
  const { brandId } = useParams();
  // const { categoryInfo } = useSelector((state) => (state as any)?.authReducer);

  // console.log("category infromation ::", categoryInfo);
  return (
    <React.Fragment>
      <BrandLayout
        brandId={brandId as any}
        title="Product List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <ProductList getBrandId={brandId} />
          </div>
        </div> 
      </BrandLayout>
    </React.Fragment>
  );
};

export default BrandProductList;
