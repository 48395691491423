import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Empty, 
  Form,
  Input,
  List,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import Link from "antd/lib/typography/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";
import moment from "moment";
import { Loader, Pagination, TreeSelectTwo } from "../common";
import axios from "axios";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
const { RangePicker } = DatePicker;
interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const categoryListShow = new Array(10).fill(null).map((_, index) => ({
  key: index + 1,
  label: `Category ${index + 1}`,
}));
const ShippingRatesSettings = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [size, setSize] = useState<SizeType>("large");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const isDeleted = "false";
  const [isActive, setIsActive] = useState<any>(undefined);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [brandData, setBrandData] = useState<any>({
    loading: false,
    data: null,
  });
  console.log("type ::", type);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();

  const getBrands = useCallback(async () => {
    setBrandData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/brand?type=${type}` +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (isDeleted ? `&deleted=${isDeleted}` : ``) +
          (page
            ? `&page=${
                page == brandData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBrandData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBrandData({ loading: false, data: [] });
        console.error("Brand: Error", err);
      });
  }, [type, sortBy, sortOrder, isActive, isDeleted, limit, page]);

  useEffect(() => {
    getBrands();
  }, [getBrands]);
  const reseAllFieldData = () => {
    form.resetFields();

    setRange({
      from: null,
      to: null,
    });
  };
  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            className="py-2 inline-block min-w-full sm:px-6 lg:px-8 "
            style={contendStyle}
          >
            <div className="content-body">
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  //   className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <div className="w-full mx-8 py-0">
                    <div className="float-left w-4/5 ">
                      <h3 className="mb-2 mt-0 text-2xl text-primary">
                        General Shipping Rates
                      </h3>
                    </div>
                    <div className="float-left w-1/5">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>

                  <div className="float-left px-8 py-4 w-full">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Type"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Type is Required!",
                        },
                      ]}
                      name="shippingDeliveryType"
                      initialValue={shippingDeliveryType}
                    >
                      <Radio.Group>
                        <Radio value={1}>Fulfillment By Jachai (FBJ)</Radio>
                        <Radio value={2}>Fulfillment By Seller (FBS)</Radio>
                        <Radio value={3}>Fulfillment By Agent/Referral</Radio>
                        <Radio value={4}>Fulfillment By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="float-left w-full px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Packeging"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Packeging is Required!",
                        },
                      ]}
                      name="shippingDeliveryPackeging"
                      initialValue={shippingDeliveryPackeging}
                    >
                      <Radio.Group>
                        <Radio value={10}>Packaging By Jachai (PBJ)</Radio>
                        <Radio value={20}>Packaging By Seller (PBS)</Radio>
                        <Radio value={30}>Packaging By Agent/Referral</Radio>
                        <Radio value={40}>Packaging By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <h3 className="mb-2 mt-0 text-2xl font-medium leading-tight text-primary px-8">
                    Shipping
                  </h3>
                  <div className="float-left w-full px-8">
                    <div className="float-left w-2/5">
                      <Form.Item
                        hasFeedback
                        label="All Product Category"
                        rules={[
                          {
                            required: false,
                            message: "Shop Name is Required!",
                          },
                        ]}
                        name="name"
                      >
                        <Input
                          id="name"
                          type="text"
                          placeholder="Product SKU,  Name,  Barcode, JSIN, UPC, GTIN, EAN, GCID, ISBN "
                        />
                      </Form.Item>
                    </div>
                    <div className="float-left pl-2 w-2/5">
                      <Form.Item name="range" label="Date & Time">
                        <RangePicker
                          defaultValue={
                            range.from != null && range.to != null
                              ? [moment(range.from), moment(range.to)]
                              : null
                          }
                          onChange={(_e, f) => {
                            console.log(
                              "momnet",
                              moment.utc(f[0])?.toISOString()
                            );
                            setRange({
                              from: moment.utc(f[0])?.toISOString(),
                              to: moment.utc(f[1])?.toISOString(),
                            });
                          }}
                          showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [
                              moment("00:00:00", "HH:mm:ss"),
                              moment("23:59:59", "HH:mm:ss"),
                            ],
                          }}
                          inputReadOnly
                          allowClear
                        />
                      </Form.Item>
                    </div>
                    <div className="float-left pt-8 w-1/5">
                      <Button
                        type="primary"
                        className="text-4xl w-14 h-12"
                        icon={<SearchOutlined />}
                        href="https://www.google.com"
                      />
                    </div>
                  </div>
                  <div className="float-left w-full px-8">
                    <Form.Item
                      hasFeedback
                      label="Category"
                      name="categoryId"
                      rules={[
                        {
                          required: !categoryId,
                          message: "Category is required!",
                        },
                      ]}
                      initialValue={categoryId}
                    >
                      <TreeSelectTwo
                        setCategoryId={setCategoryId}
                        value={categoryId}
                      />
                    </Form.Item>
                  </div>
                  <div className="float-left w-full px-8">
                    <div className={styles?.searchBox}>
                      <Menu
                        mode="horizontal"
                        defaultSelectedKeys={["2"]}
                        items={categoryListShow}
                        style={{ flex: 1, minWidth: 0 }}
                      />
                    </div>
                  </div>

                  <div className="float-left w-full px-8">
                    <div className={styles.contentWrapper}>
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                          <div
                            className={
                              showSearch
                                ? `content-body-withSearch`
                                : `content-body`
                            }
                          >
                            {brandData?.loading ? (
                              <Loader />
                            ) : (
                              <table className={styles.mainTable}>
                                <thead className="bg-white border-b">
                                  <tr>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Total Product</th>
                                    <th scope="col">Total Sell</th>

                                    <th scope="col">Status</th>
                                    <th scope="col">Move TO Top</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Created Info</th>
                                    <th scope="col">Updated Info</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {brandData?.data?.brands?.length ? (
                                    brandData?.data?.brands?.map(
                                      (brand: any, index: any) => (
                                        <tr
                                          className="border-t hover:bg-gray-100"
                                          key={index}
                                          onClick={() =>
                                            navigate(
                                              `/brands/${brand?.id}/products`
                                            )
                                          }
                                          draggable="true"
                                        >
                                          <td>
                                            <Avatar
                                              size={40}
                                              src={brand?.logo}
                                            />{" "}
                                            <span className="font-medium text-gray-500 ml-2">
                                              {brand?.name}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="font-medium text-gray-500 ml-2">
                                              {brand?.numberOfProducts}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="font-medium text-gray-500 ml-2">
                                              {brand?.sellCount}
                                            </span>
                                          </td>
                                          {!inCampaign && !inShop && (
                                            <>
                                              <td>
                                                <Switch
                                                  checkedChildren={
                                                    <CheckOutlined />
                                                  }
                                                  unCheckedChildren={
                                                    <CloseOutlined />
                                                  }
                                                  defaultChecked={
                                                    brand?.isActive
                                                  }
                                                  onChange={(val, e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onStatusChange(
                                                      brand?.id,
                                                      val
                                                    );
                                                  }}
                                                  loading={
                                                    confirmLoading &&
                                                    brand?.id === confirmLoading
                                                  }
                                                />
                                              </td>

                                              <td>
                                                <Button
                                                  shape="round"
                                                  type="primary"
                                                  danger
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onBrandMoveToTop(brand?.id);
                                                  }}
                                                >
                                                  <ToTopOutlined />
                                                </Button>
                                              </td>
                                              <td>
                                                <div className="flex gap-2">
                                                  <Button
                                                    shape="round"
                                                    type="dashed"
                                                    className="bg-white"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      setSelectedBrandForEdit(
                                                        brand
                                                      );
                                                    }}
                                                  >
                                                    <EditOutlined />
                                                  </Button>
                                                  {isAllowedService(
                                                    `Brands Delete`
                                                  ) && (
                                                    <Popconfirm
                                                      placement="left"
                                                      title="Are you sure to remove?"
                                                      visible={
                                                        brand?.id ===
                                                        visiblePopconfirm
                                                      }
                                                      onConfirm={(e: any) => {
                                                        console.log(true);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onBrandRemove(
                                                          brand?.id
                                                        );
                                                      }}
                                                      okButtonProps={{
                                                        loading: confirmLoading,
                                                        className:
                                                          "bg-blue-400",
                                                      }}
                                                      onCancel={handleCancel}
                                                    >
                                                      <Button
                                                        className="bg-white"
                                                        type="dashed"
                                                        danger
                                                        shape="round"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          showPopconfirm(
                                                            brand?.id
                                                          );
                                                        }}
                                                      >
                                                        <DeleteOutlined />
                                                      </Button>
                                                    </Popconfirm>
                                                  )}
                                                  {/* <Popconfirm
                              placement="left"
                              title="Are you sure to delete?"
                              visible={brand?.id === visiblePopconfirm}
                              onConfirm={(e: any) => {
                                console.log(true);
                                e.preventDefault();
                                e.stopPropagation();
                                onDelete(brand?.id);
                              }}
                              okButtonProps={{
                                loading: confirmLoading,
                                className: "bg-blue-400",
                              }}
                              onCancel={handleCancel}
                            >
                              <Button
                                className="mx-1"
                                type="dashed"
                                danger
                                shape="round"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  showPopconfirm(brand?.id);
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm> */}
                                                </div>
                                              </td>
                                              <td>
                                                <small className="font-bold uppercase text-gray-500">
                                                  {brand?.createdBy?.name ||
                                                    brand?.createdBy
                                                      ?.mobileNumber}{" "}
                                                  -{" "}
                                                </small>
                                                <small
                                                  className={`font-capitalize ${
                                                    moment().diff(
                                                      brand?.createdAt,
                                                      "minutes"
                                                    ) >= 60
                                                      ? "text-red-600"
                                                      : "text-gray-800"
                                                  }`}
                                                >
                                                  {moment(
                                                    brand?.createdAt
                                                  ).fromNow()}
                                                </small>
                                                <br />
                                                <span className="name">
                                                  {moment(
                                                    brand?.createdAt
                                                  ).format("lll")}
                                                </span>
                                              </td>
                                              <td>
                                                <small className="font-bold uppercase text-gray-500">
                                                  {brand?.updatedBy?.name ||
                                                    brand?.updatedBy
                                                      ?.mobileNumber}{" "}
                                                  -{" "}
                                                </small>
                                                <small
                                                  className={`font-capitalize ${
                                                    moment().diff(
                                                      brand?.updatedAt,
                                                      "minutes"
                                                    ) >= 60
                                                      ? "text-red-600"
                                                      : "text-gray-800"
                                                  }`}
                                                >
                                                  {moment(
                                                    brand?.updatedAt
                                                  ).fromNow()}
                                                </small>
                                                <br />
                                                <span className="name">
                                                  {moment(
                                                    brand?.updatedAt
                                                  ).format("lll")}
                                                </span>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td>
                                        <Empty />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>

                      <Pagination
                        {...brandData?.data}
                        limit={limit}
                        page={getPage(loc.search)}
                      />
                    </div>
                  </div>
                  <p className="float-left w-full mb-2 mt-8 text-primary px-8">
                    To charge different rates for only certain products, create
                    a new profile in shipping settings.
                  </p>
                  <h3 className="mb-2 mt-8 text-3xl font-medium leading-tight text-primary px-8 ">
                    Shipping Origins
                  </h3>

                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-1/2 h-full font-medium">
                      <p className="float-left">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-5xl"
                        />
                      </p>
                      <p className="float-left">
                        <h5 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                          Shop Location/Seller Address
                        </h5>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                          Banglades
                        </p>
                      </p>
                    </p>
                    <p className="float-left px-4 w-1/4 text-center font-medium">
                      Doesn’t offer delivery
                    </p>
                    <p className="float-left px-4 w-1/4 text-right font-medium">
                      Manage
                    </p>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Shipping Zones
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-4/5 h-full font-medium">
                      <div className="float-left w-1/12">
                        <Avatar
                          shape="square"
                          size={64}
                          icon={<UserOutlined />}
                        />
                      </div>
                      <div className="float-left w-11/12">
                        <div className="w-1/5">
                          <Form.Item
                            hasFeedback
                            label="Domestic"
                            rules={[
                              {
                                required: true,
                                message: "Domestic is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add Shipping Rate
                          </Button>
                        </div>
                        <div className="w-1/4">
                          <Form.Item
                            hasFeedback
                            label="Not covered by your shipping zones"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Not covered by your shipping zones is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add more shipping places
                          </Button>
                        </div>
                        <p>
                          Add countries/regions to a market to start selling and
                          manage localized settings, including shipping zones.
                        </p>
                      </div>
                    </div>

                    <div className="float-left px-4 w-1/5 text-right font-medium">
                      Create Zone
                    </div>
                  </Card>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingRatesSettings;
