import React from "react";
import MenuList from "../../components/Main-Menu";
import MainLayout from "../../components/Layouts";

const MainMenuPage = () => {
  return (
    <MainLayout>
      <MenuList />
    </MainLayout>
  );
};

export default MainMenuPage;
