import React, { useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";

interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const ProcessingTime = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [size, setSize] = useState<SizeType>("large");
  console.log("type ::", type);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            className="py-2 inline-block min-w-full sm:px-6 lg:px-8 "
            style={contendStyle}
          >
            <div className="content-body">
              <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
              Processing Time
              </h3>
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Type"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Type is Required!",
                        },
                      ]}
                      name="shippingDeliveryType"
                      initialValue={shippingDeliveryType}
                    >
                      <Radio.Group>
                        <Radio value={1}>Fulfillment By Jachai (FBJ)</Radio>
                        <Radio value={2}>Fulfillment By Seller (FBS)</Radio>
                        <Radio value={3}>Fulfillment By Agent/Referral</Radio>
                        <Radio value={4}>Fulfillment By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Packeging"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Packeging is Required!",
                        },
                      ]}
                      name="shippingDeliveryPackeging"
                      initialValue={shippingDeliveryPackeging}
                    >
                      <Radio.Group>
                        <Radio value={10}>Packaging By Jachai (PBJ)</Radio>
                        <Radio value={20}>Packaging By Seller (PBS)</Radio>
                        <Radio value={30}>Packaging By Agent/Referral</Radio>
                        <Radio value={40}>Packaging By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-full text-left text-2xl">
                      Processing Time
                      </p>
                      <p className="float-left w-full text-left">How long it takes you to process an  order, from the time it is placed to when the package is handed to the carrier. This will be added to the transit time of the carrier.</p>
                    </div>
                    <div className="float-left w-full h-24 border rounded-md mb-4">
                        <p className="float-left w-4/5 text-center p-8 text-2xl">You currently don’t have any shipping rates with transit time. Once you’ve set up transit times, you can then use processing time to show delivery dates to your customers.</p>
                    </div>
                    <div className="float-left w-full">
                    <Checkbox >Use processing time to show delivery dates at checkout</Checkbox>
                    </div>
                    <div className="float-left w-full mt-8">
                    <Form.Item
                            hasFeedback
                            label="Your processing Time"
                            rules={[
                              {
                                required: false,
                                message: "Your processing Time is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                    </div>
                  </Card>

                  <div className="w-full mx-8 py-0">
                    
                    <div className="float-left w-11/12 text-right">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessingTime;
