import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CorporateLayout from "../Layout";
import { getPage, getParamValue } from "../../../utils/index";
import { Button, DatePicker, Empty, Form } from "antd";
import Pagination from "../../common/Pagination/Pagination";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "../../../styles/tailwind/List.module.css";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router";
import TopupCompanyInvoice from "../Invoice/index";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FileDoneOutlined } from "@ant-design/icons";

const CorporateTopupHistoryList = ({ params }: any) => {
  const { RangePicker } = DatePicker;
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [activeSearch, setActiveSearch] = useState(false);
  const startOfMonth = moment.utc().startOf("month").format();
  const endOfMonth = moment.utc().endOf("month").format();
  const [showSearch, setShowSearch] = useState<any>(true);
  const [selectedTopUpHistory, setSelectedTopUpHistory] = useState<any>();

  const [range, setRange] = useState<any>({
    from: moment.utc().startOf("month")?.toISOString() || null,
    to: moment.utc().endOf("month")?.toISOString() || null,
  });

  const [topupData, setTopupData] = useState<any>([
    {
      loading: false,
      data: null,
    },
  ]);

  const [topupInvoiceData, setTopupInvoiceData] = useState<any>();
  const [invoiceEffectiveDate, setInvoiceEffectiveDate] = useState<any>();

  const getTopupHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTopupData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/company-history?companyId=${corporateInfo?.ownerId}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopupData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [range]);

  const companyInvoice = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTopupInvoiceData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/company-invoice?companyId=${corporateInfo?.ownerId}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopupInvoiceData({ loading: false, data: res.data });
        setInvoiceEffectiveDate({ to });
      })
      .catch((err) => {
        setTopupInvoiceData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [range]);

  useEffect(() => {
    getTopupHistory();
  }, [getTopupHistory]);
  useEffect(() => {
    companyInvoice();
  }, [companyInvoice]);
  const onClose = () => {
    setSelectedTopUpHistory(undefined);
    getTopupHistory();
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={corporateInfo?.id}
        title="Topup History List"
        subTitle="Topup History List"
      >
        <>
          {showSearch && (
            <div className={styles?.searchBox}>
              <Form layout="inline" form={form} className={styles.formInline}>
                <Form.Item name="range">
                  <RangePicker
                    onChange={(_e, f) => {
                      // console.log("momnet", moment.utc(f[0])?.toISOString());
                      setRange({
                        from: moment.utc(f[0])?.toISOString(),
                        to: moment.utc(f[1])?.toISOString(),
                      });
                    }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment("00:00:00", "HH:mm:ss"),
                        moment("23:59:59", "HH:mm:ss"),
                      ],
                    }}
                    inputReadOnly
                    allowClear
                  />
                </Form.Item>
              </Form>
              <Button
                type="primary"
                danger
                size="large"
                htmlType="reset"
                onClick={reseAllFieldData}
              >
                Reset
              </Button>

              <PDFDownloadLink
                document={
                  <TopupCompanyInvoice
                    // invoiceData={topupInvoiceData?.data?.companyTopUps}
                    invoiceData={topupInvoiceData}
                    corporateInfo={corporateInfo}
                    invoiceEffectiveDate={invoiceEffectiveDate}
                  />
                }
                fileName={`Invoice`}
              >
                <Button
                  shape="circle"
                  className="bg-transparent border-dashed"
                  // onClick={() =>
                  //   navigate(`/merchants/${data?.id}/invoice`)
                  // }
                >
                  <FileDoneOutlined />
                </Button>
              </PDFDownloadLink>

              {/* <PDFViewer width="1000" height="600" className="app">
              <TopupInvoice invoice={topupData} />
            </PDFViewer> */}
            </div>
          )}
          <div className="content-body rounded-2xl">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
              <div className={styles.contentWrapper}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
                      {topupData?.loading ? (
                        <Loader />
                      ) : (
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              <th scope="col">Date & Time </th>
                              <th scope="col">Number Of Request</th>
                              <th scope="col">Total Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {topupData?.data?.companyTopUps?.length ? (
                              topupData?.data?.companyTopUps?.map(
                                (item: any, index: any) => (
                                  <tr
                                    className="border-t hover:bg-gray-100 cursor-pointer"
                                    key={index}
                                    onClick={() =>
                                      navigate(
                                        `/corporates/${item?.id}/topupdetails`
                                      )
                                    }
                                  >
                                    <td>
                                      <span className="name">
                                        {moment(item?.createdAt).format("lll")}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="name">
                                        {item?.numberOfRequest}
                                      </span>
                                    </td>

                                    <td>
                                      <span className="name text-right">
                                        &#2547; {item?.totalAmount}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>

                <Pagination
                  {...topupData?.data}
                  limit={limit}
                  page={getPage(loc.search)}
                />
              </div>
            </div>
          </div>
        </>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateTopupHistoryList;
