import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Tooltip } from "antd";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";

const AddBrand = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl) {
      setLoading(true);

      const readyData = data && {
        name: data.name,
        color: data.color,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        keyWord: data.keyWord,
        type: type,
        isActive: true,
        logo: generatedImgUrl ? generatedImgUrl : data?.logo,
        bannerImage: bannerImgUrl ? bannerImgUrl : data?.bannerImage,
        description: convertedText || data.description,
        termAndConditions: convertedTextForTnC || data.termAndConditions,
      };

      if (brandData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: brandData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Brand Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Brand Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  useEffect(() => {
    if (visibleData) {
      setBrandData(visibleData);
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [visibleData]);

  useEffect(() => {
    if (brandData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.logo);
    }
  }, [brandData, form, visibleData]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setBannerImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...brandData, 
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Color"
          rules={[
            {
              required: true,
              message: "Color is Required!",
            },
          ]}
          name="color"
        >
          <Input id="color" type="color" placeholder="Enter color code" />
        </Form.Item>

        <Form.Item hasFeedback label="Description" name="description">
          <JoditEditor
            ref={editor}
            value={convertedText}
            config={jodiConfig as any}
            onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Form.Item>

        <Form.Item hasFeedback label="Meta Title" name="metaTitle">
          <Input id="metaTitle" type="text" placeholder="Enter Meta Title" />
        </Form.Item>

        <Form.Item hasFeedback label="Meta Description" name="metaDescription">
          <Input.TextArea
            id="metaDescription"
            placeholder="Enter Meta Description"
            rows={3}
          />
        </Form.Item>
        <Form.Item hasFeedback label="Keywords" name="keyWord">
          <Input.TextArea
            id="keyWord"
            placeholder="Enter Product Keywords"
            rows={3}
          />
        </Form.Item>

        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Icon Image
              <Tooltip
                placement="bottom"
                title={
                  "Icon Image should be square. We prefer height 200px and width 200px (Ratio 1)"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">
              Banner Image
              <Tooltip
                placement="bottom"
                title={"Banner image should 250px/1600px (Ratio 6.4)"}
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              // crop={6.4}
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
            setStartUpload2("Uploading");
          }}
          onClickReset={resetData}
          disabled={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
          loading={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
        />
      </Form>
    </>
  );
};

export default AddBrand;
