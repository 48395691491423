import React from "react";
import MainLayout from "../../components/Layouts";
import PackageList from "../../components/DeliveryShipping/AddPackage";

const AddPackage = () => {
  return (
    <MainLayout>
      <PackageList />
    </MainLayout>
  );
}; 

export default AddPackage;
