import React from "react";
import ServiceList from "../../components/ServiceType/ServiceList";
import MainLayout from "../../components/Layouts";

const ServiceListPage = () => {
  return (
    <MainLayout>
      <ServiceList />
    </MainLayout>
  );
};

export default ServiceListPage;
