import {
  Rate,
  Avatar,
  Layout,
  Tabs,
  Empty,
  Steps,
  Row,
  Col,
  Form,
  Button,
  Input,
  Image,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import { SET_ORDER_INFO } from "../../../redux/auth/authType";
import { useDispatch, useSelector } from "react-redux";
const { Content } = Layout;

const SingleOrder = ({
  selectedOrder,
}: {
  selectedOrder?: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const route = useParams();
  const [singleOrderInfo, setSingleOrderInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchOrderDetails = useCallback((getOrderId: any) => {
    if (getOrderId) {
      try {
        setSingleOrderInfo({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/image-order/details?id=${getOrderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleOrderInfo({
                loading: false,
                data: data?.data?.order,
              });
            } else {
              setSingleOrderInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleOrderInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleOrderInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchOrderDetails((route as any)?.orderId);
  }, [fetchOrderDetails, route]);

  useEffect(() => {
    fetchOrderDetails(selectedOrder?.orderId);
  }, [fetchOrderDetails, selectedOrder]);

  const order = singleOrderInfo?.data;

  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={`Order Details`}
                extra={
                  <Button
                    type="primary"
                    danger
                    shape="round"
                    onClick={() => navigate(`/orders/create`)}
                  >
                    Create Order
                  </Button>
                }
              />
            </div>
          </div>
          <div className={"grid grid-cols-3 xl:grid-cols-3 gap-6 xl:gap-6"}>
            <div
              className={
                "col-span-full xl:col-auto grid gap-6 grid-cols-2 xl:grid-cols-1"
              }
            >
              <div
                className={
                  "bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 col-span-1"
                }
              >
                <div className="flow-root">
                  <div className="flex flex-wrap gap-4">
                    <div className="px-4 pb-2">
                      <div>
                        <h2 className="text-lg font-bold mb-2 text-gray-600">
                          Customer Information
                        </h2>
                        <div className="flex flex-no-wrap py-2">
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-500">
                              <span className="font-bold">Name</span>
                            </div>
                          </div>
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-700">
                              <span>
                                {singleOrderInfo?.data?.customer?.name?.toUpperCase()}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-no-wrap py-2">
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-500">
                              <span className="font-bold">Mobile</span>
                            </div>
                          </div>
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-700">
                              <span>
                                {singleOrderInfo?.data?.customer?.mobileNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-no-wrap py-2">
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-500">
                              <span className="font-bold">Status</span>
                            </div>
                          </div>
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-700">
                              <span>{singleOrderInfo?.data?.status}</span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-no-wrap py-2">
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-500">
                              <span className="font-bold">
                                Shipping Address
                              </span>
                            </div>
                          </div>
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-700">
                              <span>
                                {singleOrderInfo?.data?.shippingAddress}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-no-wrap py-2">
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-500">
                              <span className="font-bold">Order Note</span>
                            </div>
                          </div>
                          <div className="w-2/5 flex-none">
                            <div className="text-gray-700">
                              <span>{singleOrderInfo?.data?.orderNote}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CONTENT AREA */}
            <div className={"col-span-3 xl:col-span-2"}>
              <Layout>
                <Content className="main-content-layout">
                  <div className="content-body_ rounded-2xl">
                    <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
                      <span className="font-semibold text-lg mb-3 block text-gray-600">
                        Requested List
                      </span>
                      {singleOrderInfo?.data?.images?.length ? (
                        <div className="grid grid-cols-4 gap-4">
                          {singleOrderInfo?.data?.images?.map((item: any) => (
                            <div className="shadow-md rounded-md overflow-hidden w-full h-40">
                              <Image
                                // preview={{ visible: false }}
                                width={200}
                                src={item}
                                // onClick={() => setVisible(true)}
                                className="object-cover overflow-hidden"
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <Empty />
                      )}

                      {/* <div className="hidden">
                        <Image.PreviewGroup
                          preview={{
                            visible,
                            onVisibleChange: (vis) => setVisible(vis),
                          }}
                        >
                          <Image src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp" />
                          <Image src="https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp" />
                          <Image src="https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp" />
                        </Image.PreviewGroup>
                      </div> */}
                    </div>
                  </div>
                </Content>
              </Layout>
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

SingleOrder.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default SingleOrder;
