import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleCountry = ({
  CountryDetails,
}: {
  CountryDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={CountryDetails?.currency_symbol}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Country Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{CountryDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Capital Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{CountryDetails?.capital}</span>
            </div>
          </div>
        </div>
  
      </div>
    </>
  );
};

export default SingleCountry;
