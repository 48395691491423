import React from "react";
import CurriculumList from "../../components/Curriculum";
import MainLayout from "../../components/Layouts";

const CurriculumPage = () => {
  return (
    <MainLayout>
      <CurriculumList />
    </MainLayout>
  );
};

export default CurriculumPage;
