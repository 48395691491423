import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleZone = ({
  zoneDetails,
}: {
  zoneDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{zoneDetails?.deliveryZoneName}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Rules</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{zoneDetails?.addShippingRules}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Types</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{zoneDetails?.zoneType}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleZone;
