import {
  Rate,
  Dropdown,
  Menu,
  Modal,
  Image,
  Divider,
  Avatar,
  Form,
} from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import MerchantLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MERCHANT_INFO } from "../../../redux/auth/authType";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const MerchantDetails = ({
  selectedMerchant,
  onCloseMethod,
}: {
  selectedMerchant?: any;
  onCloseMethod?: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const route = useParams();
  const [singleMerchantInfo, setSingleMerchantInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchMerchantDetails = useCallback((getMerchantId: any) => {
    if (getMerchantId) {
      try {
        setSingleMerchantInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_RIDER_API}/admin/merchant/merchant/details?merchantId=${getMerchantId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleMerchantInfo({
                loading: false,
                data: data?.data?.merchant,
              });
              dispatch({
                type: SET_MERCHANT_INFO,
                payload: {
                  merchantInfo: {
                    id: data?.data?.merchant?.id,
                    merchantId: data?.data?.merchant?.merchantId,
                    name:
                      data?.data?.merchant?.fullName ||
                      data?.data?.merchant?.merchantPersonalDetail?.name,
                    type: data?.data?.merchant?.type,
                    mobileNumber: data?.data?.merchant?.mobileNumber,
                    balance: data?.data?.merchant?.balance,
                    profilePicture:
                      data?.data?.merchant?.merchantPersonalDetail
                        ?.profilePicture,
                    rating: data?.data?.merchant?.rating || 0,
                    state: data?.data?.merchant?.state,
                    status: data?.data?.merchant?.status,
                  },
                },
              });
            } else {
              setSingleMerchantInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleMerchantInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleMerchantInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  const onStatusChange = async (state: any) => {
    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/merchant/status-update`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumber: singleMerchantInfo?.data?.mobileNumber,
          state: state,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          dispatch({
            type: SET_MERCHANT_INFO,
            payload: {
              merchantInfo: {
                state: state,
              },
            },
          });
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
          fetchMerchantDetails((route as any)?.merchantId);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: `Do you Want to change Status "${status?.split("_").join(" ")}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  useEffect(() => {
    fetchMerchantDetails((route as any)?.merchantId);
  }, [fetchMerchantDetails]);

  useEffect(() => {
    fetchMerchantDetails(selectedMerchant?.merchantId);
  }, [fetchMerchantDetails, selectedMerchant]);

  const merchant = singleMerchantInfo?.data;

  return (
    <React.Fragment>
      <MerchantLayout
        merchantId={merchant?.id}
        title={`Merchant Details`}
        subTitle={`Details`}
        extra={
          <div className="bg-white shadow-md rounded-md p-1">
            <Dropdown.Button
              disabled={
                merchant?.state === "VERIFIED"
              }
              overlay={
                <Menu
                  onClick={(val) => {
                    showConfirm(val?.key);
                  }}
                >
                  <Menu.Item key="VERIFIED">VERIFIED</Menu.Item>
                  {/* {Object?.values(MerchantStatus)?.map((status) => (
                    <Menu.Item key={status}>
                      {status?.split("_").join(" ")}
                    </Menu.Item>
                  ))} */}
                </Menu>
              }
            >
              {merchant?.state?.split("_")?.join(" ") || "Status"}
            </Dropdown.Button>
          </div>
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            <div className="sticky top-2 shadow-sm flex justify-between align-middle bg-white rounded-full pl-4 py-2 mb-6">
              <h3 className="text-xl text-gray-700 font-bold">
                Personal Details
              </h3>
              {/* <Link
                to={`/shops/${merchant?.id}/edit`}
                className="rounded-full h-8 w-8 border-2 border-white hover:bg-slate-50 text-center align-middle"
              >
                <EditOutlined />
              </Link> */}
            </div>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Merchant Name
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {merchant?.fullname || merchant?.merchantPersonalDetail?.name}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Merchant ID
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {merchant?.merchantId}
                </dd>
              </div>

              {merchant?.type && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Type</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {merchant?.type?.replace("_", " ")}
                  </dd>
                </div>
              )}

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Current Balance
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  &#2547; {merchant?.balance || 0}
                </dd>
              </div>

              {merchant?.merchantPersonalDetail && (
                <>
                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Mobile Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.mobileNumber?.split("+88")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Emergency Contact Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.merchantPersonalDetail?.emergencyContactNumber?.split(
                        "+88"
                      )}
                    </dd>
                  </div>

                  {merchant?.merchantPersonalDetail?.email && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        E-mail
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.merchantPersonalDetail?.email}
                      </dd>
                    </div>
                  )}

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Date Of Birth
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {moment(
                        merchant?.merchantPersonalDetail?.dateOfBirth
                      ).format("ll")}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Gender
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.merchantPersonalDetail?.gender}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Country
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.merchantPersonalDetail?.country}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">City</dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.merchantPersonalDetail?.city}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Identification Document
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {merchant?.merchantPersonalDetail?.identificationDocumentType?.toUpperCase()}
                    </dd>
                  </div>

                  <div>
                    <dt className="text-sm font-medium text-gray-900">
                      Identification Document Number
                    </dt>
                    <dd className="text-sm font-semibold text-gray-500">
                      {
                        merchant?.merchantPersonalDetail
                          ?.identificationDocumentNumber
                      }
                    </dd>
                  </div>

                  {merchant?.merchantPersonalDetail
                    ?.identificationDocumentType && (
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        {merchant?.merchantPersonalDetail?.identificationDocumentType?.toUpperCase()}{" "}
                        Number
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {
                          merchant?.merchantPersonalDetail
                            ?.nationalIdOrPassportNumber
                        }

                        {merchant?.merchantPersonalDetail
                          ?.identificationDocumentUrl && (
                          <Avatar
                            shape="square"
                            size="large"
                            src={
                              <Image
                                src={
                                  merchant?.merchantPersonalDetail
                                    ?.identificationDocumentUrl
                                }
                              />
                            }
                          />
                        )}
                        {merchant?.merchantPersonalDetail
                          ?.identificationDocumentUrl2 && (
                          <Avatar
                            shape="square"
                            size="large"
                            src={
                              <Image
                                src={
                                  merchant?.merchantPersonalDetail
                                    ?.identificationDocumentUrl2
                                }
                              />
                            }
                          />
                        )}
                      </dd>
                    </div>
                  )}
                </>
              )}

              {merchant?.dateOfBirth && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Date Of Birth
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {merchant?.dateOfBirth}
                  </dd>
                </div>
              )}

              {merchant?.status && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {merchant?.status?.split("_").join(" ")}
                  </dd>
                </div>
              )}

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Joining Date
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {moment(merchant?.createdAt).format("lll")}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">Rattings</dt>
                <dd className="text-sm font-semibold text-gray-500">
                  <Rate value={merchant?.rating || 1} disabled />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                    {merchant?.numberOfRating || 0}
                  </span>
                </dd>
              </div>
            </dl>

            {/* WareHouse details */}
            <div className="">
              <div className="sticky top-2 shadow-sm flex justify-between align-middle bg-white rounded-lg pl-4 py-1 mt-10 mb-4">
                <h3 className="text-xl text-gray-600 font-bold">WareHouse</h3>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div className="shadow-sm_  bg-gray-50 rounded-md">
                  <Divider orientation="left"> WareHouse Address</Divider>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        WareHouse Address
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.address}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        City
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.city}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Postcode
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.postcode}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        District
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.wareHouseAddress?.district}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="shadow-sm_ bg-gray-50 rounded-md">
                  <Divider orientation="left">Return Address</Divider>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Address
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.address}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        City
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.city}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        Postcode
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.postcode}
                      </dd>
                    </div>

                    <div>
                      <dt className="text-sm font-medium text-gray-900">
                        District
                      </dt>
                      <dd className="text-sm font-semibold text-gray-500">
                        {merchant?.warehouse?.returnAddress?.district}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>

            {/* Business Bank Detail */}
            <div className="">
              <div className="sticky top-2 shadow-sm flex justify-between align-middle bg-white rounded-lg pl-4 py-1 mt-10 mb-4">
                <h3 className="text-xl text-gray-600 font-bold">
                  Business Bank Detail
                </h3>
              </div>

              <div className="grid grid-cols-2 gap-6">
                {merchant?.businessBankDetailList?.map((bankData: any) => (
                  <div className="shadow-sm_  bg-gray-50 rounded-md">
                    <Divider orientation="left">{bankData?.bankName}</Divider>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 p-4 pt-0">
                      <div>
                        <dt className="text-sm font-medium text-gray-900">
                          Account Name
                        </dt>
                        <dd className="text-sm font-semibold text-gray-500">
                          {bankData?.accountName}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-900">
                          Account Number
                        </dt>
                        <dd className="text-sm font-semibold text-gray-500">
                          {bankData?.accountNumber}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-900">
                          Branch Name
                        </dt>
                        <dd className="text-sm font-semibold text-gray-500">
                          {bankData?.branchName}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-900">
                          Routing Number
                        </dt>
                        <dd className="text-sm font-semibold text-gray-500">
                          {bankData?.routingNumber}
                        </dd>
                      </div>
                    </dl>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </MerchantLayout>
    </React.Fragment>
  );
};

MerchantDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default MerchantDetails;
