import React from "react";
import ContentList from "../../components/EContent";
import MainLayout from "../../components/Layouts";

const EContentPage = () => {
  return (
    <MainLayout>
      <ContentList />
    </MainLayout>
  );
};

export default EContentPage;
