import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    Button,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import axios from "axios";
import dayjs from "dayjs";

const AddAuctionReschedule = ({ visibleData,productData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [auctionType, setAuctionType] = useState<any>("NORMAL");
  const [startingDate, setStartingDate] = useState<DatePickerProps["value"]>();
  const [endingDate, setEndingDate] = useState<DatePickerProps["value"]>();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [auctionData, setAuctionData] = useState<any>(undefined);
  console.log("auctionData ====>", auctionData);
  console.log("visibleData <======>", productData);
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      auctionType: auctionType,
      startingPrice: data.startingPrice,
      minimumIncrementAmount: data.minimumIncrementAmount,
      reservePrice: data.reservePrice,
      isBuyNowEnable: data.isBuyNowEnable,
      isSealed: data.isSealed,
      startDate: startingDate,
      endDate: endingDate,
      hasEnded: data.hasEnded,
    };

    if (auctionData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product/auction-reschedule`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          productId: productData,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } 
  };

  useEffect(() => {
    if (visibleData) {
      setAuctionData(visibleData);
      setAuctionType(visibleData?.auctionType);
      setStartingDate(visibleData?.startDate);
      setEndingDate(visibleData?.endAt);
    }
  }, [visibleData]);

  useEffect(() => {
    if (auctionData) {
      form.resetFields(Object.keys(visibleData));
      setAuctionType(visibleData?.auctionType);
      setStartingDate(visibleData?.startDate);
      setEndingDate(visibleData?.endAt);
    }
  }, [auctionData, form, visibleData]);

  //   const resetData = () => {
  //     form?.resetFields();
  //     setGeneratedImgUrl(undefined);
  //     setBannerImgUrl(undefined);
  //     setStartUpload(undefined);
  //     setStartUpload2(undefined);
  //   };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...auctionData,
          startDate: dayjs(auctionData?.startAt),
          endDate: dayjs(auctionData?.endAt),
        }}
      >
        <h1>{auctionData?.id}</h1>
        <div>
          <Form.Item
            hasFeedback
            label="Auction Type"
            rules={[
              {
                required: true,
                message: "Auction Type is Required!",
              },
            ]}
            name="auctionType"
            initialValue={auctionType}
          >
            <Select
              options={[
                { value: null, label: "NONE" },
                { value: "NORMAL", label: "NORMAL" },
                { value: "REVERSE", label: "REVERSE" },
              ]}
              placeholder="Enter Auction Type"
              onChange={(val) => setAuctionType(val)}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Starting Price"
            rules={[
              {
                required: true,
                message: "Starting Price is Required!",
              },
            ]}
            name="startingPrice"
          >
            <Input
              id="startingPrice"
              type="number"
              min={0}
              placeholder="Enter Starting Price"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Minimum Increment Amount"
            rules={[
              {
                required: true,
                message: "Minimum Increment Amount is Required!",
              },
            ]}
            name="minimumIncrementAmount"
          >
            <Input
              id="minimumIncrementAmount"
              type="number"
              min={0}
              placeholder="Enter Minimum Increment Amount"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Reserve Price"
            rules={[
              {
                required: false,
                message: "Reserve Price is Required!",
              },
            ]}
            name="reservePrice"
          >
            <Input
              id="reservePrice"
              type="number"
              min={0}
              placeholder="Enter Reserve Price"
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="IS Buy Now Enable?"
            name="isBuyNowEnable"
            className="mr-5"
          >
            <Switch
              checkedChildren={`true`}
              unCheckedChildren={`false`}
              defaultChecked={(auctionData as any)?.isBuyNowEnable}
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="IS Sealed?"
            name="isSealed"
            className="mr-5"
          >
            <Switch
              checkedChildren={`true`}
              unCheckedChildren={`false`}
              defaultChecked={(auctionData as any)?.isSealed}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            hasFeedback
            label="Starting Date"
            rules={[
              {
                required: true,
                message: "Starting Date is Required!",
              },
            ]}
            name="startDate"
          >
            <DatePicker
              showTime
              style={{ minWidth: "100%" }}
              showNow={false}
              placeholder="Starting Date"
              onChange={(val: DatePickerProps["value"]) => setStartingDate(val)}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            hasFeedback
            label="Ending Date"
            rules={[
              {
                required: true,
                message: "Ending Date is Required!",
              },
            ]}
            name="endDate"
          >
            <DatePicker
              showTime
              id="endDate"
              placeholder="Enter Ending Date"
              showNow={false}
              style={{ minWidth: "100%" }}
              onChange={(val: DatePickerProps["value"]) => setEndingDate(val)}
              disabled={!startingDate}
              disabledDate={(currentDate) =>
                currentDate.isBefore(dayjs(startingDate))
              }
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Has Ended?"
            name="hasEnded"
            className="mr-5"
          >
            <Switch
              checkedChildren={`true`}
              unCheckedChildren={`false`}
              defaultChecked={(auctionData as any)?.hasEnded}
            />
          </Form.Item>
        </div>
        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddAuctionReschedule;
