import React from "react";
import BannerList from "../components/Banners";
import MainLayout from "../components/Layouts";

const BannerPage = () => {
  return (
    <MainLayout>
      <BannerList />
    </MainLayout>
  );
};

export default BannerPage;
