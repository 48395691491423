import React from "react";
import ServiceTypeList from "../../components/ServiceType/index";
import MainLayout from "../../components/Layouts";

const ServiceTypePage = () => {
  return (
    <MainLayout>
      <ServiceTypeList />
    </MainLayout>
  );
};

export default ServiceTypePage;
