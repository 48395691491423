import { DatePicker, Empty, Avatar } from "antd";

import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage } from "../../utils";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import React from "react";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const MySettlementList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(16);
  const [settlementData, setSettlementData] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedSettlement, setSelectedSettlement] = useState<any>(undefined);
  const loc = useLocation();

  const getSettlements = useCallback(async () => {
    setSettlementData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/order/settlement-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSettlementData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSettlementData({ loading: false, data: [] });
        console.error("Drivers: Error", err);
      });
  }, [limit]);
  useEffect(() => {
    getSettlements();
  }, [getSettlements]);

  const onClose = () => {
    setSelectedSettlement(null);
  };

  return (
    <>
      <BreadCrumb
        title="My Settlement List"
        subTitle={`${settlementData?.data?.totalElements} Data(s)`}
      />

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div>
              {settlementData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Delivery Hero</th>
                      <th scope="col">DH Contact</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {settlementData?.data?.settlementRequests?.length ? (
                      settlementData?.data?.settlementRequests?.map(
                        (settlement: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                <Avatar
                                  src={settlement?.deliveryMan?.profilePicture}
                                  size={45}
                                />{" "}
                                {settlement?.deliveryMan?.name}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {settlement?.deliveryMan?.mobileNumber?.replace(
                                  "+88",
                                  ""
                                )}
                              </span>
                            </td>

                            <td>
                              <span className="name text-capitalize">
                                {moment(settlement?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                &#2547; {settlement?.amount}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${settlement?.status?.toLowerCase()}-btn`}
                              >
                                {settlement?.status?.split("-").join(" ")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...settlementData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default MySettlementList;
