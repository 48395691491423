import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import CreateMergeOrderList from "../../components/MergeOrder/AddMergeOrder";
import React from "react";

const CreateMergeOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <CreateMergeOrderList />
    </MainLayout>
  );
};

export default CreateMergeOrderPage;
