import React from "react";
import MainLayout from "../../components/Layouts";
import TeacherList from "../../components/Teacher";

const TeacherPage = () => {
  return (
    <MainLayout>
      <TeacherList />
    </MainLayout>
  );
};

export default TeacherPage;
