import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Spin,
  DatePicker,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPage, getParamValue } from "../../utils/index";
import { debounce } from "lodash";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../../styles/tailwind/List.module.css";
import Loader from "../common/Loader";
import moment from "moment";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import CustomerAcquisition from "./CustomerAcquisition";
import { PaginationThree } from "../common";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const { Option } = Select;

const UserList = ({
  inPromo = false,
  getPromoCode,
}: {
  inPromo?: boolean;
  getPromoCode?: string;
}) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const navigate = useNavigate();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const dateFrom = getParamValue(loc.search, "from");
  const dateTo = getParamValue(loc.search, "to");
  const [showSearch, setShowSearch] = useState(true);
  const [status, setStatus] = useState(getParamValue(loc.search, "status"));
  const pageType: any = getParamValue(loc.search, "page-type");
  const [mobileNumber, setMobileNumber] = useState<any>();
  let params = useParams();
  const [usersData, setUsersData] = useState<any>({
    loading: false,
    data: null,
  });

  const [usersOptions, setUsersOptions] = useState({
    loading: false,
    list: [],
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const fetchRef = useRef<any>(null);

  const [form] = Form.useForm();

  const reseAllFieldData = () => {
    setStatus("");
    setMobileNumber("");
    setRange({
      from: null,
      to: null,
    });
    form.resetFields();
    window.location.reload();
  };

  useEffect(() => {
    setRange({
      from: dateFrom,
      to: dateTo,
    });
  }, [dateFrom, dateTo]);
  console.log("getPromoCode:", getPromoCode);
  const getUsers = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}/admin/user?`;
    const encodedPromoUri = `${process.env.REACT_APP_ORDER_API}/admin/promo/promo-usage-user-list?`;

    axios
      .get(
        `${getPromoCode ? encodedPromoUri : encodedUri}` +
          (page ? `page=${page || 0}` : `page=${0}`) +
          (limit ? `&limit=${limit || 16}` : `&limit=${16}`) +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``) +
          (status ? `&status=${status}` : ``) +
          (getPromoCode ? `&promoCode=${getPromoCode}` : ``) +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber
                ?.toString()
                .replace("+88", "")}`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUsersData({ loading: false, data: null });
        console.error("Users: Error", err);
      });
  }, [limit, mobileNumber, status, range, page, getPromoCode]);

  // const getPromoUsers = useCallback(async () => {
  //   setUsersData({ loading: true, data: null });
  //   const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
  //   axios
  //     .get(
  //       `${encodedUri}/admin/promo/promo-usage-user-list` +
  //         (getPromoCode ? `?promoCode=${getPromoCode}` : ``) +
  //         (page ? `page=${page || 0}` : ``) +
  //         (limit ? `&limit=${limit || 16}` : ``),
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setUsersData({ loading: false, data: res.data });
  //       setTotalCustomer(res.data?.totalElements);
  //     })
  //     .catch((err) => {
  //       setUsersData({ loading: false, data: null });
  //       console.error("Users: Error", err);
  //     });
  // }, [limit, page, getPromoCode]);

  const getUsersOptions = useCallback(
    async (mobileNumber: any) => {
      setUsersOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (status ? `&status=${status}` : ``) +
            (mobileNumber ? `&mobileNumber=%2B88${mobileNumber}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUsersOptions({
            loading: false,
            list: res.data?.users?.map(
              (user: { fullName: any; mobileNumber: any }) => ({
                label: user.fullName,
                value: user.mobileNumber,
              })
            ),
          });
        })
        .catch((err) => {
          setUsersOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    if (range?.from) {
      getUsers();
    }
  }, [range]);

  useEffect(() => {
    getUsers();
  }, [inPromo]);

  // const getUsersOptionsDebounce = (mobileNumber) => {
  //   const fetcher = (mobileNumber) => {
  //     getUsersOptions(mobileNumber);
  //   };

  //   return debounce(fetcher, 0.8);
  // };

  const getUsersOptionsDebounce = React.useMemo<any>(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setUsersOptions({ loading: false, list: [] });

      if (fetchId !== fetchRef.current) {
        return;
      }

      getUsersOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getUsersOptions]);

  return (
    <>
      <BreadCrumb
        title="Customer List"
        subTitle={`${
          usersData?.data?.totalElements || usersData?.data?.users?.length
        } Customer(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <div>
            <PDFDownloadLink
              document={
                <CustomerAcquisition
                  data={usersData?.data?.users?.length}
                  range={range}
                />
              }
              fileName="customer_acquisition.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
          </div>,
          // <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles.searchBox}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 300 }}
                onSearch={getUsersOptionsDebounce}
                onSelect={(val: { toString: () => any }) =>
                  setMobileNumber(val?.toString())
                }
                options={usersOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  usersOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  style={{ minWidth: 100, width: "calc(100% - 45px)" }}
                  size="large"
                  placeholder="Name or Phone Number"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={usersOptions.loading}
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item initialValue={status}>
              <Select
                placeholder="User Status"
                onChange={(val) => setStatus(val)}
                value={status}
                style={{ minWidth: 250 }}
              >
                <Option value={""}>ALL</Option>
                <Option value={"REGULAR"}>REGULAR</Option>
                <Option value={"ACTIVE"}>ACTIVE</Option>
              </Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {usersData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Rattings</th>
                      <th scope="col">Balance</th>
                      <th scope="col">
                        Status:
                        {params.from}
                        {params.to}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {usersData?.data?.users?.length ? (
                      usersData?.data?.users?.map((user: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() =>
                            navigate(`/customer/${user?.id}/details`)
                          }
                        >
                          <td>
                            <Avatar size={40} src={user?.profilePictureUrl} />{" "}
                            {user?.fullName ||
                              user?.mobileNumber?.replace("+88", "")}
                          </td>

                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(user?.createdAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(user?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(user?.createdAt).format("lll")}
                            </span>
                          </td>

                          <td>
                            <Rate value={user?.rating || 1} disabled />
                            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                              {user?.numberOfRating || 0}
                            </span>
                          </td>

                          <td>&#2547;{user?.balance || 0}</td>

                          <td>
                            <span
                              className={`border p-1 px-5 rounded-2xl bg-white ${
                                user?.status?.toLowerCase() || "initiated"
                              }-btn`}
                            >
                              {user?.status || "Initiated"}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...usersData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default UserList;
