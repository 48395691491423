import React from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/auth/authType";

const types = [
  { value: "JC_COMMERCE", icon: "/departments/mall.png" },
  { value: "JC_GLOBAL", icon: "/departments/global.png" },
  { value: "JC_GROCERY", icon: "/departments/grocery.png" },
  { value: "JC_FOOD", icon: "/departments/food.png" },
  { value: "JC_MART", icon: "/departments/mart.png" },
  { value: "JC_ELEARNING", icon: "/departments/elearning.png" },
];

const AllTypes = ({ type }: { type?: string }) => {
  const dispatch = useDispatch();

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
      {types?.map((data, i) => (
        <div
          key={i}
          className={cn(
            "shadow hover:shadow-lg rounded-md p-2 text-center cursor-pointer",
            {
              ["bg-green-50 border border-green-500 shadow-lg"]:
                type === (data?.value as any),
            }
          )}
          onClick={() => {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                type: data?.value,
              },
            });
          }}
        >
          <img src={data?.icon} className="m-auto mb-4 h-12" alt="Loading" />
          <span className="font-light text-gray-600">
            {data?.value?.replace("JC_", "")}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AllTypes;
