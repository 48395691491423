import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";

const AddURL = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [shortURLData, setShortURLData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      url: data.url,
    };
    await fetch(`${process.env.REACT_APP_AI_API}/short-url`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("URL Create Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (visibleData) {
      setShortURLData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (shortURLData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [shortURLData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...shortURLData,
        }}
      >
        <Form.Item
          hasFeedback
          label="URL"
          // rules={[
          //   {
          //     required: true,
          //     message: "URL is Required!",
          //   },
          // ]}
          rules={[
            {
              required: true,
              message: "URL is required!",
            },
            {
              validator: async (_, url) => {
                if (
                  url &&
                  !String(url)
                    .toLowerCase()
                    ?.match(
                      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
                    )
                ) {
                  return Promise.reject(new Error("Enter valid url"));
                }
              },
            },
          ]}
          name="url"
        >
          <Input id="url" type="text" placeholder="Enter URL" />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddURL;
