import { Collapse, Form, Input } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import React from "react";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SubmitResetBtn } from "../common";
import { useSelector } from "react-redux";
const { Panel } = Collapse;
const AddConfig = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [singleConfigInfo, setSingleConfigInfo] = useState<any>({
    loading: false,
    data: null,
  });
  //console.log("singleConfigInfo", singleConfigInfo);

  // **************************************
  const onSubmit = async (data: any) => {
    // console.log("DAta::", data);
    setLoading(true);

    const readyData = data && {
      maxPointConversionAmountForSingleOrder:
        data.maxPointConversionAmountForSingleOrder || 0,

      percentageOfOrderAmountToBeConvertedToPointForReferrer:
        data.percentageOfOrderAmountToBeConvertedToPointForReferrer || 0,

      percentageOfOrderAmountToBeConvertedToPointForUser:
        data.percentageOfOrderAmountToBeConvertedToPointForUser || 0,

      pointOfRefererForFirstTimeUse: data.pointOfRefererForFirstTimeUse || 0,

      pointOfReferralUserForFirstTimeUse:
        data.pointOfReferralUserForFirstTimeUse || 0,

      pointToBalanceConversionRate: data.pointToBalanceConversionRate || 0,

      activePointExpirationPeriodInDays:
        data.activePointExpirationPeriodInDays || 0,

      inActivePointExpirationPeriodInDays:
        data.inActivePointExpirationPeriodInDays || 0,

      deliveryManDeliveryManConfiguration:
        data.deliveryManDeliveryManConfiguration,

      deliveryManMerchantConfiguration: data.deliveryManMerchantConfiguration,
      deliveryManUserConfiguration: data.deliveryManUserConfiguration,
      merchantDeliveryManConfiguration: data.merchantDeliveryManConfiguration,
      merchantMerchantConfiguration: data.merchantMerchantConfiguration,
      merchantUserConfiguration: data.merchantUserConfiguration,
    };
    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/point/configuration`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: singleConfigInfo?.data?.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Point Configuration Updated Successfully",
            "success"
          );
          form.resetFields();
          fetchConfigDetails();
          //setSingleConfigInfo(readyData);
          // navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchConfigDetails = useCallback(() => {
    try {
      setSingleConfigInfo({ loading: true, data: null });
      axios
        .get(`${process.env.REACT_APP_RIDER_API}/admin/point/configuration`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setSingleConfigInfo({
              loading: false,
              data: data?.data?.pointConfiguration,
            });
          } else {
            setSingleConfigInfo({ loading: false, data: null });
            responseNotification(
              data.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleConfigInfo({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setSingleConfigInfo({ loading: false, data: null });

      console.log(error, "error");
    }
  }, []);

  useEffect(() => {
    fetchConfigDetails();
  }, [fetchConfigDetails]);

  useEffect(() => {
    if (singleConfigInfo.data) {
      form.resetFields(Object.keys(singleConfigInfo.data as any));
    }
  }, [form, singleConfigInfo]);

  return (
    <div className="content-body rounded-2xl">
      <BreadCrumb
        title="Referrals"
        // subTitle={`${
        //   shopData?.data?.totalElements || shopData?.data?.shops?.length || 0
        // } Shop(s)`}
        extra={
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              form.submit();
            }}
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
          />
        }
      />

      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-6">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onSubmit}
          initialValues={{
            ...singleConfigInfo?.data,
          }}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >
          <Collapse accordion defaultActiveKey={["1"]}>
            <Panel header="User Referral Configuration" key="1">
              <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                <div>
                  <Form.Item
                    hasFeedback
                    label="Point of Referer For First Time Use"
                    rules={[
                      {
                        required: true,
                        message:
                          "Point Of Referer For First Time Use is Required!",
                      },
                    ]}
                    name="pointOfRefererForFirstTimeUse"
                  >
                    <Input
                      id="pointOfRefererForFirstTimeUse"
                      type="number"
                      placeholder="Enter Point Of Referer For First Time Use"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Point of Referral User For First Time Use"
                    rules={[
                      {
                        required: true,
                        message:
                          "Point Of Referral User For First Time Use is Required!",
                      },
                    ]}
                    name="pointOfReferralUserForFirstTimeUse"
                  >
                    <Input
                      id="pointOfReferralUserForFirstTimeUse"
                      type="number"
                      placeholder="Enter Point Of Referral User For First Time Use"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Percentage of Order Amount To Be Converted To Point For Referrer"
                    rules={[
                      {
                        required: true,
                        message:
                          "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                      },
                    ]}
                    name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                  >
                    <Input
                      id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      type="number"
                      placeholder="Enter Percentage Of Order Amount To Be Converted To Point For Referrer"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Percentage of Order Amount To Be Converted To Point For User"
                    rules={[
                      {
                        required: true,
                        message:
                          "Percentage OfOrder Amount To Be Converted To Point For User is Required!",
                      },
                    ]}
                    name="percentageOfOrderAmountToBeConvertedToPointForUser"
                  >
                    <Input
                      id="percentageOfOrderAmountToBeConvertedToPointForUser"
                      type="number"
                      placeholder="Enter Percentage Of Order Amount To Be Converted To Point For User"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Point To Balance Conversion Rate"
                    rules={[
                      {
                        required: true,
                        message:
                          "Point To Balance Conversion Rate is Required!",
                      },
                    ]}
                    name="pointToBalanceConversionRate"
                  >
                    <Input
                      id="pointToBalanceConversionRate"
                      type="number"
                      placeholder="Point To Balance Conversion Rate"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Max Point Conversion Amount For Single Order"
                    rules={[
                      {
                        required: true,
                        message:
                          "Max Point Conversion Amount For Single Order is Required!",
                      },
                    ]}
                    name="maxPointConversionAmountForSingleOrder"
                  >
                    <Input
                      id="maxPointConversionAmountForSingleOrder"
                      type="number"
                      placeholder="Enter Max Point Conversion Amount For Single Order"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    hasFeedback
                    label="Active Point Expiration Period In Days"
                    rules={[
                      {
                        required: true,
                        message:
                          "Active Point Expiration Period In Days is Required!",
                      },
                    ]}
                    name="activePointExpirationPeriodInDays"
                  >
                    <Input
                      id="activePointExpirationPeriodInDays"
                      type="number"
                      placeholder="Enter Active Point Expiration Period In Days"
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    hasFeedback
                    label="In Active Point Expiration Period In Days"
                    rules={[
                      {
                        required: true,
                        message:
                          "In Active Point Expiration Period In Days is Required!",
                      },
                    ]}
                    name="inActivePointExpirationPeriodInDays"
                  >
                    <Input
                      id="inActivePointExpirationPeriodInDays"
                      type="number"
                      placeholder="Enter In Active Point Expiration Period In Days"
                    />
                  </Form.Item>
                </div>
              </div>
            </Panel>
            <Panel header="Delivery Man To Delivery Man Configuration" key="2">
              <Form.List name={"deliveryManDeliveryManConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="Delivery Man To Merchant Configuration" key="3">
              <Form.List name={"deliveryManMerchantConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="Delivery Man To User Configuration" key="4">
              <Form.List name={"deliveryManUserConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="Merchant To Delivery Man Configuration" key="5">
              <Form.List name={"merchantDeliveryManConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="Merchant To Merchant Configuration" key="6">
              <Form.List name={"merchantMerchantConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="Merchant To User Configuration" key="7">
              <Form.List name={"merchantUserConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="User To Delivery Man Configuration" key="8">
              <Form.List name={"userDeliveryManConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
            <Panel header="User To Merchant Configuration" key="9">
              <Form.List name={"userMerchantConfiguration"}>
                {(fields) => (
                  <div className="grid grid-cols-2 gap-x-6 sm md lg xl 2xl">
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For Referrer is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForReferrer"
                          type="number"
                          placeholder="Enter Order Amount (%)"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Order Amount For User (%)"
                        rules={[
                          {
                            required: false,
                            message:
                              "Percentage Of Order Amount To Be Converted To Point For User is Required!",
                          },
                        ]}
                        name="percentageOfOrderAmountToBeConvertedToPointForUser"
                      >
                        <Input
                          id="percentageOfOrderAmountToBeConvertedToPointForUser"
                          type="number"
                          placeholder="Enter Point Of Referer For First Time Use"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referer For First Time Use"
                        rules={[
                          {
                            required: false,
                            message:
                              "Point Of Referer For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfRefererForFirstTimeUse"
                      >
                        <Input
                          id="pointOfRefererForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point"
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        hasFeedback
                        label="Point of Referral User For First Time Use"
                        rules={[
                          {
                            required: true,
                            message:
                              "Point Of Referral User For First Time Use is Required!",
                          },
                        ]}
                        name="pointOfReferralUserForFirstTimeUse"
                      >
                        <Input
                          id="pointOfReferralUserForFirstTimeUse"
                          type="number"
                          placeholder="Enter Point Of Referral User For First Time Use"
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default AddConfig;
