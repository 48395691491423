import { Alert, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../utils";

const AddCarrier = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [carrierData, setCarrierData] = useState<any>(undefined);
  const [carrierType, setCarrierType] = useState<any>(null);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        countryCode: data.countryCode,
        countryName: data.countryName,
        email: data.email,
        isActive: data.isActive,
        mobileNumber: data.mobileNumber,
        name: data.name,
      };

      if (carrierData) {
        await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-carrier`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: carrierData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Carrier Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-carrier`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Carrier Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }

  };
  useEffect(() => {
    if (visibleData) {
      setCarrierData(visibleData);
      setIsActive(visibleData?.isActive === "true");
    }
  }, [visibleData]);

  useEffect(() => {
    if (carrierData) {
      form.resetFields(Object.keys(visibleData));
      setIsActive(visibleData?.isActive === "true");
    }
  }, [carrierData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...carrierData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Carrier Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Carrier Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Country Name"
          rules={[
            {
              required: true,
              message: "Country Name is Required!",
            },
          ]}
          name="countryName"
        >
          <Input
            id="countryName"
            type="text"
            placeholder="Enter Country Name"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Country Code"
          rules={[
            {
              required: true,
              message: "Country Code is Required!",
            },
          ]}
          name="countryCode"
        >
          <Input
            id="countryCode"
            type="text"
            placeholder="Enter Country Code"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="E-mail"
          rules={[
            {
              required: true,
              message: "email is Required!",
            },
          ]}
          name="email"
        >
          <Input id="email" type="text" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Mobile Number"
          rules={[
            {
              required: true,
              message: "Mobile Number is Required!",
            },
          ]}
          name="mobileNumber"
        >
          <Input
            id="mobileNumber"
            type="text"
            placeholder="Enter Mobile Number"
          />
        </Form.Item>

        <Form.Item hasFeedback label="Is Active" name="isActive">
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            defaultChecked={isActive}
            // defaultChecked={
            //   (carrierData as any)?.data?.isActive
            // }
            onChange={() => setIsActive(!isActive)}
          />
        </Form.Item>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddCarrier;
