import React from "react";
import ShortURLList from "../components/ShortURL";
import MainLayout from "../components/Layouts";

const BannerPage = () => {
  return (
    <MainLayout>
      <ShortURLList />
    </MainLayout>
  );
};

export default BannerPage;
