import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios";

const AddMenu = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [programOptions, setProgramOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [programId, setProgramId] = useState<any>([]);

  console.log('programId::',programId);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [menuData, setMenuData] = useState<any>(undefined);
  console.log('menuData::',menuData);
  const [convertedText, setConvertedText] = useState("");
  const [shopId, setShopId] = useState<any>("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      programIdList:programId,
      url: data.url,
    };

    if (menuData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/menu/update-menu`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: menuData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Menu Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/menu/create-menu`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Menu Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setMenuData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (menuData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [menuData, form, visibleData]);

  const getProgramOptions = useCallback(async (programId?: string) => {
    setProgramOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/program?page=0&limit=20` +
        (programId ? `&name=${programId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => {
        return {
          value: program?.id,
          label: program?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getProgramOptions();
  }, [getProgramOptions]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "subMenus") getProgramOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getProgramOptions, type]);
  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setBannerImgUrl(undefined);
    setVideoUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
    setStartUpload3(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...menuData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>


        <div className="">
          <Form.Item
            hasFeedback
            label="Sub Menu"
            rules={[
              {
                required: false,
                message: "Sub Menu is required!",
              },
            ]}
            name="subMenus"
          >
            <Select
              showSearch
              placeholder="Select Sub Menu"
              mode="multiple"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "subMenus");
              }}
              onChange={(val) => setProgramId(val)}
              filterOption={() => {
                return true;
              }}
              value={'6550642c66a69e57254f9d15'}
              options={programOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback
          label="URL"
          rules={[
            {
              required: false,
              message: "URL is Required!",
            },
          ]}
          name="url"
        >
          <Input id="url" type="text" placeholder="Enter URL" />
        </Form.Item>
        <div className="mt-2">
          <SubmitResetBtn
            onClickReset={() => {
              form?.resetFields();
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </div>
      </Form>
    </>
  );
};

export default AddMenu;
