import {
  AutoComplete,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination/Pagination";
import { getPage } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import { isAllowedService } from "../../../utils/services";
import { useSelector } from "react-redux";

const ServiceZoneList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const [showSearch, setShowSearch] = useState(true);
  const [limit, setLimit] = useState(16);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });

  const [zoneOptions, setZoneOptions] = useState({
    loading: false,
    list: [],
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [selectedZoneForEdit, setSelectedZoneForEdit] = useState<any>();

  const getServiceZone = useCallback(async () => {
    setZoneData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/zone/all` + (key ? `?name=${key}` : ``), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setZoneData({ loading: false, data: [] });
        console.error("Locations: Error", err);
      });
  }, [key]);
  //

  const getZoneOptions = useCallback(async (key: any) => {
    setZoneOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    return axios
      .get(`${encodedUri}/zone/all` + (key ? `?name=${key}` : ``), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setZoneOptions({
          loading: false,
          list: res.data?.zones?.map((zone: { name: any }) => ({
            label: zone.name,
            value: zone.name,
          })),
        });
      })
      .catch((err) => {
        setZoneOptions({ loading: false, list: [] });
        console.error("Locations: Error", err);
      });
  }, []);

  const fetchRef = useRef(0);
  const debounceLocationOptionsFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      getZoneOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getZoneOptions]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  //******************************** */
  const onSubmit = async (data: any) => {
    setLoading(true);

    if (selectedZoneForEdit) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          deleted: false,
          zoneId: selectedZoneForEdit?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Updated Successfully", "success");
            form.resetFields();
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/zone`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: data.name }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Create Successfully", "success");
            form.resetFields();
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onServiceZoneRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          zoneId: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Service Zone Remove Successfully", "success");
            getServiceZone();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //******************************** */

  useEffect(() => {
    if (visible || selectedZoneForEdit) {
      form.resetFields();
    }
  }, [visible, selectedZoneForEdit]);

  useEffect(() => {
    getServiceZone();
  }, [getServiceZone]);

  useEffect(() => {
    if (showSearch) {
      getZoneOptions("");
    }
  }, [showSearch]);

  const onClose = () => {
    setSelectedZoneForEdit(undefined);
    getServiceZone();
    setVisible(false);
    form.resetFields();
  };

  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
  };
  console.log("selectedZoneForEdit", selectedZoneForEdit);
  console.log("selectedZoneForEdit", 124);
  return (
    <>
      <BreadCrumb
        title="Service Zone"
        subTitle={`${
          zoneData?.data?.totalElements || zoneData?.data?.zones?.length
        } Zone(s)`}
        extra={[
          // <Button
          //   type="dashed"
          //   shape="circle"
          //   onClick={() => setShowSearch(!showSearch)}
          //   key={1}
          // >
          //   <SearchOutlined />
          // </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />
      {showSearch && (
        <div className={styles.searchBox}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={debounceLocationOptionsFetcher}
                onSelect={(val: any) => setKey(val?.toString())}
                options={zoneOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  zoneOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  style={{ width: "calc(100% - 40px)" }}
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={zoneOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {zoneData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Zone</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {zoneData?.data?.zones?.length ? (
                      zoneData?.data?.zones?.map((zone: any, index: any) => (
                        <tr className="border-t hover:bg-gray-100" key={index}>
                          <td>{zone?.name}</td>
                          <td>
                            <div className="flex gap-2">
                              <Button
                                type="dashed"
                                shape="round"
                                onClick={() => setSelectedZoneForEdit(zone)}
                              >
                                <EditOutlined />
                              </Button>
                              {isAllowedService(`Delivery Delete`) && (
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={zone?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onServiceZoneRemove(zone?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(zone?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...zoneData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title={visible ? "Add Zone" : "Edit Zone"}
        open={visible || selectedZoneForEdit}
        onCancel={onClose}
        okText={`Submit`}
        okButtonProps={{
          loading: loading,
          onClick: form.submit,
        }}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...selectedZoneForEdit,
          }}
        >
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Zone" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ServiceZoneList;
