import React from "react";
import MainLayout from "../../components/Layouts";
import ShippingZone from "../../components/DeliveryShipping/ShippingZone";

const ShippingZonePage = () => {
  return (
    <MainLayout>
      <ShippingZone />
    </MainLayout>
  );
};

export default ShippingZonePage;
