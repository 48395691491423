import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";

const TopHeading = ({
  loading,
  total,
  title,
  download,
  dissable,
  extra,
}: any): ReactElement => {
  const simpleFileDownload = () => {
    if (download) {
      download();
    }
  };

  console.log("download", download);

  return (
    <div className="top-heading-products top-heading-content">
      <div className="container-fluid">
        <div className="row d-flex-sb mb-15">
          <div className="col-lg-6 col-md-6 no-padding">
            <div className="page-heading-content">
              {/* <h2 className="d-flex-l wrap d-flex-nowrap d-flex-base">
              
                <span className="d-flex-l ml-1">{total || 0} Data(s)</span>
              </h2> */}
            </div>
          </div>
          {extra}
          {download && (
            <div className="single-button">
              <Button
                type="dashed"
                shape="round"
                size="large"
                onClick={simpleFileDownload}
                disabled={dissable}
                loading={loading}
              >
                <DownloadOutlined />
                Download
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TopHeading.propTypes = {
  Id: PropTypes.string,
  GetStatus: PropTypes.string,
  GetPrime: PropTypes.string,
  GetFlagship: PropTypes.string,
  GetRocket: PropTypes.string,
  title: PropTypes.string,
};

export default TopHeading;
