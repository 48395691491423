import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleDiscipline from "./Details";
import AddDiscipline from "./AddDiscipline";
import { debounce } from "lodash";
import moment from "moment";
import _ from "lodash";
import { AddBtn, Loader, Pagination } from "../common";
const DisciplineList = (): ReactElement => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedDiscipline, setSelectedDiscipline] = useState(undefined);
  const [selectedDisciplineForEdit, setSelectedDisciplineForEdit] =
    useState(undefined);
  const [disciplineId, setDisciplineId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const isDeleted = "false";
  const [disciplineOptions, setDisciplineOptions] = useState({
    loading: false,
    list: [],
  });
  const [disciplineData, setDisciplineData] = useState<any>({
    loading: false,
    data: null,
  });

  const getDisciplines = useCallback(async () => {
    setDisciplineData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    axios
      .get(
        `${encodedUri}/admin/discipline?type=${type}` +
          (disciplineId ? `&name=${disciplineId}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (isDeleted ? `&deleted=${isDeleted}` : ``) +
          (page
            ? `&page=${
                page == disciplineData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDisciplineData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setDisciplineData({ loading: false, data: [] });
        console.error("discipline: Error", err);
      });
  }, [type, disciplineId,sortBy,sortOrder, isActive, isDeleted, limit, page]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  const onClose = () => {
    getDisciplines();
    setVisible(undefined);
    setSelectedDiscipline(undefined);
    setSelectedDisciplineForEdit(undefined);
  };

  const getDisciplinesOptions = useCallback(async (key?: string) => {
    setDisciplineOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/discipline?type=${type}&page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setDisciplineOptions({
      loading: false,
      list: res?.data?.disciplines?.map((discipline: any) => ({
        label: discipline.name,
        value: discipline.name,
      })),
    });
  }, []);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/discipline`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getDisciplines();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "discipline") getDisciplinesOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getDisciplinesOptions]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
  };

  const reseAllFieldData = () => {
    setDisciplineId("");
    setKey("");
    setIsActive("");
    setSortBy("");
    setSortOrder("");
    setType("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getDisciplinesOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Discipline List`}
        subTitle={`${
          disciplineData?.data?.totalElements ||
          disciplineData?.data?.disciplines?.length ||
          0
        } Discipline(s)`}
        childComponent={false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="discipline_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by discipline"
                optionFilterProp="children"
                onChange={(val) => setDisciplineId(val)}
                onSearch={(e) => handleSearch(e, "discipline")}
                filterOption={() => {
                  return true;
                }}
                options={disciplineOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {disciplineData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Discipline</th>
                      <th scope="col">Program</th>
                      <th scope="col">curriculum</th>
                      <th scope="col">Orgination</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {disciplineData?.data?.disciplines?.length ? (
                      disciplineData?.data?.disciplines?.map(
                        (discipline: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedDiscipline(discipline)}
                            draggable="true"
                          >
                            <td>
                              <Avatar size={40} src={discipline?.uploadDetails?.imageUrl} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {discipline?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {discipline?.program?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {discipline?.curriculum?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {discipline?.shop?.name}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={discipline?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(discipline?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  discipline?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedDisciplineForEdit(discipline);
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {discipline?.createdBy?.name ||
                                  discipline?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    discipline?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(discipline?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(discipline?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {discipline?.updatedBy?.name ||
                                  discipline?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    discipline?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(discipline?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(discipline?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...disciplineData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Discipline"
            : selectedDisciplineForEdit
            ? "Edit Discipline"
            : "Discipline Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedDiscipline || selectedDisciplineForEdit}
        width={450}
      >
        {visible || selectedDisciplineForEdit ? (
          <AddDiscipline
            onCloseMethod={onClose}
            visibleData={selectedDisciplineForEdit}
          />
        ) : (
          <SingleDiscipline disciplineDetails={selectedDiscipline} />
        )}
      </Drawer>
    </>
  );
};

export default DisciplineList;
