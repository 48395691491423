import React, { useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";

interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const ShippingPackage = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [size, setSize] = useState<SizeType>("large");
  console.log("type ::", type);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
  const [packingUnitType, setPackingUnitType] = useState<any>();
  const [packingType, setPackingType] = useState<any>();
  const [selected, setSelected] = useState<any>(1);
  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8 ">
            <div className="content-body">
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full">
                      <div>
                        <Form.Item
                          hasFeedback
                          label="Package name"
                          rules={[
                            {
                              required: true,
                              message: "Package name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Enter Package name"
                          />
                        </Form.Item>
                      </div>
                      <div className="float-left w-full">
                        <Form.Item
                          hasFeedback
                          label="Packing Unit type"
                          name="shippingDeliveryType"
                          initialValue={shippingDeliveryType}
                        >
                          <Radio.Group
                            onChange={(val) =>
                              setPackingUnitType((val as any).target.value)
                            }
                            value={packingUnitType}
                            name="packingUnitType"
                            className="w-full"
                          >
                            <Radio
                              value={true}
                              name="individualUnits"
                              className="float-left w-1/4"
                            >
                              Individual Units
                            </Radio>

                            <Radio
                              value={true}
                              className="float-left w-1/4"
                              name="caseUnits"
                            >
                              Case Units
                            </Radio>

                            <Radio
                              name="groupUnits"
                              value={true}
                              className="float-left w-1/4"
                            >
                              Group Units
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="float-left w-full">
                        <Form.Item
                          hasFeedback
                          label="Package type"
                          name="shippingDeliveryType"
                          initialValue={shippingDeliveryType}
                        >
                          <Radio.Group
                            onChange={(val) =>
                              setPackingType((val as any).target.value)
                            }
                            value={packingType}
                            name="packingType"
                            className="w-full"
                          >
                            <Radio
                              value={true}
                              name="box"
                              className="float-left w-1/4"
                            >
                              Box
                            </Radio>

                            <Radio
                              value={true}
                              className="float-left w-1/4"
                              name="envelope"
                            >
                              Envelope
                            </Radio>

                            <Radio
                              name="softPackageOrSatchel"
                              value={true}
                              className="float-left w-1/4"
                            >
                              Soft Package or Satchel
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="float-left w-full">okkk 2</div>
                    <div className="float-left w-full mt-8">
                      <h3 className="mb-2 text-2xl text-primary">Add Rate</h3>
                    </div>
                    <div className="float-left w-full">
                      <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Set up your own rates</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>
                            Use carrier or app to calculate rates
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                    </div>
                    <div className="float-left w-full">
                      <div className="float-left w-full">
                        <div className="float-left w-1/2">
                          <Form.Item
                            hasFeedback
                            label="Carrier name"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Type your country  Zone Name is Required!",
                              },
                            ]}
                            name="name"
                          >
                            <Input
                              id="name"
                              type="text"
                              placeholder="Type your Shipping profile name"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-1/2">
                          <p className="float-left w-full text-left text-2xl">
                            Logo
                          </p>
                          <Avatar
                            shape="square"
                            className="w-20 h-20 p-6 m-4"
                            icon={<UserOutlined />}
                          />
                        </div>
                      </div>
                      <div className="float-left w-full">
                        <div className="float-left w-1/2 p-4">
                          <Form.Item
                            hasFeedback
                            label="Shipping Transit Type"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Type your country  Zone Name is Required!",
                              },
                            ]}
                            name="name"
                          >
                            <Input
                              id="name"
                              type="text"
                              placeholder="Type your Shipping profile name"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-1/2 p-4">
                          <Form.Item
                            hasFeedback
                            label="Shipping Transit time"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Type your country  Zone Name is Required!",
                              },
                            ]}
                            name="name"
                          >
                            <Input
                              id="name"
                              type="text"
                              placeholder="Type your Shipping profile name"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-full">
                          <p className="float-left w-full text-left text-2xl">
                            At least 3 services typically meet this transit
                            time. Show Services
                          </p>
                        </div>
                      </div>
                      <div className="float-left w-full">
                        <Form.Item
                          hasFeedback
                          label="Shipping Price"
                          rules={[
                            {
                              required: false,
                              message:
                                "Type your country  Zone Name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Type your Shipping profile name"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="float-left w-full">
                      <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                    </div>
                    <div className="float-left w-full">
                      <div className="float-left w-1/2 p-4">
                        <Form.Item
                          hasFeedback
                          label="Shipping Transit Type"
                          rules={[
                            {
                              required: false,
                              message: "Your processing Time is Required!",
                            },
                          ]}
                          name="campaignType"
                        >
                          <Select
                            options={[
                              { value: "DISCOUNT", label: "DISCOUNT" },
                              { value: "BOGO", label: "BUY ONE GET ONE" },
                              { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                              { value: "GROUP_BUY", label: "GROUP BUY" },
                              {
                                value: "PURCHASE_VOUCHER",
                                label: "PURCHASE VOUCHER",
                              },
                              {
                                value: "FREE_DELIVERY",
                                label: "FREE DELIVERY",
                              },
                            ]}
                            placeholder="Enter Campaign Type"
                          />
                        </Form.Item>
                      </div>
                      <div className="float-left w-1/2 p-4">
                        <Form.Item
                          hasFeedback
                          label="Shipping Transit time"
                          rules={[
                            {
                              required: false,
                              message: "Your processing Time is Required!",
                            },
                          ]}
                          name="campaignType"
                        >
                          <Select
                            options={[
                              { value: "DISCOUNT", label: "DISCOUNT" },
                              { value: "BOGO", label: "BUY ONE GET ONE" },
                              { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                              { value: "GROUP_BUY", label: "GROUP BUY" },
                              {
                                value: "PURCHASE_VOUCHER",
                                label: "PURCHASE VOUCHER",
                              },
                              {
                                value: "FREE_DELIVERY",
                                label: "FREE DELIVERY",
                              },
                            ]}
                            placeholder="Enter Campaign Type"
                          />
                        </Form.Item>
                      </div>
                      <div className="float-left w-full">
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                          At least 3 services typically meet this transit time.
                          Show Services
                        </p>
                      </div>
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        label="Shipping Price"
                        rules={[
                          {
                            required: false,
                            message:
                              "Type your country  Zone Name is Required!",
                          },
                        ]}
                        name="name"
                      >
                        <Input
                          id="name"
                          type="text"
                          placeholder="Type your Shipping profile name"
                        />
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Checkbox>Add Conditions</Checkbox>
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Based on item weight</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Card
                        title="Based on item weight"
                        className="my-4 rounded-xl"
                      >
                        <div className="float-left w-full">
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Minimum weight"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Maximum weight"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Charge"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Button
                            type="primary"
                            size={size}
                            className="rounded-md mt-9 text-black"
                            style={{ backgroundColor: "#2A7CE2" }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Card>
                    </div>
                    <div className="float-left w-full">
                      <Card
                        title="Based on order price"
                        className="my-4 rounded-xl"
                      >
                        <div className="float-left w-full">
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Above Order Price"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Maximum Above Order Price"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Charge"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Button
                            type="primary"
                            size={size}
                            className="rounded-md mt-9 text-black"
                            style={{ backgroundColor: "#2A7CE2" }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Card>
                    </div>
                    <div className="float-left w-full">
                      <Card
                        title="Based on Distance"
                        className="my-4 rounded-xl"
                      >
                        <div className="float-left w-full">
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Customer within Minimum Radios"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            <Form.Item
                              hasFeedback
                              label="Customer within Maximum Radios"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-4/12 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Charge"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Button
                            type="primary"
                            size={size}
                            className="rounded-md mt-9 text-black"
                            style={{ backgroundColor: "#2A7CE2" }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Card>
                    </div>
                    <div className="float-left w-full">
                      <Card title="Based on Area" className="my-4 rounded-xl">
                        <div className="float-left w-full">
                          <div className="float-left w-1/4 p-2">
                            <Form.Item
                              hasFeedback
                              label="Select Country (From)"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            <Form.Item
                              hasFeedback
                              label="Select Zone (From)"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Select Zone (To)"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Charge"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Button
                            type="primary"
                            size={size}
                            className="rounded-md mt-9 text-black"
                            style={{ backgroundColor: "#2A7CE2" }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Card>
                    </div>
                    <div className="float-left w-full">
                      <Card
                        title="Based on Schedule"
                        className="my-4 rounded-xl"
                      >
                        <div className="float-left w-full">
                          <div className="float-left w-1/4 p-2">
                            <Form.Item
                              hasFeedback
                              label="Business Day"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            <Form.Item
                              hasFeedback
                              label="Schedule Delivery Time( From)"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            <Form.Item
                              hasFeedback
                              label="Schedule Delivery Time (to)"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/4 p-2">
                            {" "}
                            <Form.Item
                              hasFeedback
                              label="Charge"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Button
                            type="primary"
                            size={size}
                            className="rounded-md mt-9 text-black"
                            style={{ backgroundColor: "#2A7CE2" }}
                          >
                            Add More
                          </Button>
                        </div>
                      </Card>
                    </div>

                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Based on order price</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Based on Distance</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Based on Area</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group>
                          <Radio value={1}>Based on Schedule</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <Checkbox>Shipping Handling fee</Checkbox>
                    </div>
                    <div className="float-left w-full">
                      <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                        Adjust calculated rates to account for packaging and
                        handling costs
                      </p>
                    </div>
                    <div className="float-left w-full">
                      <div className="float-left w-1/2 p-2">
                        <Form.Item
                          hasFeedback
                          label="Percentage"
                          rules={[
                            {
                              required: false,
                              message:
                                "Type your country  Zone Name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Type your Shipping profile name"
                          />
                        </Form.Item>
                      </div>
                      <div className="float-left w-1/2 p-2">
                        <Form.Item
                          hasFeedback
                          label="Flat Amount"
                          rules={[
                            {
                              required: false,
                              message:
                                "Type your country  Zone Name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Type your Shipping profile name"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Card>

                  <div className="w-full mx-8 py-0">
                    <div className="float-left w-11/12 text-right">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingPackage;
