import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import GetShopTagsList from "./ShippingZoneList";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router";
import { Button, Form, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import form from "antd/es/form";
import { authenticateToken } from "../../../utils/auth";

const ShopShippingZoneList = (): ReactElement => {
  const route = useParams();

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Shop Shipping Zone List"
        subTitle="Shop Shipping Zone  List"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <GetShopTagsList inShop={true} getShopId={(route as any)?.shopId} />
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopShippingZoneList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopShippingZoneList;
