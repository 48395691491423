import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  List,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import Link from "antd/lib/typography/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";
import moment from "moment";
import { Loader, Pagination, TreeSelectTwo } from "../common";
import axios from "axios";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
const { RangePicker } = DatePicker;
interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const categoryListShow = new Array(10).fill(null).map((_, index) => ({
    key: index + 1,
    label: `Category ${index + 1}`,
  }));
const ShippingProfile = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [size, setSize] = useState<SizeType>("large");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const isDeleted = "false";
  const [isActive, setIsActive] = useState<any>(undefined);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [brandData, setBrandData] = useState<any>({
    loading: false,
    data: null,
  });
  console.log("type ::", type);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();

    
      const getBrands = useCallback(async () => {
        setBrandData({ loading: true, data: null });
    
        const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
        axios
          .get(
            `${encodedUri}/admin/brand?type=${type}` +
              (sortBy ? `&sortBy=${sortBy}` : ``) +
              (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
              (isActive ? `&isActive=${isActive}` : ``) +
              (isDeleted ? `&deleted=${isDeleted}` : ``) +
              (page
                ? `&page=${
                    page == brandData.data?.currentPageNumber ? 0 : page || 0
                  }`
                : ``) +
              (limit ? `&limit=${limit}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setBrandData({ loading: false, data: res.data });
          })
          .catch((err) => {
            setBrandData({ loading: false, data: [] });
            console.error("Brand: Error", err);
          });
      }, [
        type,
        sortBy,
        sortOrder,
        isActive,
        isDeleted,
        limit,
        page,
      ]);
    
      useEffect(() => {
        getBrands();
      }, [getBrands]);
  const reseAllFieldData = () => {
    form.resetFields();

    setRange({
      from: null,
      to: null,
    });
  };
  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            className="py-2 inline-block min-w-full sm:px-6 lg:px-8 "
            style={contendStyle}
          >
            <div className="content-body">
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  //   className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <div className="w-full mx-8 py-0">
                    <div className="float-left w-full ">
                      <h3 className="mb-2 mt-0 text-2xl text-primary">
                      Create shipping profile
                      </h3>
                    </div>
                  </div>
                 
                  <h3 className="mb-2 mt-8 text-2xl leading-tight text-primary px-8 ">
                  Ask??
                  </h3>
                 
                  <Card className="mx-8 my-4 rounded-xl">
                  <div>
                  <Form.Item
                    hasFeedback
                    label="Name"
                    rules={[
                      {
                        required: false,
                        message: "Name is Required!",
                      },
                    ]}
                    name="name"
                  >
                    <Input id="name" type="text" placeholder="Type your Shipping profile name" />
                  </Form.Item>
                  <p>Customers won’t see this</p>
                </div>
                    
                    
                  </Card>
                  <Card className="mx-8 my-4 rounded-xl">
                  <div className="float-left w-full">
                  <p className="float-left w-4/5 text-left">Product</p>
                   <p className="float-left w-1/5 text-right">Add Product</p>
                  </div>
                  <div className="float-left w-full h-16 bg-zinc-700 rounded-md text-center">
                  No Data
                </div>
                    
               
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                Shipping Origins
                  </h3>
                
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-1/2 h-full font-medium">
                      <p className="float-left">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-5xl"
                        />
                      </p>
                      <p className="float-left">
                        <h5 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                          Shop Location/Seller Address
                        </h5>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                          Banglades
                        </p>
                      </p>
                    </p>
                    <p className="float-left px-4 w-1/4 text-center font-medium">
                      Doesn’t offer delivery
                    </p>
                    <p className="float-left px-4 w-1/4 text-right font-medium">
                      Manage
                    </p>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                  Shipping Zones
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-4/5 h-full font-medium">
                      <div className="float-left w-1/12">
                        <Avatar
                          shape="square"
                          size={64}
                          icon={<UserOutlined />}
                        />
                      </div>
                      <div className="float-left w-11/12">
                        <div className="w-1/5">
                          <Form.Item
                            hasFeedback
                            label="Domestic"
                            rules={[
                              {
                                required: true,
                                message: "Domestic is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add Shipping Rate
                          </Button>
                        </div>
                        <div className="w-1/4">
                          <Form.Item
                            hasFeedback
                            label="Not covered by your shipping zones"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Not covered by your shipping zones is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add more shipping places
                          </Button>
                        </div>
                        <p>
                          Add countries/regions to a market to start selling and
                          manage localized settings, including shipping zones.
                        </p>
                      </div>
                    </div>

                    <div className="float-left px-4 w-1/5 text-right font-medium">
                      Create Zone
                    </div>
                  </Card>
                  <div className="w-full mx-8 py-0">
                    
                    <div className="float-left w-11/12 text-right">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingProfile;
