import React from "react";
import MainLayout from "../../components/Layouts";
import AddTeacher from "../../components/Teacher/AddTeacher";

const TeacherAddPage = () => {
  return (
    <MainLayout>
      <AddTeacher />
    </MainLayout>
  );
};

export default TeacherAddPage;
