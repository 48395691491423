import React from "react";
import MainLayout from "../../components/Layouts";
import RateList from "../../components/DeliveryShipping/Rates";

const AddRate = () => {
  return (
    <MainLayout>
      <RateList />
    </MainLayout>
  );
};

export default AddRate;
