import React from "react";
import MainLayout from "../../components/Layouts";
import AddCourse from "../../components/Course/AddCourse";

const CourseAddPage = () => {
  return (
    <MainLayout>
      <AddCourse />
    </MainLayout> 
  );
};

export default CourseAddPage;
