import MAP_STYLE from "../../utils/map-style-basic-v8.json";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import ReactMapGL, {
  FlyToInterpolator,
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Card } from "antd";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY; // Set your mapbox token here

function getCursor({ isHovering, isDragging }: any) {
  return isDragging ? "grabbing" : isHovering ? "pointer" : "default";
}

interface MapBoxProps {
  draggable: boolean;
  coordinate?: {
    lat: number;
    lng: number;
  };
  setCoordinator?: any;
}

const MapBox = ({
  draggable,
  coordinate,
  setCoordinator,
}: MapBoxProps): ReactElement => {
  const [currentLocation, setCurrentLocation] = useState<any>({
    lat: coordinate?.lat || 23.77340239600077,
    lng: coordinate?.lng || 90.41329051290532,
  });

  const mapContainerRef: any = useRef(null);
  const [viewport, setViewport] = useState<any>({});
  // **************************************

  useEffect(() => {
    setViewport({
      dragPan: true,
      dragRotate: true,
      touchZoom: false,
      touchRotate: false,
      keyboard: false,
      doubleClickZoom: false,
      scrollZoom: false,
      container: mapContainerRef?.current,
      latitude: coordinate?.lat || currentLocation?.lat,
      longitude: coordinate?.lng || currentLocation?.lng,
      width: "100%",
      height: "400px",
      zoom: 13,
      pitch: 50,
      bearing: 0,
      clickRadius: 50,
      getMapCenterByLngLatPosition: [
        coordinate?.lng || currentLocation?.lng,
        coordinate?.lat || currentLocation?.lat,
      ],
      center: [
        coordinate?.lng || currentLocation?.lng,
        coordinate?.lat || currentLocation?.lat,
      ],
      initialCenterCoordinate: [
        coordinate?.lng || currentLocation?.lng,
        coordinate?.lat || currentLocation?.lat,
      ],
    });
  }, [currentLocation, coordinate]);
  // **************************************

  return (
    <>
      <Card
        size="small"
        className="mr-2 my-2 mw-100 rounded-md shadow-md"
        style={{ minWidth: "99%" }}
      >
        <ReactMapGL
          mapboxApiAccessToken={MAPBOX_TOKEN}
          {...viewport}
          getCursor={getCursor}
          mapStyle={MAP_STYLE}
          onViewportChange={(viewport: any) => setViewport(viewport)}
          transitionInterpolator={new FlyToInterpolator()}
        >
          <Marker
            latitude={coordinate?.lat || currentLocation?.lat}
            longitude={coordinate?.lng || currentLocation?.lng}
            offsetLeft={(-viewport.zoom * 1.0) / 2}
            offsetTop={(-viewport.zoom * 3) / 2}
            draggable={draggable ? true : false}
            onDragEnd={(e) => {
              setCurrentLocation({
                lat: e?.lngLat?.[1],
                lng: e?.lngLat?.[0],
              });
              setCoordinator({ 
                lat: e?.lngLat?.[1],
                lng: e?.lngLat?.[0],
              });
            }}
          >
            <svg
              width={viewport.zoom * 1.5}
              height={viewport.zoom * 2}
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.78524 0C3.49244 0 0 3.49247 0 7.78524C0 9.87295 1.25608 12.4731 3.73326 15.5133C5.5422 17.7335 7.32281 19.3443 7.3977 19.4118L7.78524 19.761L8.17278 19.4118C8.24766 19.3443 10.0283 17.7335 11.8372 15.5133C14.3144 12.4731 15.5705 9.87295 15.5705 7.78524C15.5705 3.49247 12.078 0 7.78524 0V0Z"
                fill="#CD113B"
              />
              <path
                d="M7.88383 11.7769C10.0337 11.7769 11.7764 10.0342 11.7764 7.88432C11.7764 5.73448 10.0337 3.9917 7.88383 3.9917C5.734 3.9917 3.99121 5.73448 3.99121 7.88432C3.99121 10.0342 5.734 11.7769 7.88383 11.7769Z"
                fill="white"
              />
            </svg>
          </Marker>
          <GeolocateControl
            style={{ right: 10, top: 10 }}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            // auto
            label="Current Location"
          />
          <FullscreenControl style={{ right: 10, top: 50 }} />
          <NavigationControl style={{ right: 10, top: 90 }} />
        </ReactMapGL>
      </Card>
    </>
  );
};

export default MapBox;
