import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import SingleOrder from "../../components/Orders/Details";
import React from "react";

const SingleOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <SingleOrder inShop={false} />
    </MainLayout>
  );
};

export default SingleOrderPage;
