import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPage, getParamValue } from "../../../utils/index";
import { useSelector } from "react-redux";
import DriverLayout from "./Layout";
import styles from "../../../styles/tailwind/List.module.css";
import Pagination from "../../common/Pagination/Pagination";
import Loader from "../../common/Loader";
import moment from "moment";
import Empty from "../../common/Empty";

const DriverOrdersList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [limit, setLimit] = useState(16);
  const [driversData, setdriversData] = useState<any>({
    loading: false,
    data: null,
  });

  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");

  const getUserOrders = useCallback(async () => {
    setdriversData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/order/by-delivery-man?` +
          `&deliveryManId=${(route as any)?.id || ``}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setdriversData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setdriversData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [route, page, limit]);

  useEffect(() => {
    getUserOrders();
  }, [getUserOrders]);

  return (
    <React.Fragment>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Delivered Orders"
      >
        <div className={`content-body`}>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-2 overflow-x-scroll">
            <table className={styles.mainTable}>
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Status of DM</th>
                  <th scope="col">Status</th>
                  <th scope="col">Method</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>{" "}
              {driversData?.loading ? (
                <Loader />
              ) : (
                <tbody>
                  {driversData?.data?.orders?.length ? (
                    driversData?.data?.orders?.map((order: any, index: any) => (
                      <tr
                        className="border-t hover:bg-gray-100"
                        key={index}
                        // onClick={() => setSelectedUser(order)}
                      >
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {moment(order?.createdAt).format("lll")}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.orderId}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.statusOfDeliveryMan}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.status?.split("_").join(" ")}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.paymentMethod
                              ?.split("_")
                              .join(" ")
                              .toUpperCase()}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            &#2547;{order?.subTotal}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            &#2547;{order?.total}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <Pagination
              {...driversData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
        </div>
      </DriverLayout>
    </React.Fragment>
  );
};

export default DriverOrdersList;
