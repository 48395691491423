import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tag,
  DatePicker,
  Popconfirm,
  Drawer,
  Skeleton,
  Checkbox,
} from "antd";
import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import { CSVLink } from "react-csv";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import QuickUpdate from "./QuickUpdate";
import { Link } from "react-router-dom";
import moment from "moment";
import { isAllowedService } from "../../utils/services";
import ProductReviewList from "./ProductReviewList";
import _ from "lodash";
import CampaignProductAssign from "../Promotions/Campaign/Product/assign";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  TreeSelectTwo,
} from "../common";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import ProductExtra from "./extra";
import { structurizeDownloadedData } from "../../utils/csv/ProductDownloadCSV";
const { RangePicker } = DatePicker;
const { success } = Modal;

const BookList = (): ReactElement => {
  const router = useLocation();
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = 50;
  const pageType: any = getParamValue(loc.search, "page-type");
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");

  const [stockInOut, setStockInOut] = useState<any>();
  const [isActive, setIsActive] = useState<any>();
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [shopToShopVisible, setShopToShopVisible] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);

  const [open, setOpen] = useState(false);
  // --------------------------------
  const [activeClone, setActiveClone] = useState(undefined);
  const [changeCat, setChangeCat] = useState(undefined);
  const [assignSupplier, setAssignSupplier] = useState(undefined);
  const [assignCampaign, setAssignCampaign] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [parentCategoryId, setParentCategoryId] = useState(
    getParamValue(router.search, "parentCategoryId")
  );

  const [targetCategoryId, setTargetCategoryId] = useState<any>();

  const [parentCatOptions, setParentCatOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [suppliersOptions, setSuppliersOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });
  const [supplierId, setSupplierId] = useState(
    getParamValue(router.search, "supplierId")
  );
  const [onlyThisShop, setOnlyThisShop] = useState<boolean>();
  const [changeStatus, setChangeStatus] = useState(undefined);
  const [childrenDrawer, setChildrenDrawer] = useState(undefined);
  const [visible, setVisible] = useState(undefined);
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [bookId, setBookId] = useState<any>();
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  // --------------------------------
  const [selectedProductForReview, setSelectedProductForReview] =
    useState<any>(undefined);

  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  // const [barCode, setBarCode] = useState(
  //   getParamValue(router.search, "barCode")
  // );
  const [barCode, setBarCode] = useState("");


 


  const [bookData, setBookData] = useState<any>({
    loading: false,
    data: null,
  });
  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });
  const [bookOptions, setBookOptions] = useState({
    loading: false,
    list: [],
  });
  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  const isDeleted = "false";

  const getBooks = useCallback(async () => {
    setBookData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ELARNING_API}`;
    let url =
      `?type=${type}` +
      (isActive ? `&isActive=${isActive}` : ``) +
      (page
        ? `&page=${page == bookData.data?.currentPageNumber ? 0 : page || 0}`
        : ``) +
      (limit ? `&limit=${limit}` : ``);
    url = encodeURI(url);
    axios
      .get(`${encodedUrl}/admin/book`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBookData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBookData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [isActive, type, page, limit]);

  const getBookOptions = useCallback(async (menuId?: string) => {
    setBookOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/book?page=0&limit=20` +
        (menuId ? `&name=${menuId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ); 

    setBookOptions({
      loading: false,
      list: res?.data?.bookResponses?.map((book: any) => ({
        label: book.name,
        value: book.name,
      })),
    });
  }, []);








  const showDrawer = () => {
    setShopToShopVisible(true);
  };
  const fetchRef = useRef(0);
  

  useEffect(() => {
    getBooks();
  }, [getBooks]);


  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setBookId("");
    setSortBy("");
    setSortOrder("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };


  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "menu") getBookOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getBookOptions]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };


  
  const onClose = () => {
    setSubmitForm(false);
    setOpen(false);
    getBooks();
    setSelectedProductForEdit(undefined);

    setSelectedProductForReview(undefined);
    setShopToShopVisible(false);
  };
  //new add

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(undefined);
    setChangeStatus(undefined);
    setChangeStatus(undefined);
  };
  useEffect(() => {
    if (showSearch) {
      getBookOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title="Book List"
        subTitle={`${bookData?.data?.totalElements}  Product(s)`}
        childComponent={false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn
            onClick={() => {
              navigate(`/book/create`);
            }}
          />,
        ]}
      />

      {showSearch && !pageType && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          
          <Form.Item name="book_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Book"
                optionFilterProp="children"
                onChange={(val) => setBookId(val)}
                onSearch={(e) => handleSearch(e, "menu")}
                filterOption={() => {
                  return true;
                }}
                options={bookOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {bookData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Author Name</th>
                      <th scope="col">Book Genre</th>
                      <th scope="col">Language</th>
                      <th scope="col">Number Of Pages</th>
                      <th scope="col">Number Of Downloads</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {bookData?.data?.bookResponses?.length ? (
                      bookData?.data?.bookResponses?.map(
                        (book: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td className="flex items-center gap-2">
                              <a
                                href={liveUrl + `book/` + book?.id}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <Avatar
                                  size={45}
                                  src={book?.uploadBookDetails?.bannerImageUrl}
                                  shape="square"
                                />
                              </a> 
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-500">
                                  {book?.name?.slice(0, 50)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {book?.authorName}
                              </span>
                            </td>
                            <td>
                              <div className="flex_">
                                {book?.bookGenre?.length
                                  ? book?.bookGenre?.map(
                                      (bookGenre: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag color={`green`}>
                                              {bookGenre}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {book?.language?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {book?.numberOfPages}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {book?.numberOfDownloads}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {book?.price}
                              </span>
                            </td>
                            <td>
                              <Link
                                title="Edit Book"
                                to={`/book/${book?.id}/edit`}
                                className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EditOutlined />
                              </Link>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...bookData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default BookList;
