import React from "react";
import MainLayout from "../../components/Layouts";
import ProcessingTime from "../../components/DeliveryShipping/ProcessingTime";

const ProcessingTimePage = () => {
  return (
    <MainLayout>
      <ProcessingTime />
    </MainLayout>
  );
};

export default ProcessingTimePage;
