import React from "react";
import MainLayout from "../../components/Layouts";
import CarrierList from "../../components/DeliveryShipping/CarrierList";

const DeliveryCarrier = () => {
  return (
    <MainLayout>
      <CarrierList />
    </MainLayout>
  );
};

export default DeliveryCarrier;
