import React from "react";
import CourseQuizList from "../../components/Course-Quiz";
import MainLayout from "../../components/Layouts";

const CourseQuizPage = () => {
  return (
    <MainLayout>
      <CourseQuizList />
    </MainLayout>
  );
};

export default CourseQuizPage;
