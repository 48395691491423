import {
  IdcardOutlined,
  SolutionOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../../../utils/services";

const ShopSidebar = ({ campaignId }: any): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 h-full">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/promotion/campaign/${campaignId}/details`}>
                General Information
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/products"
              icon={<SolutionOutlined />}
              className={
                pathname.includes("/products") ? "active bg-gray-100" : ""
              }
            >
              <Link to={`/promotion/campaign/${campaignId}/products`}>
                Campaign Products
              </Link>
            </Menu.Item>
            {/* 
            <Menu.Item
              key="/orders"
              icon={<ShoppingCartOutlined />}
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/orders") ? "active bg-gray-100" : ""
              }`}
            >
              <Link to={`/promotion/campaign/${campaignId}/orders`}>
                Orders History
              </Link>
            </Menu.Item> */}

            <Menu.Item
              key="/brands"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/brands") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/promotion/campaign/${campaignId}/brands`}
                className="hover:text-white"
              >
                Brands
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/shops"
              className={`hover:text-white hover:bg-gray-100 ${
                pathname.includes("/shops") ? "active bg-gray-100" : ""
              }`}
              icon={<ShopOutlined />}
            >
              <Link
                to={`/promotion/campaign/${campaignId}/shops`}
                className="hover:text-white"
              >
                Shops
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ShopSidebar;
