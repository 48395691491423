import { Avatar, Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { getImgUrl } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "./sidebar";
import cn from "classnames";

const { Content } = Layout;

const BrandLayout = ({
  children,
  brandId,
  title,
  extra,
  subTitle,
}: {
  children: ReactElement;
  brandId: string;
  title: string;
  subTitle: string;
  extra?: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();
  const { brandInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6">
            <div className="col-span-full">
              <PageHeader
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
            </div>

            <div className="col-span-full xl:col-auto">
              <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl mb-6 h-full">
                <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                  <img
                    src={brandInfo?.bannerImage}
                    alt=""
                    className="rounded-xl"
                  />
                  <div className="px-4 pb-2">
                    <div
                      className={cn(
                        "flex justify-between items-baseline py-4",
                        {
                          ["xl:-mt-14"]: brandInfo?.bannerImage,
                        }
                      )}
                    >
                      <div className="hidden lg:block">
                        <Avatar
                          size={150}
                          shape="circle"
                          src={getImgUrl(brandInfo?.logo)}
                          className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                        />
                      </div>
                      <div className="">
                        <div className="flex">
                          <div
                            className={`${
                              brandInfo?.isActive
                                ? "bg-gradient-to-r from-green-400 to-blue-500"
                                : "bg-gradient-to-r from-indigo-500 via-pink-500 to-red-500"
                            } " hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 px-4 rounded-full shadow-md"`}
                          >
                            {brandInfo?.isActive ? "ACTIVE" : "INACTIVE"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-lg font-bold mb-2">
                        {brandInfo?.name?.toUpperCase()}
                      </h2>
                      <ul className="mt-2 space-y-1 grid grid-cols-none gap-2 mb-4">
                        <li className="text-gray-500">
                          <span className="font-semibold mr-1">
                            Sell Count:
                          </span>
                          {brandInfo?.sellCount || 0}
                        </li>
                        <li className="text-gray-500">
                          <span className="font-semibold mr-1">Brand ID:</span>
                          {brandInfo?.id}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <Sidebar brandId={brandId} />
            </div>
            <div className="col-span-2">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

BrandLayout.propType = {
  children: PropTypes.element,
};

export default BrandLayout;
