import React from "react";
import MainLayout from "../../components/Layouts";
import PickupZoneList from "../../components/DeliveryShipping/PickupZoneList";

const PickupZone = () => {
  return (
    <MainLayout>
      <PickupZoneList />
    </MainLayout>
  );
};

export default PickupZone;
