import { Button, Card, Col, DatePicker, Divider, Form, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const OrderVatTaxReport = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState<any>(undefined);
  const [form] = Form.useForm();
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });

  const getDownloadData = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setLoading(true);
    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/order/download/product-order?` +
          (from ? `from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDownloadData({ loading: false, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setDownloadData({ loading: false, data: null });
        console.error("Promo: Error", err);
      });
  }, [range]);

  const simpleFileDownload = () => {
    window.location.href = downloadData?.data?.url;
  };

  useEffect(() => {
    if (range) {
      setDownloadData({ loading: true, data: null });
    }
  }, [range]);

  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <React.Fragment>
      <Row align="middle" justify="center">
        <Col span={8}>
          <Card title="Order Vat-Tax Report">
            <Form
              name="control-hooks"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="ant-form ant-form-vertical"
              onFinish={getDownloadData}
              form={form} // like ref
              layout="vertical"
              autoComplete="off"
            >
              <Form.Item name="range">
                <RangePicker
                  onChange={(f: any) => {
                    console.log("momnet", moment.utc(f[0])?.toISOString());
                    setRange({
                      from: moment.utc(f[0])?.toISOString(),
                      to: moment.utc(f[1])?.toISOString(),
                    });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
              <Button
                disabled={loading}
                loading={loading}
                type="primary"
                htmlType="submit"
                className="add-submit-btn text-center mr-2"
              >
                Submit
              </Button>

              {downloadData?.data?.url && (
                <>
                  {" "}
                  <Divider />
                  <Button
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={simpleFileDownload}
                  >
                    Download
                  </Button>{" "}
                  <Button
                    danger
                    size="large"
                    type="dashed"
                    shape="round"
                    onClick={reseAllFieldData}
                    className="mx-2"
                  >
                    Reset
                  </Button>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OrderVatTaxReport;
