import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Skeleton,
  Spin,
  Switch,
  Popconfirm,
  DatePicker,
  Tree,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../utils/services";
import classNames from "classnames";
import React from "react";
import { AddBtn, Loader, LoaderFull, PaginationThree } from "../common";
const { DirectoryTree } = Tree;
const { RangePicker } = DatePicker;

const ShopList = ({
  refetch = false,
  inCampaign = false,
  inShoppingMall = false,
  inMerchant = false,
  getCampaignId,
  getShoppingMallId,
  getMerchantId,
}: {
  refetch?: boolean;
  inCampaign?: boolean;
  inShoppingMall?: boolean;
  inMerchant?: boolean;
  getCampaignId?: string;
  getShoppingMallId?: string;
  getMerchantId?: string;
}): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  //const [type, setType] = useState("JC_GROCERY");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [visible, setVisible] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [key, setKey] = useState("");
  const [neighborhoodKey, setNeighborhoodKey] = useState("");
  const [merchantId, setMerchantId] = useState<any>(getMerchantId);
  const [neighborhoodId, setNeighborhoodId] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [nameOrMobileNumber, setNameOrMobileNumber] = useState("");
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const withProduct = false;

  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [neighborhoodOptions, setNeighborhoodOptions] = useState({
    loading: false,
    list: [],
  });

  const [merchantOptions, setMerchantOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const isDeleted = "false";

  const getShops = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setShopData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/shop${
          inCampaign ? "/by-campaign" : `/v2`
        }?type=${type}` +
          (getCampaignId ? `&campaignId=${getCampaignId}` : ``) +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (neighborhoodId ? `&neighborhoodId=${neighborhoodId}` : ``) +
          (from ? `&startDate=${from}` : ``) +
          (to ? `&endDate=${to}` : ``) +
          (key ? `&key=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
          (page
            ? `&page=${
                page == shopData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setShopData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShopData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [
    limit,
    page,
    getCampaignId,
    merchantId,
    neighborhoodId,
    key,
    range,
    isActive,
    sortBy,
    sortOrder,
    isDeleted,
  ]);

  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/merchant/all?page=0&limit=20` +
          (type ? `&serviceType=${type}` : ``) +
          (key ? `&nameOrMobileNumber=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant: any) => ({
          label: `${
            merchant?.fullName?.toUpperCase() ||
            merchant?.merchantPersonalDetail?.name?.toUpperCase()
          } - ${merchant?.mobileNumber?.replace("+88", "")}`,
          value: merchant?.merchantId,
        })),
      });
    },
    [type]
  );

  const getMallShops = useCallback(async () => {
    setShopData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/mall-shop?withProduct=${withProduct}&mallId=${getShoppingMallId}` +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setShopData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShopData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [withProduct, getShoppingMallId, key]);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { name: any }) => ({
              label: shop.name,
              value: shop.name,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, page, limit]
  );

  const getNeighborhoodOptions = useCallback(
    async (neighborhoodKey: any) => {
      setNeighborhoodOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/neighborhood/all?type=${type}&page=0&limit=20` +
            (neighborhoodKey ? `&key=${neighborhoodKey}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setNeighborhoodOptions({
            loading: false,
            list: res.data?.neighborhoods?.map((neighborhood: any) => ({
              label: neighborhood.name,
              value: neighborhood.id,
            })),
          });
        })
        .catch((err) => {
          setNeighborhoodOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, neighborhoodKey, page, limit]
  );

  const onShopCampaignRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/remove-shop?shopId=${id}&campaignId=${getCampaignId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shopId: id,
            campaignId: getCampaignId,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Shop Remove Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onShopRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop?shopId=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shopId: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Shop Remove Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onCampaignRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign-shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignDisplayOrder: {
            campaignId: getCampaignId,
            displayOrder: 0,
          },
          shopIdList: [id],
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Shop Remove Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //

  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    shopData?.data?.shops?.find((shop: any, i: string) => {
      if (shop?.id === node?.key) {
        curr = i;
        item = shop;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? shopData?.data?.shops
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : shopData?.data?.shops?.slice(0, pos),
        item,
        curr > pos
          ? shopData?.data?.shops
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, shopData?.data?.shops?.length - 1)
          : shopData?.data?.shops?.slice(pos, shopData?.data?.shops?.length)
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: formated,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getShops();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (id: any, val: boolean) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onShopMoveToTop = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: [
              {
                id: id,
                isSendTop: true,
              },
            ],
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Shop Move To Top Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
        if (field === "neighborhood") getNeighborhoodOptions(value);
        if (field === "merchant") getMerchantsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopOptions, getNeighborhoodOptions, getMerchantsOptions]);

  useEffect(() => {
    if (showSearch) {
      getShopOptions("");
      getNeighborhoodOptions("");
      getMerchantsOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (inShoppingMall) {
      getMallShops();
    } else {
      getShops();
    }
  }, [getShops, refetch]);

  const onClose = () => {
    getShops();
    setReArrangeDrawer(undefined);
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setIsActive("");
    setSortBy("");
    setSortOrder("");
    setMerchantId("");
    setNeighborhoodId("");
    setRange({
      from: null,
      to: null,
    });
  };

  return (
    <>
      {!inCampaign && !inShoppingMall && (
        <BreadCrumb
          title={type == "JC_FOOD" ? "Restaurant List" : "Shop List "}
          subTitle={`${
            shopData?.data?.totalElements || shopData?.data?.shops?.length || 0
          } Shop(s)`}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
            !inCampaign && !inMerchant && (
              <Button
                shape="round"
                type="primary"
                className="bg-blue-600"
                onClick={() => setReArrangeDrawer(true)}
              >
                <SwapOutlined />
              </Button>
            ),
            isAllowedService(`Shops Create`) && !inMerchant && (
              <AddBtn
                onClick={() => {
                  navigate("/shops/create");
                }}
              />
            ),
          ]}
        />
      )}

      {showSearch && !inShoppingMall && !inCampaign && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "shop")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={shopOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  shopOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={shopOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="merchant_search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "merchant")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMerchantId(val?.toString())
                }
                defaultActiveFirstOption={false}
                notFoundContent={
                  merchantOptions?.loading ? <Spin size="small" /> : null
                }
                options={merchantOptions?.list}
              >
                <Input.Search
                  placeholder="Merchant Name or Phone Number"
                  onSearch={(val) => setMerchantId(val)}
                  enterButton
                  loading={merchantOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="negihborhood_search">
              <AutoComplete
                onSearch={(e) => handleSearch(e, "negihborhood")}
                onSelect={(val) => setNeighborhoodId(val?.toString())}
                options={neighborhoodOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  neighborhoodOptions?.loading ? <Spin size="small" /> : null
                }
                style={{ width: 300 }}
              >
                <Input.Search
                  size="large"
                  placeholder="Search by Neighborhood"
                  onSearch={(val) => setNeighborhoodId(val)}
                  enterButton
                  loading={neighborhoodOptions.loading}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(_e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {shopData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Shop</th>
                      {/* <th scope="col">Products</th>
                      <th scope="col">Orders</th> */}
                      <th scope="col">Move TO Top</th>
                      {!inMerchant && !inCampaign && (
                        <>
                          {isAllowedService(`Shops Edit`) &&
                            !inCampaign &&
                            !inShoppingMall && (
                              <>
                                {/* <th scope="col">Ratings</th> */}
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </>
                            )}

                          <th scope="col">Created Info</th>
                          <th scope="col">Updated Info</th>
                        </>
                      )}

                      {inCampaign && (
                        <>
                          <th scope="col">Action</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {shopData?.data?.shops?.length ? (
                      shopData?.data?.shops?.map((shop: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100 cursor-pointer"
                          key={index}
                        >
                          <td
                            onClick={() => 
                              navigate(`/shops/${shop?.id}/details`)
                            }
                            className="flex gap-2 items-center"
                          >
                            <Avatar
                              size={45}
                              src={shop?.logo}
                              className="border"
                            />
                            <span className="font-medium text-gray-500 ml-2 flex flex-col">
                              {shop?.name}
                              <span>
                                {type == "JC_FOOD" && (
                                  <div className="flex">
                                    <span
                                      className={classNames(
                                        `text-xs font-light pl-1 pr-2 py-0.5 leading-3 rounded-full border col-auto flex items-center gap-1`,
                                        shop?.status == "Close"
                                          ? `bg-red-100 text-red-600`
                                          : shop?.status == "Busy"
                                          ? `bg-yellow-200 text-yellow-600`
                                          : `bg-green-100 text-green-600`
                                      )}
                                    >
                                      <ClockCircleOutlined />
                                      <small>
                                        {shop?.status?.toUpperCase()}
                                      </small>
                                    </span>
                                  </div>
                                )}
                              </span>
                            </span>
                          </td>
                          {/* <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shop?.totalProducts}
                            </span>
                          </td> 
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shop?.totalOrders}
                            </span>
                          </td> */}
                          <td>
                            <Button
                              shape="round"
                              type="primary"
                              danger
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShopMoveToTop(shop?.id);
                              }}
                            >
                              <ToTopOutlined />
                            </Button>
                          </td>
                          {/* <td>
                            <Rate value={shop?.rating || 1} disabled />{" "}
                            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                              {shop?.numberOfRating || 0}
                            </span>
                          </td> */}
                          {isAllowedService(`Shops Edit`) &&
                            !inCampaign &&
                            !inShoppingMall && (
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={shop?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(shop?.id, val);
                                  }}
                                  className="mr-1"
                                  loading={
                                    confirmLoading &&
                                    shop?.id === confirmLoading
                                  }
                                  disabled={shop?.hub?.deleted}
                                />
                              </td>
                            )}
                          {isAllowedService(`Shops Edit`) &&
                            !inMerchant &&
                            !inShoppingMall &&
                            !inCampaign && (
                              <>
                                <td>
                                  <div className="flex flex-grow gap-2">
                                    {isAllowedService(`Shops Delete`) && (
                                      <Popconfirm
                                        placement="left"
                                        title="Are you sure to remove?"
                                        visible={shop?.id === visiblePopconfirm}
                                        onConfirm={(e: any) => {
                                          console.log(true);
                                          e.preventDefault();
                                          e.stopPropagation();
                                          onShopRemove(shop?.id);
                                        }}
                                        okButtonProps={{
                                          loading: confirmLoading,
                                          className: "bg-blue-400",
                                        }}
                                        onCancel={handleCancel}
                                      >
                                        <Button
                                          className="bg-white"
                                          type="dashed"
                                          danger
                                          shape="round"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            showPopconfirm(shop?.id);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Popconfirm>
                                    )}
                                    <Link
                                      title="Edit Product"
                                      to={`/shops/${shop?.id}/edit`}
                                      // target="_blank"
                                      className="py-2 px-4 rounded-full m-0 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                    >
                                      <EditOutlined />
                                    </Link>
                                  </div>
                                </td>

                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {shop?.createdBy?.name ||
                                      shop?.createdBy?.mobileNumber}{" "}
                                    -{" "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        shop?.createdAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(shop?.createdAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(shop?.createdAt).format("lll")}
                                  </span>
                                </td>

                                <td>
                                  <small className="font-bold uppercase text-gray-500">
                                    {shop?.updatedBy?.name ||
                                      shop?.updatedBy?.mobileNumber}{" "}
                                    -{" "}
                                  </small>
                                  <small
                                    className={`font-capitalize ${
                                      moment().diff(
                                        shop?.updatedAt,
                                        "minutes"
                                      ) >= 60
                                        ? "text-red-600"
                                        : "text-gray-800"
                                    }`}
                                  >
                                    {moment(shop?.updatedAt).fromNow()}
                                  </small>
                                  <br />
                                  <span className="name">
                                    {moment(shop?.updatedAt).format("lll")}
                                  </span>
                                </td>
                              </>
                            )}
                          {inCampaign && (
                            <td>
                              <div className="flex gap-2">
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={shop?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCampaignRemove(shop?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(shop?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...shopData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Re Arrange Shop"
        placement="right"
        onClose={onClose}
        open={reArrangeDrawer}
        width={450}
      >
        {shopData?.loading ? (
          <Skeleton />
        ) : shopData?.data?.shops?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={shopData?.data?.shops.map((shop: any) => ({
              title: shop?.name,
              key: shop?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>
    </>
  );
};

export default ShopList;
