import React from "react";
import MainLayout from "../../components/Layouts";
import ShippingRatesSettings from "../../components/DeliveryShipping/ShippingRates";

const ShippingRatesPage = () => {
  return (
    <MainLayout>
      <ShippingRatesSettings />
    </MainLayout>
  );
};

export default ShippingRatesPage;
