import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import numToWords from "num-to-words";
import moment from "moment";
import * as React from "react";
import Logo from "../../../images/logo.png";

const styles = StyleSheet.create({
  table: {
    width: "100%",
    height: "400px",
    border: "1px solid #dee2e6",
    borderRadius: "5px",
  },
  thead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  th: {
    fontSize: "10px",
    width: "25%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  thDes: {
    fontSize: "10px",
    width: "25%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  thSession: {
    fontSize: "10px",
    width: "17%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  thQty: {
    fontSize: "10px",
    width: "6%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  thSI: {
    fontSize: "10px",
    width: "4%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  thVATAmount: {
    fontSize: "10px",
    width: "10%",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  td: {
    fontSize: "10px",
    width: "25%",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  tdDes: {
    fontSize: "10px",
    width: "25%",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  tdSession: {
    fontSize: "10px",
    width: "17%",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  tdQty: {
    fontSize: "10px",
    width: "6%",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },

  tdSI: {
    fontSize: "10px",
    width: "4%",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "center",
    alignItems: "center",
  },
  tdTotal: {
    width: "80%",
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "right",
  },
  tdInvoiceEffective: {
    width: "80%",
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "left",
  },
  tdVATAmount: {
    width: "10%",
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    alignItems: "center",
  },
  total: {
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    borderRight: "1px",
    textAlign: "right",
    width: "60%",
  },
  totalAmount: {
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "right",
    width: "40%",
  },
  borderBottom: {
    borderBottom: "1px solid #dee2e6",
  },
  bold: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  normal: {
    fontSize: "12px",
    fontWeight: 100,
  },
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    flexGrow: 1,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    width: "45%",
  },
  logo: {
    flexDirection: "row",
    // height: 40,
    width: 125,
  },
  headerRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  shopName: {
    fontSize: 12,
    color: "#ff1f1f",
    marginBottom: 8,
    fontWeight: 400,

    textTransform: "capitalize",
  },
  address: {
    fontSize: 10,
    color: "#666",
    fontWeight: 400,
    marginBottom: "4px",
  },
  name: {
    fontSize: 10,
    marginBottom: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },

  footerTitle: {
    fontSize: 20,
    marginBottom: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  inWordTitle: {
    fontSize: 12,
    marginBottom: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },

  dateText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
    color: "#535353",
  },
  titleArea: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    marginBottom: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },
  middleSection: {
    width: "100%",
    height: "80%",
  },
  acountAreaPara: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    padding: "0px 5px",
  },
  //footer style

  footerArea: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    borderTop: "1px solid #d5d5d5",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  footerWeb: {
    fontSize: 10,
    color: "#666",
    fontWeight: 400,
    marginBottom: "4px",
  },
  textRed: {
    color: "#cd113b",
  },
  //new end
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  // So Declarative and unDRY 👌

  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "space-between",
  },
  rowInviceEffectiveDate: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "space-between",
  },
  rowMiddle: {
    height: "70%",
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "space-between",
  },
  header: {
    borderTop: "none",
  },

  row1: {
    width: "40%",
    justifyContent: "space-between",
  },
  row2: {
    width: "30%",
    justifyContent: "space-between",
  },
  row3: {
    width: "30%",
    justifyContent: "space-between",
  },

  // table design
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 2,
  },
  hr: {
    backgroundColor: "#d5d5d5",
    width: "100%",
    height: "1px",
    margin: "16px 0",
  },
});
const MerchantInvoice = ({
  invoiceData,
  corporateInfo,
  invoiceEffectiveDate,
}: {
  invoiceData?: any;
  corporateInfo?: any;
  invoiceEffectiveDate?: any;
}) => {
  let totalGetAmount = invoiceData?.data?.totalAmount;
  let words = numToWords(totalGetAmount);
  // console.log(data.data.orderIds);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Image src={Logo} style={styles.logo} />
            </View>
            <View style={styles.headerRight}>
              <View>
                <Text style={styles.shopName}>
                  INVOICE NO :{corporateInfo?.ownerId?.replace("D-I-", "")}
                </Text>
              </View>
              <View>
                <Text style={styles.dateText}>
                  Invoice Date: {moment(new Date()).format("MMMM D, YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.hr}></View>
          <View style={styles.headerArea}>
            <View>
              <View>
                <Text style={styles.shopName}>Invoice To</Text>
              </View>
              <View>
                <Text style={styles.name}>{corporateInfo?.name}</Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Attention : {corporateInfo?.spokesmanName}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Delivery Address : {corporateInfo?.address}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Mobile Number :{" "}
                  {corporateInfo?.spokesmanMobileNumber?.replace("+88", "")}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  {corporateInfo?.spokesmanEmail}
                </Text>
              </View>
            </View>
            <View style={styles.headerRight}>
              <View>
                <Text style={styles.shopName}>Invoice From</Text>
              </View>
              <View>
                <Text style={styles.name}>Jachai.com ltd</Text>
              </View>
              <View>
                <Text style={styles.address}>House# 26, Road# 7, Block# C</Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Niketon, Gulshan -1, Dhaka-1212.
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Issue Date & Time : {moment().format("llll")}
                </Text>
                <Text style={styles.address}>
                  Due Date : {moment().add(1, "months").format("L")}
                </Text>
                <Text style={styles.address}>
                  Invoice Period : {moment().format("LL")}
                </Text>
              </View>
            </View>
          </View>
          {/* <View style={styles.hr}></View> */}
          {/* table */}
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.thSI}>SI.</Text>
              <Text style={styles.thDes}>Description of goods or service</Text>
              <Text style={styles.thSession}>Number Of Session</Text>
              <Text style={styles.thQty}>Qty</Text>
              <Text style={styles.thSI}>SD %</Text>
              <Text style={styles.thSI}>SD Amt.</Text>
              <Text style={styles.thSI}>VAT Rate</Text>
              <Text style={styles.thVATAmount}>VAT Amount</Text>
              <Text style={styles.th}>
                Value including all duties and texes
              </Text>
            </View>

            <View style={styles.rowMiddle}>
              <Text style={styles.tdSI}>01</Text>
              <Text style={styles.tdDes}>TOP-UP </Text>
              <Text style={styles.tdSession}>
                {invoiceData?.data?.numberOfSession}
              </Text>
              <Text style={styles.tdQty}>
                {invoiceData?.data?.numberOfTotalSuccessfulTopUps}
              </Text>
              <Text style={styles.tdSI}>0</Text>
              <Text style={styles.tdSI}>0</Text>
              <Text style={styles.tdSI}>0</Text>
              <Text style={styles.tdVATAmount}>0.00</Text>

              <Text style={styles.td}>{invoiceData?.data?.totalAmount}</Text>
            </View>
            <View style={styles.rowInviceEffectiveDate}>
              <Text style={styles.tdInvoiceEffective}>
                Effective Period :
                {moment(invoiceEffectiveDate?.to).format("MMMM, YYYY")}
              </Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tdTotal}>Total Amount (BDT)</Text>
              <Text style={styles.td}>{invoiceData?.data?.totalAmount}</Text>
            </View>
          </View>
          <View style={styles.footerText}>
            <View>
              <Text style={styles.inWordTitle}>In Word : {words}</Text>
            </View>
          </View>

          <View style={styles.footerArea}>
            <View>
              <Text style={styles.footerTitle}>Jachai.com ltd</Text>
            </View>
            <View>
              <Text style={styles.address}>
                House# 26, Road# 7, Block# C, Niketon, Gulshan -1, Dhaka-1212.
              </Text>
            </View>
            <View>
              <Text style={styles.footerWeb}>Call : 9639-333444</Text>
            </View>
            <View>
              <Text style={styles.footerWeb}>Email : support@jachai.com</Text>
            </View>
            <View>
              <Text style={styles.footerWeb}>Web : Jachai.com</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default MerchantInvoice;
