import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch, Tag } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddMenu from "./AddMenu";
import SingleMenu from "./Details";
import { debounce } from "lodash";
import moment from "moment";
import _ from "lodash";
import { AddBtn, Loader, Pagination } from "../common";
const MenuList = (): ReactElement => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedMenu, setSelectedMenu] = useState(undefined);
  const [selectedMenuForEdit, setSelectedMenuForEdit] =
    useState(undefined);
  const [menuId, setMenuId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const isDeleted = "false";
  const [menuOptions, setMenuOptions] = useState({
    loading: false,
    list: [],
  });
  const [menuData, setMenuData] = useState<any>({
    loading: false,
    data: null,
  });
  const getMenus = useCallback(async () => {
    setMenuData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    axios
      .get(
        `${encodedUri}/admin/menu/menus-list?page=0&limit=20` +
          (menuId ? `&name=${menuId}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMenuData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setMenuData({ loading: false, data: [] });
        console.error("Menu: Error", err);
      });
  }, [type, menuId,sortBy,sortOrder, isActive, limit, page]);

  useEffect(() => {
    getMenus();
  }, [getMenus]);

  const onClose = () => {
    getMenus();
    setVisible(undefined);
    setSelectedMenu(undefined);
    setSelectedMenuForEdit(undefined);
  };

  const getMenuOptions = useCallback(async (menuId?: string) => {
    setMenuOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/menu/menus-list?page=0&limit=20` +
        (menuId ? `&name=${menuId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ); 

    setMenuOptions({
      loading: false,
      list: res?.data?.menuList?.map((menu: any) => ({
        label: menu.name,
        value: menu.name,
      })),
    });
  }, []);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/menu/update-menu`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getMenus();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
 
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "menu") getMenuOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getMenuOptions]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
  };

  const reseAllFieldData = () => {
    setMenuId("");
    setSortBy("");
    setSortOrder("");
    setKey("");
    setIsActive("");
    setType("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getMenuOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Menu List`}
        subTitle={`${
          menuData?.data?.totalElements ||
          menuData?.data?.programs?.length ||
          0
        } Program(s)`}
        childComponent={false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="menu_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Menu"
                optionFilterProp="children"
                onChange={(val) => setMenuId(val)}
                onSearch={(e) => handleSearch(e, "menu")}
                filterOption={() => {
                  return true;
                }}
                options={menuOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>          
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {menuData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Program</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuData?.data?.menuList?.length ? (
                      menuData?.data?.menuList?.map(
                        (menu: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedMenu(menu)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {menu?.name}
                              </span>
                            </td>
                            <td>
                            <div className="flex_">
                                {menu?.subMenus?.length
                                  ? menu?.subMenus?.map(
                                      (subMenu: any, subIndex: string) => (
                                        <React.Fragment key={subIndex}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag color={`green`}>
                                              {subMenu.name}
                                            </Tag> 
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>
                          
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={menu?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(menu?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  menu?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedMenuForEdit(menu);
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {menu?.createdBy?.name ||
                                  menu?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    menu?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(menu?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(menu?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {menu?.updatedBy?.name ||
                                  menu?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    menu?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(menu?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(menu?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...menuData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Menu"
            : selectedMenuForEdit
            ? "Edit Menu"
            : "Menu Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedMenu || selectedMenuForEdit}
        width={450}
      >
        {visible || selectedMenuForEdit ? (
          <AddMenu
            onCloseMethod={onClose}
            visibleData={selectedMenuForEdit}
          />
        ) : (
          <SingleMenu MenuDetails={selectedMenu} />
        )}
      </Drawer>
    </>
  );
};

export default MenuList;
