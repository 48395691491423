import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddChapter from "./AddChapter";
import { debounce } from "lodash";
import moment from "moment";
import _ from "lodash";
import { AddBtn, Loader, Pagination } from "../common";
const ChapterList = (): ReactElement => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedChapter, setSelectedChapter] = useState(undefined);
  const [shopId, setShopId] = useState<any>("");
  const [selectedChapterForEdit, setSelectedChapterForEdit] =
    useState(undefined);
  const [chapterId, setChapterId] = useState<any>();
  const [courseId, setCourseId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const isDeleted = "false";
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [chapterOptions, setChapterOptions] = useState({
    loading: false,
    list: [],
  });
  const [chapterData, setChapterData] = useState<any>({
    loading: false,
    data: null,
  });
  const [courseOptions, setCourseOptions] = useState({
    loading: false,
    list: [],
  });

  const getChapter = useCallback(async () => {
    setChapterData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    axios
      .get(
        `${encodedUri}/admin/chapter?page=0&limit=20` +
        (courseId ? `&courseId=${courseId}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (sortBy ? `&sortBy=${sortBy}` : ``) +
        (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChapterData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setChapterData({ loading: false, data: [] });
        console.error("Chapter: Error", err);
      });
  }, [type,chapterId,shopId,courseId,sortBy,sortOrder,isActive, isDeleted, limit, page]);
  
  useEffect(() => {
    getChapter();
  }, [getChapter]);

  const getCourseOptions = useCallback(async (courseId?: string) => {
    setCourseOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/course?page=0&limit=20` +
        (courseId ? `&name=${courseId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCourseOptions({
      loading: false,
      list: res?.data?.courses?.map((course: any) => ({
        label: course.name,
        value: course.name,
      })),
    });
  }, []);

  const onClose = () => {
    getChapter();
    setVisible(undefined);
    setSelectedChapter(undefined);
    setSelectedChapterForEdit(undefined);
  };

  const getChapterOptions = useCallback(async (key?: string) => {
    setChapterOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ELARNING_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/chapter?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setChapterOptions({
      loading: false,
      list: res?.data?.chapters?.map((chapter: any) => ({
        label: chapter.name,
        value: chapter.name,
      })),
    });
  }, []);
  const getShopsOptions = useCallback(async (key?: any) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/chapter`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getChapter();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "chapter") getChapterOptions(value);
        if (field === "course") getCourseOptions(value);
        if (field === "shop") getShopsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getChapterOptions,setCourseOptions,getShopsOptions]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
  };

  const reseAllFieldData = () => {
    setChapterId("");
    setCourseId("");
    setSortBy("");
    setSortOrder("");
    setShopId("");
    setKey("");
    setIsActive("");
    setType("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getCourseOptions();
    }
  }, [showSearch]);
  useEffect(() => {
    if (showSearch) {
      getChapterOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Chapter List`}
        subTitle={`${
          chapterData?.data?.totalElements ||
          chapterData?.data?.disciplines?.length ||
          0
        } Chapter(s)`}
        childComponent={false}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="course_chapter_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Chapter"
                optionFilterProp="children"
                onChange={(val) => setChapterId(val)}
                onSearch={(e) => handleSearch(e, "chapter")}
                filterOption={() => {
                  return true;
                }}
                options={chapterOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="course_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Course"
                optionFilterProp="children"
                onChange={(val) => setCourseId(val)}
                onSearch={(e) => handleSearch(e, "course")}
                filterOption={() => {
                  return true;
                }}
                options={courseOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item> 
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {chapterData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {chapterData?.data?.chapters?.length ? (
                      chapterData?.data?.chapters?.map(
                        (chapter: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            draggable="true"
                          >
                            <td>
                              <Avatar size={40} src={chapter?.uploadDetails?.imageUrl} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {chapter?.name}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={chapter?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(chapter?.id, val);
                                }}
                                loading={
                                  confirmLoading &&
                                  chapter?.id === confirmLoading
                                }
                              />
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedChapterForEdit(chapter);
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {chapter?.createdBy?.name ||
                                  chapter?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    chapter?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(chapter?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(chapter?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {chapter?.updatedBy?.name ||
                                  chapter?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    chapter?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(chapter?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(chapter?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...chapterData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Chapter"
            : selectedChapterForEdit
            ? "Edit Chapter"
            : "Chapter Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedChapter || selectedChapterForEdit}
        width={450}
      >
        {visible || selectedChapterForEdit ? (
          <AddChapter
            onCloseMethod={onClose}
            visibleData={selectedChapterForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>
    </>
  );
};

export default ChapterList;
