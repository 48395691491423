import React from "react";
import MainLayout from "../../../components/Layouts";
import ShopShippingZoneList from "../../../components/Shop/shippingZone";

const ShopShippingZonePage = () => {
  return (
    <MainLayout>
      <ShopShippingZoneList />
    </MainLayout>
  );
};

export default ShopShippingZonePage;
