import React from "react";
import ProgramList from "../../components/Programs";
import MainLayout from "../../components/Layouts";

const ProgramPage = () => {
  return (
    <MainLayout>
      <ProgramList />
    </MainLayout>
  );
};

export default ProgramPage;
