import React from "react";
import MainLayout from "../../../components/Layouts";
import TopUpDetailsList from "../../../components/Corporate/TopUp/details";

const TopUpDetailsPage = () => {
  return (
    <MainLayout>
      <TopUpDetailsList />
    </MainLayout>
  );
};

export default TopUpDetailsPage;
