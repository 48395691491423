import React from "react";
import MainLayout from "../../components/Layouts";
import DefaultDeliveryConfig from "../../components/Config/DefaultDeliveryConfig";

const DefaultDeliveryConfigPage = () => {
  return (
    <MainLayout>
      <DefaultDeliveryConfig />
    </MainLayout>
  );
};

export default DefaultDeliveryConfigPage;
