import React from "react";
import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import CompanyBlanceHistoryList from "../../components/Finance/CompanyBlanceHistory";
const ComapnyBlanceHistoryPage = (): ReactElement => {
  return (
    <MainLayout>
      <CompanyBlanceHistoryList />
    </MainLayout>
  );
};

export default ComapnyBlanceHistoryPage;
