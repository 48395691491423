import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Popconfirm,
  Spin,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import { isAllowedService } from "../../utils/services";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddURL from "./AddShortUrl";
import moment from "moment";
import React from "react";
import { debounce } from "lodash";

const URLList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(10);
  const [shortUrl, setShortUrl] = useState("");
  const [longUrl, setLongUrl] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedURL, setSelectedURL] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shortUrlOptions, setShortUrlOptions] = useState({
    loading: false,
    list: [],
  });
  const [longUrlOptions, setLongUrlOptions] = useState({
    loading: false,
    list: [],
  });
  const [URLData, setURLData] = useState<any>({
    loading: false,
    data: null,
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
  };
  const getUrls = useCallback(async () => {
    setURLData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_AI_API}`;
    axios
      .get(
        `${encodedUri}/short-url?` +
          (shortUrl ? `&shortUrl=${shortUrl}` : ``) +
          (longUrl ? `&longUrl=${longUrl}` : ``) +
          (page
            ? `&page=${page == URLData.data?.currentPageNumber ? 0 : page || 0}`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setURLData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setURLData({ loading: false, data: [] });
        console.error("URLs: Error", err);
      });
  }, [shortUrl, longUrl, page, limit]);

  const getShortUrlOptions = useCallback(async (key: any) => {
    setShortUrlOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_AI_API}`;
    return axios
      .get(
        `${encodedUri}/short-url` + (shortUrl ? `?shortUrl=${shortUrl}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShortUrlOptions({
          loading: false,
          list: res.data?.urls?.map((url: { shortUrl: any }) => ({
            label: url.shortUrl,
            value: url.shortUrl,
          })),
        });
      })
      .catch((err) => {
        setShortUrlOptions({ loading: false, list: [] });
        console.error("Locations: Error", err);
      });
  }, []);

  const getLongUrlOptions = useCallback(async (key: any) => {
    setLongUrlOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_AI_API}`;
    return axios
      .get(`${encodedUri}/short-url` + (longUrl ? `?longUrl=${longUrl}` : ``), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLongUrlOptions({
          loading: false,
          list: res.data?.urls?.map((url: { longUrl: any }) => ({
            label: url.longUrl,
            value: url.longUrl,
          })),
        });
      })
      .catch((err) => {
        setLongUrlOptions({ loading: false, list: [] });
        console.error("Locations: Error", err);
      });
  }, []);

  const onShortURLRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_AI_API}/short-url/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Url Remove Successfully", "success");
            getUrls();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const debounceLocationOptionsFetcher = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shortUrl") getShortUrlOptions(value);
        else if (field === "longUrl") getLongUrlOptions(value);
      }
      //getShortUrlOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getShortUrlOptions]);

  useEffect(() => {
    if (showSearch) {
      getShortUrlOptions("");
    }
  }, [showSearch]);
  useEffect(() => {
    if (showSearch) {
      getLongUrlOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getUrls();
  }, [getUrls]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const onClose = () => {
    form.resetFields();
    getUrls();
    setVisible(undefined);
    setSelectedURL(undefined);
  };

  const reseAllFieldData = () => {
    setShortUrl("");
    setLongUrl("");
    form.resetFields();
  };

  const success = () => {
    message.success("Short URL Copied!");
  };

  return (
    <>
      <BreadCrumb
        title="URL List"
        subTitle={`${
          URLData?.data?.totalElements || URLData?.data?.urls?.length || 0
        } URL(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="short_url_search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => debounceLocationOptionsFetcher(e, "shortUrl")}
                onSelect={(val: any) => setShortUrl(val?.toString())}
                options={shortUrlOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  shortUrlOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  style={{ width: "calc(100% - 40px)" }}
                  placeholder="Search by Short Url"
                  onSearch={(val) => setShortUrl(val)}
                  enterButton
                  loading={shortUrlOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="long_url_search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => debounceLocationOptionsFetcher(e, "longUrl")}
                onSelect={(val: any) => setLongUrl(val?.toString())}
                options={longUrlOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  longUrlOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  style={{ width: "calc(100% - 40px)" }}
                  placeholder="Search by Long Url"
                  onSearch={(val) => setLongUrl(val)}
                  enterButton
                  loading={longUrlOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {URLData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Long Url</th>
                      <th scope="col">Short Url</th>
                      <th scope="col">Short Code</th>
                      <th scope="col">View Count</th>
                      <th scope="col">Updated Info</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {URLData?.data?.urls?.length ? (
                      URLData?.data?.urls?.map((shortURL: any, index: any) => (
                        <tr className="border-t hover:bg-gray-100" key={index}>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shortURL?.longUrl?.slice(0, 40)}
                            </span>
                          </td>
                          <td>
                            <div className="flex items-center gap-3">
                              <Button
                                type="dashed"
                                shape="circle"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    shortURL?.shortUrl
                                  );
                                  success();
                                }}
                              >
                                <CopyOutlined title="Copy" />
                              </Button>
                              <a
                                href={shortURL?.shortUrl}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <span className="font-medium text-gray-500">
                                  {shortURL?.shortUrl}
                                </span>
                              </a>
                            </div>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shortURL?.shortCode}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {shortURL?.viewCount}
                            </span>
                          </td>

                          <td>
                            <small className="font-bold uppercase text-gray-500">
                              {shortURL?.createdBy?.name ||
                                shortURL?.createdBy?.mobileNumber?.replace(
                                  "+88",
                                  ""
                                )}
                              {" - "}
                            </small>
                            <small
                              className={`font-capitalize ${
                                moment().diff(shortURL?.createdAt, "minutes") >=
                                60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(shortURL?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(shortURL?.createdAt).format("lll")}
                            </span>
                          </td>
                          <td>
                            <div className="flex gap-2">
                              {isAllowedService(`Short URL Delete`) && (
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={shortURL?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShortURLRemove(shortURL?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(shortURL?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...URLData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={"Add URL"}
        placement="right"
        onClose={onClose}
        open={visible || selectedURL}
        width={450}
      >
        <AddURL onCloseMethod={onClose} />
      </Drawer>
    </>
  );
};

export default URLList;
