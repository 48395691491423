import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios";

const AddCurriculum = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [programOptions, setProgramOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [disciplineOptions, setDisciplineOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [curriculumData, setCurriculumData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");
  const [shopId, setShopId] = useState<any>("");
  const [programId, setProgramId] = useState<any>("");
  const [disciplineId, setDisciplineId] = useState<any>("");
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      description: convertedText || data.description,
      disciplineId: disciplineId,
      name: data.name,
      programId: programId || data.programId,
      shopId: shopId || data.shopId,
      type: data.type,
      uploadDetails: {
        bannerImageUrl: bannerImgUrl
          ? bannerImgUrl
          : data?.uploadDetails?.bannerImageUrl || "NULL",
        imageUrl: generatedImgUrl
          ? generatedImgUrl
          : data?.uploadDetails?.imageUrl || "NULL",
        videoUrl: data.videoUrl || "NULL",
        title: data.name,
      },
    };

    if (curriculumData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/curriculum`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: curriculumData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Curriculum Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/curriculum`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Curriculum Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setCurriculumData(visibleData);
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
    }
  }, [visibleData]);

  useEffect(() => {
    if (curriculumData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
    }
  }, [curriculumData, form, visibleData]);

  const getShopsOptions = useCallback(async (key?: any) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);

  const getProgramOptions = useCallback(async (programId?: string) => {
    setProgramOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/program?page=0&limit=20` +
        (programId ? `&name=${programId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => {
        return {
          value: program?.id,
          label: program?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getProgramOptions();
  }, [getProgramOptions]);

  const getDisciplineOptions = useCallback(async (programId?: string) => {
    setDisciplineOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/discipline?page=0&limit=20` +
        (programId ? `&name=${programId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDisciplineOptions({
      loading: false,
      list: res?.data?.disciplines?.map((discipline: any) => {
        return {
          value: discipline?.id,
          label: discipline?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getDisciplineOptions();
  }, [getDisciplineOptions]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "shop") getShopsOptions(value);
          if (field === "program") getProgramOptions(value);
          if (field === "discipline") getDisciplineOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopsOptions, getProgramOptions, getDisciplineOptions, type]);
  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setBannerImgUrl(undefined);
    setVideoUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
    setStartUpload3(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...curriculumData,
        }}
      >
        <div className="">
          <Form.Item
            hasFeedback
            label="Program"
            rules={[
              {
                required: true,
                message: "Program is required!",
              },
            ]}
            name="programId"
          >
            <Select
              showSearch
              placeholder="Select Program"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "program");
              }}
              onChange={(val) => setProgramId(val)}
              filterOption={() => {
                return true;
              }}
              options={programOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Discipline"
            rules={[
              {
                required: false,
                message: "Discipline is required!",
              },
            ]}
            name="disciplineId"
          >
            <Select
              showSearch
              placeholder="Select Discipline"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "discipline");
              }}
              onChange={(val) => setDisciplineId(val)}
              filterOption={() => {
                return true;
              }}
              options={disciplineOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item hasFeedback label="Description" name="description">
          <JoditEditor
            ref={editor}
            value={convertedText}
            config={jodiConfig as any}
            onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Form.Item>
        <div>
              <Form.Item hasFeedback label="Curriculum Type" name="type">
                <Select
                  placeholder="Select Type"
                  options={[
                    {
                      value: "COURSE",
                      label: "COURSE",
                    },
                    {
                      value: "DISCIPLINE",
                      label: "DISCIPLINE",
                    },
                  ]}
                ></Select>
              </Form.Item>
            </div> 
        <div className="">
          <Form.Item
            hasFeedback
            label="Organization"
            rules={[
              {
                required: false,
                message: "Organization is required!",
              },
            ]}
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Video Url"
            rules={[
              {
                required: false,
                message: "Video Url is Required!",
              },
            ]}
            name="videoUrl"
          >
            <Input id="videoUrl" type="text" placeholder="Enter videoUrl Url" />
          </Form.Item>
        </div>
        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Image
              <Tooltip
                placement="bottom"
                title={
                  "Curriculum image must be transparent & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">
              Banner Image
              {/* <Tooltip
                placement="bottom"
                title={
                  "Banner image must be transparent & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip> */}
            </Divider>
            <ImageUploader
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
            setStartUpload2("Uploading");
          }}
          onClickReset={resetData}
          disabled={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
          loading={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
        />
      </Form>
    </>
  );
};

export default AddCurriculum;
