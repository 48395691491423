import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn, TreeSelectTwo } from "../common";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddHistory = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState(undefined);
  const [paymentMethod, setPaymentMethod] = useState<any>();

  const [methodOptions, setMethodOptions] = useState({
    loading: false,
    list: null,
  });

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl) {
      setLoading(true);

      const readyData = data && {
        amount: data.amount,
        description: data.description,
        paymentMethod: paymentMethod,
        imageUrl: generatedImgUrl ? generatedImgUrl : data?.imageUrl,
      };

      if (historyData) {
        await fetch(
          `${process.env.REACT_APP_TOPUP_API}/admin/topup/vr-history`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: historyData?.id,
              deleted: false,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("History Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(
          `${process.env.REACT_APP_TOPUP_API}/admin/topup/vr-history`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("History Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };

  const getMethodOptions = useCallback(async () => {
    setMethodOptions({ loading: true, list: null });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(`${encodedUri}/payment-method?type=JC_MART`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMethodOptions({
          loading: false,
          list: res.data?.methods?.map((method) => ({
            label: `${method.title} - ${method.name}`,
            value: method.name,
          })),
        });
      })
      .catch((err) => {
        setMethodOptions({ loading: false, list: [] });
        console.error("Method: Error", err);
      });
  }, []);

  useEffect(() => {
    if (visibleData) {
      setHistoryData(visibleData);
      setGeneratedImgUrl(visibleData?.imageUrl);
    }
  }, [visibleData]);

  useEffect(() => {
    if (historyData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [historyData, visibleData]);

  useEffect(() => {
    getMethodOptions();
  }, [getMethodOptions]);

  // **********************************
  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...historyData,
          }}
        >
          <Form.Item
            hasFeedback
            label="Amount"
            rules={[
              {
                required: true,
                message: "Amount is Required!",
              },
            ]}
            name="amount"
          >
            <Input id="amount" type="text" placeholder="Enter Amount" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Payment Method"
            rules={[
              {
                required: true,
                message: "Payment Method is required!",
              },
            ]}
            name="paymentMethod"
          >
            <Select
              allowClear
              showSearch
              placeholder="Payment Method"
              optionFilterProp="children"
              onChange={(val) => setPaymentMethod(val)}
              onSearch={getMethodOptions}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {methodOptions?.list?.map((method, i) => (
                <Option value={method?.value} key={i}>
                  {method?.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Description"
            rules={[
              {
                required: true,
                message: "Description is Required!",
              },
            ]}
            name="description"
          >
            <Input.TextArea
              rows={5}
              id="description"
              placeholder="Enter Description"
            />
          </Form.Item>

          <h4 className="title" style={{ maxWidth: "99%" }}>
            Upload Document:
          </h4>
          <div className="">
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            />
          </div>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
              onClick={(e) => {
                e.preventDefault();
                setStartUpload("Uploading");
              }}
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
                setGeneratedImgUrl(undefined);
                setStartUpload(undefined);
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddHistory;
