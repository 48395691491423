import React from "react";
import MainLayout from "../../components/Layouts";
import CreateMergeOrder from "../../components/MergeOrder/AddMergeOrder";

const MergeOrderEditPage = () => {
  return (
    <MainLayout>
      <CreateMergeOrder />
    </MainLayout>
  );
}; 
 
export default MergeOrderEditPage;
