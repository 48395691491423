import React from "react";
import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import CompanyTopUpHistory from "../../components/Reports/CompanyTopUpHistory";
const MyTransactionPage = (): ReactElement => {
  return (
    <MainLayout>
      <CompanyTopUpHistory />
    </MainLayout>
  );
};

export default MyTransactionPage;
