import { Avatar, Layout, Rate } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { getImgUrl } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Layouts/Breadcrumb";
import noImage from "../../../images/noImage.png";
const { Content } = Layout;

const FeedbackLayout = ({
  children,
  feedbackId,
  title,
  extra,
  subTitle,
}: {
  children?: ReactElement;
  feedbackId?: string;
  title: string;
  subTitle?: string;
  extra?: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();
  const { feedbackInfo } = useSelector((state) => (state as any)?.authReducer);
  return (
    <React.Fragment>
      <Layout className="min-vh-100">
        <main>
          <div className="grid grid-cols-3 px-2 pt-4 xl:grid-cols-10 gap-4 xl:gap-4">
            <div className="col-span-full">
              <BreadCrumb
                className="site-page-header px-0 py-0"
                onBack={() => navigate(-1)}
                title={
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {title}
                  </h1>
                }
                extra={extra}
              />
            </div>

            <div className="col-span-full xl:col-span-3">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-4 xl:grid-cols-1 min-h-full">
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl">
                  <div className="sm:flex__ xl:block sm:space-x-4 xl:space-x-0">
                    <div className="w-full overflow-auto h-48">
                      <img
                        src={feedbackInfo?.customerImage || noImage}
                        alt=""
                        className="rounded-t-xl object-cover w-full"
                      />
                    </div>
                    <div className="px-4 pb-2">
                      <div className="-mt-12 xl:-mt-14">
                        <Avatar
                          size={100}
                          shape="circle"
                          src={getImgUrl(feedbackInfo?.customerImage)}
                          className="mb-5 w-20 h-20 shadow-lg border-4 border-white bg-gray-50"
                        />
                      </div>
                      <div>
                        <h2 className="text-xl font-bold">
                          {feedbackInfo?.customerName?.toUpperCase()}
                        </h2>

                        {feedbackInfo?.rating && (
                          <>
                            <Rate
                              value={feedbackInfo?.rating || 1}
                              className="mt-1"
                              disabled
                            />
                            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                              {feedbackInfo?.numberOfRating || 0}
                            </span>
                          </>
                        )}
                      </div>{" "}
                      {feedbackInfo?.customerContact && (
                        <div className="mb-4 sm:flex xl:block">
                          <div className="sm:flex-1">
                            <address className="text-sm not-italic font-normal text-gray-500">
                              <div className="mt-4">Mobile Number</div>
                              <div className="mb-2 text-sm font-medium text-gray-900">
                                {feedbackInfo?.customerContact?.split("+88")}
                              </div>
                            </address>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 xl:col-span-7">
              <Layout>
                <Content className="main-content-layout">{children}</Content>
              </Layout>{" "}
            </div>
          </div>
        </main>
      </Layout>
    </React.Fragment>
  );
};

FeedbackLayout.propType = {
  children: PropTypes.element,
};

export default FeedbackLayout;
