import BreadCrumb from "../Layouts/Breadcrumb";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Line, Column } from "@ant-design/plots";
import { Pie } from "@ant-design/charts";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import moment from "moment";
import { jcTypeArray } from "../../utils";

const Dashboard = (): ReactElement => {
   const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [type1, setType1] = useState(type);
  const [form] = Form.useForm();
  const InputGroup = Input.Group;
  const loc = useLocation();
  const [filterBy, setFilterBy] = useState<any>("day"); // default is 'middle'

  const [range, setRange] = useState<any>({
    from: moment().startOf(filterBy).toISOString(),
    to: moment().endOf(filterBy).toISOString(),
  }); //To get the Current Date

  useEffect(() => {
    setRange({
      from: moment().startOf(filterBy).toISOString(),
      to: moment().endOf(filterBy).toISOString(),
    });
  }, [filterBy]);

  const [seoMissingProductData, setSeoMissingProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [imageMissingProductData, setImageMissingProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [totalUser, setTotalUser] = useState<any>({
    loading: false,
    data: null,
  });

  const [totalAdmin, setTotalAdmin] = useState<any>({
    loading: false,
    data: null,
  });
  const [newMerchant, setNewMerchant] = useState<any>({
    loading: false,
    data: null,
  });
  const [totalData, setTotalData] = useState<any>({
    loading: false,
    data: null,
  });
  const [totalearningData, setTotalEarningData] = useState<any>({
    loading: false,
    data: null,
  });
  const [totalOrderData, setTotalOrderData] = useState<any>([]);
  const orderStatus =
    "TOTAL,INITIATED,CONFIRMED_BY_CS,PROCESSING,ON_DELIVERY,REVIEWED,CANCELLED";
  const getOrderData = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTotalOrderData({ data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/order/total-order-counted-by-status?type=${type1}` +
          (orderStatus ? `&statuses=${orderStatus}` : ``) +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalEarningData({ loading: false, data: res.data });
        setTotalOrderData({
          data: res.data?.orders?.map((order: any) => ({
            type: order.status,
            value: order.count,
          })),
        });
      })
      .catch((err) => {
        setTotalOrderData({ data: [] });
        console.error("totalOrder: Error", err);
      });
  }, [orderStatus, range]);

  const getTotalSEOMissingProducts = useCallback(async () => {
    setSeoMissingProductData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/product/seo-missing?type=${type1}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSeoMissingProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSeoMissingProductData({ loading: false, data: [] });
        console.error("Product: Error", err);
      });
  }, []);

  const getTotalImageMissingProducts = useCallback(async () => {
    setImageMissingProductData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/product/image-missing?type=${type}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setImageMissingProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setImageMissingProductData({ loading: false, data: [] });
        console.error("Image: Error", err);
      });
  }, []);

  const getTotalUser = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTotalUser({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;

    axios
      .get(
        `${encodedUri}/admin/user?page=0&limit=20` +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalUser({ loading: false, data: res.data?.totalElements });
      })
      .catch((err) => {
        setTotalUser({ loading: false, data: [] });
        console.error("Shop: Error", err);
      });
  }, [range]);
  const getTotalAdmin = useCallback(async () => {
    setTotalAdmin({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;

    axios
      .get(`${encodedUri}/admin/all/V2?page=0&limit=20`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTotalAdmin({ loading: false, data: res.data?.totalElements });
      })
      .catch((err) => {
        setTotalAdmin({ loading: false, data: [] });
        console.error("Shop: Error", err);
      });
  }, []);
  const getNewMerchant = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setNewMerchant({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;

    axios
      .get(
        `${encodedUri}/admin/merchant/all?serviceType=${type}&page=0&limit=20` +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setNewMerchant({ loading: false, data: res.data?.totalElements });
      })
      .catch((err) => {
        setNewMerchant({ loading: false, data: [] });
        console.error("Merchant: Error", err);
      });
  }, [range]);
  const getTotalData = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setTotalData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

    axios
      .get(
        `${encodedUri}/admin/product/total-counted-product?type=${type}&page=0&limit=20` +
          (from ? `&startDate=${from}` : ``) +
          (to ? `&endDate=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTotalData({ loading: false, data: [] });
        console.error("Merchant: Error", err);
      });
  }, [range]);

  const status = "COMPLETED";
  const returnStatus = "CANCELLED";

  //order data chart

  const orderChartConfig = {
    appendPadding: 10,
    data:
      totalOrderData?.data?.filter((d) => d.type?.toLowerCase() !== "total") ||
      [],
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false as const,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        formatter: function formatter() {
          return `Total\n${
            totalOrderData?.data?.find((d) => d.type?.toLowerCase() == "total")
              ?.value || 0
          }`;
        },
      },
    },
  };

  //order data chart end
  useEffect(() => {
    //asyncFetch();
    getTotalSEOMissingProducts();
    getTotalImageMissingProducts();
    getTotalUser();
    getTotalAdmin();
    getNewMerchant();
    getTotalData();
    getOrderData();
  }, [
    getTotalSEOMissingProducts,
    getTotalImageMissingProducts,
    getTotalUser,
    getTotalAdmin,
    getNewMerchant,
    getTotalData,
    getOrderData,
  ]);
  return (
    <>
      <BreadCrumb title="Dashboard" />
      <div className="">

      <InputGroup size="large">
          <Row gutter={8}>
            <Col span={8}>
          <Radio.Group
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value)}
          >
            <Radio.Button value="day">Today</Radio.Button>
            <Radio.Button value="week">This Week</Radio.Button>
            <Radio.Button value="month">This Month</Radio.Button>
            <Radio.Button value="year">This Year</Radio.Button>
          </Radio.Group>
       
            </Col>
            <Col span={5}>
            <Form form={form} className="search_form">
                  <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType1(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                 
                  </Form>
            </Col>
          </Row>
        </InputGroup>               
                  
      </div>

      <div className="">
        <div className="grid grid-cols-2 gap-6 mb-6 w-full xl:grid-cols-4 2xl:grid-cols-4">
          <Link to={`/customer/`}>
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 640 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M352 128c0 70.7-57.3 128-128 128S96 198.7 96 128 153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8 2.4-.1 4.7-.2 7.1-.2h61.4c89.1 0 161.3 72.2 161.3 161.3 0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9 19.7-26.6 31.3-59.5 31.3-95.1 0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900 sm:text-2xl">
                    {totalUser?.data}
                  </span>
                  <h3 className="text-base font-normal text-gray-500">
                    Total Client
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/administration/admins">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M224 0c70.7 0 128 57.3 128 128s-57.3 128-128 128S96 198.7 96 128 153.3 0 224 0zm-14.9 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2h39.5c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 39.5-161.2c77.2 12 136.3 78.8 136.3 159.4 0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-80.6 59.1-147.4 136.3-159.4l39.5 161.2 33.4-123.9z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalAdmin?.data}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Admins
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/merchants/">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 1000 1000"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M1000 940H776V790c0-36-10-63-30-81s-71.333-47.667-154-89c26.667-20 40-48 40-84 0-10.667-4.333-21.667-13-33-8.667-11.333-15-28.333-19-51-1.333-5.333-6-10.667-14-16s-12.667-19.333-14-42c0-16 4-26 12-30-4-22.667-6.667-42.667-8-60-2.667-25.333 5-51.333 23-78s49.667-40 95-40 77.333 13.333 96 40 26.667 52.667 24 78l-8 60c8 4 12 14 12 30-1.333 22.667-6 36.667-14 42-8 5.333-12.667 10.667-14 16-4 22.667-10.333 39.667-19 51-8.667 11.333-13 22.333-13 33 0 28 7 50 21 66s39.667 32 77 48c74.667 30.667 118 57.333 130 80 4 5.333 7 25.667 9 61s3.667 69 5 101v48M512 678c121.333 52 182 93.333 182 124v138H0V756c0-29.333 28-55.333 84-78 50.667-21.333 85.333-42.667 104-64s28-50.667 28-88c0-13.333-6.333-28-19-44s-21-38.667-25-68c-1.333-6.667-7.333-14-18-22s-17.333-26.667-20-56c0-9.333 1-17 3-23s4.333-10.333 7-13l4-2c-4-30.667-7.333-58-10-82-2.667-33.333 8.333-67.667 33-103s67-53 127-53 102.333 17.667 127 53 35.667 69.667 33 103l-10 82c9.333 5.333 14 18 14 38-2.667 29.333-9.333 48-20 56s-16.667 15.333-18 22c-4 29.333-12.333 52-25 68s-19 30.667-19 44c0 37.333 9.333 66.667 28 88s53.333 42.667 104 64" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {newMerchant?.data}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Merchant
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/products/">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M832 312H696v-16c0-101.6-82.4-184-184-184s-184 82.4-184 184v16H192c-17.7 0-32 14.3-32 32v536c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V344c0-17.7-14.3-32-32-32zm-208 0H400v-16c0-61.9 50.1-112 112-112s112 50.1 112 112v16z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalProduct}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/products/">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M8 19 A2 2 0 0 1 6 21 A2 2 0 0 1 4 19 A2 2 0 0 1 8 19 z" />
                    <path d="M19 19 A2 2 0 0 1 17 21 A2 2 0 0 1 15 19 A2 2 0 0 1 19 19 z" />
                    <path d="M17 17H6V3H4" />
                    <path d="M6 5l7.999.571m5.43 4.43L19 13H6M17 3l4 4M21 3l-4 4" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalStockOutProduct}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Stockout Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="#">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 2.75a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25h-6.5a.75.75 0 00-.53.22L4.5 14.44v-2.19a.75.75 0 00-.75-.75h-2a.25.25 0 01-.25-.25v-8.5zM1.75 1A1.75 1.75 0 000 2.75v8.5C0 12.216.784 13 1.75 13H3v1.543a1.457 1.457 0 002.487 1.03L8.061 13h6.189A1.75 1.75 0 0016 11.25v-8.5A1.75 1.75 0 0014.25 1H1.75zm5.03 3.47a.75.75 0 010 1.06L5.31 7l1.47 1.47a.75.75 0 01-1.06 1.06l-2-2a.75.75 0 010-1.06l2-2a.75.75 0 011.06 0zm2.44 0a.75.75 0 000 1.06L10.69 7 9.22 8.47a.75.75 0 001.06 1.06l2-2a.75.75 0 000-1.06l-2-2a.75.75 0 00-1.06 0z"
                    />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalProductReview}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Product Review
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/shops/">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M882 272.1V144c0-17.7-14.3-32-32-32H174c-17.7 0-32 14.3-32 32v128.1c-16.7 1-30 14.9-30 31.9v131.7a177 177 0 0014.4 70.4c4.3 10.2 9.6 19.8 15.6 28.9v345c0 17.6 14.3 32 32 32h676c17.7 0 32-14.3 32-32V535a175 175 0 0015.6-28.9c9.5-22.3 14.4-46 14.4-70.4V304c0-17-13.3-30.9-30-31.9zM214 184h596v88H214v-88zm362 656.1H448V736h128v104.1zm234 0H640V704c0-17.7-14.3-32-32-32H416c-17.7 0-32 14.3-32 32v136.1H214V597.9c2.9 1.4 5.9 2.8 9 4 22.3 9.4 46 14.1 70.4 14.1s48-4.7 70.4-14.1c13.8-5.8 26.8-13.2 38.7-22.1.2-.1.4-.1.6 0a180.4 180.4 0 0038.7 22.1c22.3 9.4 46 14.1 70.4 14.1 24.4 0 48-4.7 70.4-14.1 13.8-5.8 26.8-13.2 38.7-22.1.2-.1.4-.1.6 0a180.4 180.4 0 0038.7 22.1c22.3 9.4 46 14.1 70.4 14.1 24.4 0 48-4.7 70.4-14.1 3-1.3 6-2.6 9-4v242.2zm30-404.4c0 59.8-49 108.3-109.3 108.3-40.8 0-76.4-22.1-95.2-54.9-2.9-5-8.1-8.1-13.9-8.1h-.6c-5.7 0-11 3.1-13.9 8.1A109.24 109.24 0 01512 544c-40.7 0-76.2-22-95-54.7-3-5.1-8.4-8.3-14.3-8.3s-11.4 3.2-14.3 8.3a109.63 109.63 0 01-95.1 54.7C233 544 184 495.5 184 435.7v-91.2c0-.3.2-.5.5-.5h655c.3 0 .5.2.5.5v91.2z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalShop}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Shops
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/categories/">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M10 3H4a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalParentCategory}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Parent Category
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/categories/level=2">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M10 3H4a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1zM9 9H5V5h4v4zm11 4h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4v4zM17 3c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM7 13c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalSubCategory}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Sub Category
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/categories/level=3">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 4h6v6h-6zM4 14h6v6H4z" />
                    <path d="M20 17 A3 3 0 0 1 17 20 A3 3 0 0 1 14 17 A3 3 0 0 1 20 17 z" />
                    <path d="M10 7 A3 3 0 0 1 7 10 A3 3 0 0 1 4 7 A3 3 0 0 1 10 7 z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalChildCategory}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total child Category
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/brands">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M0 23.291h19.601v-2.978H2.98V3.689h16.626V14.6h-1.422l2.908 2.909L24 14.599h-1.417V.709H0v22.582zm16.148-9.935a3.028 3.028 0 00-1.653-1.58 4.013 4.013 0 00-.916-.254 1.72 1.72 0 00.735-.296c.241-.161.455-.364.647-.609.192-.247.345-.535.458-.863.115-.33.171-.686.171-1.069 0-.648-.126-1.186-.377-1.617a2.858 2.858 0 00-1.033-1.033 4.614 4.614 0 00-1.536-.547 10.311 10.311 0 00-1.868-.162c-.754 0-1.382.018-1.887.054-.502.037-.945.083-1.329.145v12.933c.684.083 1.293.141 1.834.171.539.03 1.082.044 1.634.044a12.6 12.6 0 002.057-.162 5.022 5.022 0 001.723-.592c.499-.288.893-.68 1.187-1.177.294-.498.441-1.135.441-1.914 0-.575-.096-1.065-.288-1.472zm-5.983-6.035c.91-.111 1.873-.054 2.301.304.38.317.607.599.607 1.42 0 .751-.357 1.195-.608 1.356-.251.161-.59.368-1.403.368h-.897V7.321zm3.029 8.68c-.449.39-1.114.552-1.816.552-.79 0-1.213-.072-1.213-.072v-3.737h1.132c.711 0 1.438.126 1.832.464.509.437.611.895.611 1.505.001.609-.212.998-.546 1.288z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalBrand}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Brand
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/promotion/campaign">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M3 18l9-6.462L3 6v12h18V6l-9 5.538" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {totalData?.data?.product?.totalCampaign}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Campaign
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/products?page-type=seo-missing">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M7 8H4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 011 1v2a1 1 0 01-1 1H3M14 16h-4V8h4M11 12h2" />
                    <path d="M18 8 H20 A1 1 0 0 1 21 9 V15 A1 1 0 0 1 20 16 H18 A1 1 0 0 1 17 15 V9 A1 1 0 0 1 18 8 z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900 sm:text-2xl">
                    {seoMissingProductData?.data?.totalElements}
                  </span>
                  <h3 className="text-base font-normal text-gray-500">
                    SEO Missing Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/products?page-type=image-missing">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M7 8H4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 011 1v2a1 1 0 01-1 1H3M14 16h-4V8h4M11 12h2" />
                    <path d="M18 8 H20 A1 1 0 0 1 21 9 V15 A1 1 0 0 1 20 16 H18 A1 1 0 0 1 17 15 V9 A1 1 0 0 1 18 8 z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {imageMissingProductData?.data?.totalElements}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Image Missing Product
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="#">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 7l8.445-5.63a1 1 0 011.11 0L21 7v14a1 1 0 01-1 1H4a1 1 0 01-1-1V7zm2 1.07V20h14V8.07l-7-4.666L5 8.07zM8 16h8v2H8v-2zm0-3h8v2H8v-2zm4-2a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {Math.round(totalearningData?.data?.totalEarning) || 0}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Earning
                  </h3>
                </div>
              </div>
            </div>
          </Link>
          <Link to="#">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-purple-700 rounded-lg">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 7l8.445-5.63a1 1 0 011.11 0L21 7v14a1 1 0 01-1 1H4a1 1 0 01-1-1V7zm2 1.07V20h14V8.07l-7-4.666L5 8.07zM8 16h8v2H8v-2zm0-3h8v2H8v-2zm4-2a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </div>
                <div className="flex-shrink-0 ml-3">
                  <span className="text-2xl font-bold leading-none text-gray-900">
                    {Math.round(totalearningData?.data?.totalPendingEarning) ||
                      0}
                  </span>

                  <h3 className="text-base font-normal text-gray-500">
                    Total Pending Earning
                  </h3>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="grid grid-cols-1 gap-6 mb-6 w-full xl:grid-cols-2">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="mb-2 text-xl font-bold text-gray-900">
                  Orders chart
                </h3>
              </div>
              <div className="flex-shrink-0">
                <Link
                  to="/orders"
                  className="p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  View all
                </Link>
              </div>
            </div>
            {totalOrderData?.data?.length && (
              <div className="">
                <Pie {...orderChartConfig} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
