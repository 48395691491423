import React from "react";
import MainLayout from "../../components/Layouts";
import AddProduct from "../../components/Product/AddProduct";

const ProductEditPage = () => {
  return (
    <MainLayout>
      <AddProduct />
    </MainLayout>
  );
}; 
 
export default ProductEditPage;
