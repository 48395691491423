import React from "react";
import MainLayout from "../../components/Layouts";
import AddBook from "../../components/Books/AddBook";

const ProductAddPage = () => {
  return (
    <MainLayout>
      <AddBook />
    </MainLayout>
  );
};

export default ProductAddPage;
