import React from "react";
import { ReactElement } from "react";
import DriverUnsettleOrderList from "../../../components/Delivery/DeliveryHero/UnsettleOrder";
import MainLayout from "../../../components/Layouts";

const DriverUnsettleOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverUnsettleOrderList />
    </MainLayout>
  );
};

export default DriverUnsettleOrderPage;
