import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import MergeOrderList from "../../components/MergeOrder";
import React from "react";

const MergeOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <MergeOrderList />
    </MainLayout>
  );
};

export default MergeOrderPage;
