import {
  AutoComplete,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { debounce } from "lodash";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const DeliveryReportList = () => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [hubId, setHubId] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [showSearch, setShowSearch] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [pmethodId, setPmethodId] = useState("");
  const [usersOptions, setUsersOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [ordersData, setOrdersData] = useState<any>({
    loading: false,
    data: null,
  });
  const [paymentMethod, setPaymentMethod] = useState<any>({
    loading: false,
    list: [],
  });
  const [salesData, setSalesData] = useState<any>({
    loading: false,
    data: null,
  });

  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });

  const [hubOptions, setHubOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const getDeliveryReport = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrdersData({ loading: true, data: null });
    const encoUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encoUri}/finance/delivery-hero?page=${page || 0}&limit=${
          limit || 16
        }` +
          (mobileNumber
            ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
            : ``) +
          (pmethodId ? `&paymentMethod=${pmethodId}` : ``) +
          (from ? `&startFrom=${from}` : ``) +
          (to ? `&endAt=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrdersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrdersData({ loading: false, data: [] });
        console.error("Data: Error", err);
      });
  }, [range.from, range.to, page, limit, mobileNumber, pmethodId]);

  const getDeliveryReportDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    fetch(
      `${encodedUri}/finance/delivery-hero/download?page=0` +
        (from ? `&startFrom=${from}` : ``) +
        (to ? `&endAt=${to}` : ``) +
        (mobileNumber
          ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
          : ``) +
        (paymentMethod ? `&paymentMethod=${paymentMethod}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((res) => {
        setDownloadData({ loading: false, data: res });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Delivery_Report-${moment(range.from).format("YYYY_MM_DD")}-${moment(
            range.to
          ).format("YYYY_MM_DD")}.xlsx`
        );
        link.click();
        // saveAs(res, `revenue-report-${Math.random().toString(36)}.xlsx`);
      })
      .catch((err) => {
        console.error("Data: Error", err);
      });
  }, [mobileNumber, paymentMethod, range.from, range.to]);

  const getUsersOptions = useCallback(
    async (mobileNumber: any) => {
      setUsersOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      axios
        .get(
          `${encodedUri}/admin/user?` +
            `page=${0}` +
            `&limit=${20}` +
            (status ? `&status=${status}` : ``) +
            (mobileNumber
              ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUsersOptions({
            loading: false,
            list: res.data?.users?.map(
              (user: { fullName: any; mobileNumber: string }) => ({
                label: user.fullName,
                value: user.mobileNumber.replace("+88", ""),
              })
            ),
          });
        })
        .catch((err) => {
          setUsersOptions({ loading: false, list: [] });
          console.error("Users: Error", err);
        });
    },
    [status]
  );

  const getSalesReportDownload = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    fetch(
      `${encodedUri}/finance/sales/download?page=0` +
        (from ? `&fromDate=${from}` : ``) +
        (to ? `&toDate=${to}` : ``) +
        (hubId ? `&hubId=${hubId}` : ``) +
        (status ? `&status=${status}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((res) => {
        setDownloadData({ loading: false, data: res });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Sales_Report-${moment(range.from).format("YYYY_MM_DD")}-${moment(
            range.to
          ).format("YYYY_MM_DD")}.xlsx`
        );
        link.click();
      })
      .catch((err) => {
        console.error("Data: Error", err);
      });
  }, [hubId, range.from, range.to, status]);

  const fetchRef: any = useRef(null);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "user") getUsersOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getUsersOptions]);

  useEffect(() => {
    getDeliveryReport();
  }, [getDeliveryReport]);

  const reseAllFieldData = () => {
    form.resetFields();
    setStatus("");
    setMobileNumber("");
    setPmethodId("");
    setRange({
      from: null,
      to: null,
    });
  };

  const paymentMethods = useMemo(() => {
    let allPayments: any = {};

    salesData?.data?.sales?.find((shop: any) => {
      if (shop?.methodAmount?.length > 0) {
        shop?.methodAmount.forEach((method: any) => {
          allPayments[method?.paymentMethod] = 1;
        });
      }
    });
    return Object.keys(allPayments);
  }, [salesData]);

  const formatedData = useMemo(() => {
    const obj: any = {};
    salesData?.data?.sales?.map((shop: any) => {
      const method: any = {};
      shop?.methodAmount?.map((item: any) => {
        method[item?.paymentMethod] = item?.total;
      });
      obj[shop?.shopName] = method;
    });
    return obj;
  }, [salesData]);

  const fatchPaymentMethod = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_PAY_API}/payment-method/all?` +
        (type ? `&type=${type}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    const response = await data.json();

    setPaymentMethod({
      loading: false,
      list: response?.methods?.map((method: { name: any }) => ({
        value: method.name,
        label: method.name,
      })),
    });
  };

  useEffect(() => {
    fatchPaymentMethod();
  }, []);
  const totalData = useMemo(() => {
    return (
      salesData?.data?.sales?.reduce((obj: any, data: any) => {
        const newObj: any = {};
        console.log(1, obj, data?.methodAmount);

        data?.methodAmount?.map((item: any) => {
          newObj[item?.paymentMethod] =
            (obj[item?.paymentMethod] || 0) + (item?.total || 0);
        });

        const onlyMethods = { ...obj, ...newObj };
        let allMethodsTotal = 0;
        Object.keys(onlyMethods)?.forEach((item) => {
          if (item !== "total") {
            allMethodsTotal += onlyMethods?.[item];
          }
        });

        return {
          ...onlyMethods,
          total: allMethodsTotal,
        };
      }, {}) || {}
    );
  }, [salesData]);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  return (
    <React.Fragment>
      {/* <TopHeading
          {...salesData?.data?.totalElements}
          refetch={getSalesReport}
          title={"Sales Report"}
          download={() => getSalesReportDownload()}
          dissable={!salesData?.data?.sales?.length ? true : false}
          loading={downloadData?.loading}
        /> */}
      <BreadCrumb
        title="Sales Order Report"
        subTitle={`${ordersData?.data?.totalElements} Order(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            <Form.Item name="phone_search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "user")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setMobileNumber(val?.toString())
                }
                options={usersOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  usersOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Phone"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={usersOptions.loading}
                  maxLength={11}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="payment_method">
              <Select
                allowClear
                placeholder="Filter by Payment Method"
                optionFilterProp="children"
                onChange={(val) => setPmethodId(val)}
                filterOption={() => {
                  return true;
                }}
                options={paymentMethod?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {ordersData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order Id</th>
                      <th scope="col">Date</th>
                      <th scope="col">Delivery Hero</th>
                      <th scope="col">DH Contact </th>
                      <th scope="col">Method</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ordersData?.data?.reports?.length ? (
                      ordersData?.data?.reports?.map(
                        (ordersData: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                {ordersData?.orderId}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {moment(ordersData?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                {ordersData?.deliveryMan?.name}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {ordersData?.deliveryMan?.mobileNumber?.split(
                                  "+88"
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {ordersData?.paymentMethod}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`name ${
                                  ordersData?.revenue <= 0
                                    ? `text-danger font-weight-bold`
                                    : ``
                                }`}
                              >
                                {parseInt(ordersData?.total)}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...ordersData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </React.Fragment>
  );
};

export default DeliveryReportList;
