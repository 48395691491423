import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Select, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";

const placement = [
  {
    label: "BEST_SELLER_LEFT",
    value: "BEST_SELLER_LEFT",
  },
  {
    label: "BEST_SELLER_RIGHT_ONE",
    value: "BEST_SELLER_RIGHT_ONE",
  },
  {
    label: "BEST_SELLER_RIGHT_TWO",
    value: "BEST_SELLER_RIGHT_TWO",
  },
  {
    label: "SHOP_MIDDLE",
    value: "SHOP_MIDDLE",
  },
  {
    label: "BRAND_VIDEO",
    value: "BRAND_VIDEO",
  },
  {
    label: "BRAND_MIDDLE",
    value: "BRAND_MIDDLE",
  },
  {
    label: "LIVE_MIDDLE",
    value: "LIVE_MIDDLE",
  },
  {
    label: "HOME_POSITION_ONE",
    value: "HOME_POSITION_ONE",
  },
  {
    label: "SPONSORED PRODUCTS",
    value: "SPONSORED_PRODUCTS",
  },
];

const AddAdvertise = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [shopId, setShopId] = useState<any>();

  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${
          process.env.REACT_APP_CATALOG_READER_API
        }/shop/admin/search?page=0&limit=50&key=${val || ``}` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: { id: any; name: any }) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/product/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any; id: number }) => ({
              label: product.name,
              value: product.id,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [type]
  );

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
  
      setLoading(true);

      const readyData = data && {
        type: type || data?.type,
        title: data.title,
        description: data.description,
        placement: data.placement,
        productId: data.productId,
        brandId: data.brandId,
        campaignId: data.campaignId,
        categoryId: data.categoryId,
        shopId: data.shopId,
        imageUrl: generatedImgUrl || data.imageUrl,
        videoUrl: data.videoUrl,
        url: data.url,
      };

      if (visibleData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/promotion`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: visibleData?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Promo Code Updated Successfully",
                "success"
              );
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/promotion`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Promo Code Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
 
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "shop") getShopsOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "product") getProductOptions(value);
      }
    };

    return debounce(loadOptions, 1200);
  }, [getShopsOptions, getBrandsOptions, getProductOptions]);

  useEffect(() => {
    if (visibleData) {
      form.resetFields();
    }
  }, [form, visibleData]);

  useEffect(() => {
    if (visibleData) {
      setGeneratedImgUrl(visibleData?.imageUrl);
    }
  }, [visibleData]);

  useEffect(() => {
    getShopsOptions();
    getBrandsOptions();
    getProductOptions(``);
  }, [getShopsOptions, getBrandsOptions, getProductOptions]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setStartUpload(undefined);
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...visibleData,
            type: type || visibleData?.type,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <Form.Item
              hasFeedback
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Title is Required!",
                },
              ]}
              name="title"
            >
              <Input id="title" type="text" placeholder="Enter Title" />
            </Form.Item>

            <Form.Item hasFeedback label="Placement" name="placement">
              <Select
                id="placement"
                placeholder="Enter Placement"
                options={placement}
              ></Select>
            </Form.Item>

            <Form.Item hasFeedback label="Target URL" name="url">
              <Input id="url" type="text" placeholder="Enter Target URL" />
            </Form.Item>

            <Form.Item hasFeedback label="Video URL" name="videoUrl">
              <Input id="videoUrl" type="text" placeholder="Enter RL" />
            </Form.Item>

            <Form.Item hasFeedback label="Brand" name="brandId">
              <Select
                showSearch
                placeholder="Select brand"
                optionFilterProp="children"
                onSelect={(val: any) => setShopId(val)}
                onSearch={(val) => getBrandsOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions.list}
              ></Select>
            </Form.Item>

            <Form.Item hasFeedback label="Product" name="productId">
              <Select
                showSearch
                placeholder="Select product"
                optionFilterProp="children"
                onSearch={(val) => getProductOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={productOptions.list}
              ></Select>
            </Form.Item>

            <Form.Item hasFeedback label="Campaign" name="campaignId">
              <Select
                showSearch
                placeholder="Select campaign"
                optionFilterProp="children"
                onSearch={(val) => getBrandsOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions.list}
              ></Select>
            </Form.Item>

            {/* <Form.Item hasFeedback label="Category" name="categoryId">
              <Select
                showSearch
                placeholder="Select categoryId"
                optionFilterProp="children"
                onSearch={(val) => getPaymentOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={paymentsOptions.list}
              ></Select>
            </Form.Item> */}

            <Form.Item hasFeedback label="Shop" name="shopId" className="mr-2">
              <Select
                showSearch
                placeholder="Select Shop"
                optionFilterProp="children"
                onSearch={(val) => getShopsOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={shopsOptions.list}
              ></Select>
            </Form.Item>

            <div className="col-span-2 mb-6">
              <Form.Item hasFeedback label="Description" name="description">
                <Input.TextArea
                  rows={5}
                  id="description"
                  placeholder="Enter Description"
                />
              </Form.Item>

              <Divider orientation="left">
                Image
                <Tooltip
                  placement="bottom"
                  title={
                    "Brand image must be transparent & max height 100px and width auto"
                  }
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={generatedImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              />
            </div>
          </div>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading || startUpload === "Uploading" || !!error}
            loading={loading || startUpload === "Uploading" || !!error}
          />
        </Form>
      </div>
    </div>
  );
};

export default AddAdvertise;
