import MainLayout from "../../components/Layouts";
import BrandDetails from "../../components/Brands/Details";
import React from "react";

const BrandsDetailsPage = () => {
  return (
    <MainLayout>
      <BrandDetails />
    </MainLayout>
  );
};

export default BrandsDetailsPage;
