import React from "react";
import ChapterList from "../../components/Chapter";
import MainLayout from "../../components/Layouts";

const ChapterPage = () => {
  return (
    <MainLayout>
      <ChapterList />
    </MainLayout>
  );
};

export default ChapterPage;
