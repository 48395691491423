import MainLayout from "../../components/Layouts";
import BrandProductList from "../../components/Brands/Product/index";
import React from "react";

const CategoryProductPage = () => {
  return (
    <MainLayout>
      <BrandProductList />
    </MainLayout>
  );
};
export default CategoryProductPage;
