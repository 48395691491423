import React from "react";
import MainLayout from "../../components/Layouts";
import RevenueDistributionConfig from "../../components/Config/RevenueDistributionConfig";

const RevenueDistributionConfigPage = () => {
  return (
    <MainLayout>
      <RevenueDistributionConfig />
    </MainLayout>
  );
};

export default RevenueDistributionConfigPage;
