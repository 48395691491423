import React from "react";
import MainLayout from "../../components/Layouts";
import TopUpDetails from "../../components/Reports/CompanyTopUpDetails";

const MerchantDetailsPage = () => {
  return (
    <MainLayout>
      <TopUpDetails />
    </MainLayout>
  );
};

export default MerchantDetailsPage;
