import {
  ApartmentOutlined,
  CheckCircleFilled,
  CopyOutlined,
  DeleteFilled,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  SoundOutlined,
  UserOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Drawer,
  Radio,
  Alert,
  Space,
} from "antd";
import axios from "axios";
import { TextLoop } from "react-text-loop-next";
import { responseNotification } from "../../utils/notify";
import { getParamValue } from "../../utils";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";

const ProductExtra = ({ barcodes }: { barcodes?: [] }): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const pageType: any = getParamValue(loc.search, "page-type");
  const [key, setKey] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [merchantId, setMerchantId] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [campaignId, setCampaignId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>(undefined);
  const [sortOrder, setSortOrder] = useState<any>(undefined);
  const [shopToShopVisible, setShopToShopVisible] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [productReArrangeDrawer, setProductReArrangeDrawer] =
    useState<any>(undefined);
  const [open, setOpen] = useState(false);
  const [openGiftProduct, setOpenGiftProduct] = useState(false);
  // --------------------------------
  const [activeClone, setActiveClone] = useState(true);
  const [changeCat, setChangeCat] = useState<boolean>(false);
  const [assignSupplier, setAssignSupplier] = useState(false);
  const [assignCampaign, setAssignCampaign] = useState(false);
  const [loading, setLoading] = useState(false);

  const [parentCategoryId, setParentCategoryId] = useState();
  const [selectedShopId, setSelectedShopId] = useState(
    assignSupplier ? shopId : [shopId] || []
  );
  const [targetCategoryId, setTargetCategoryId] = useState<any>();
  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [suppliersOptions, setSuppliersOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });
  const [supplierId, setSupplierId] = useState();
  const [onlyThisShop, setOnlyThisShop] = useState<boolean>();
  const [changeStatus, setChangeStatus] = useState<any>(undefined);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [visible, setVisible] = useState(undefined);
  const { shopInfo } = useSelector((state) => (state as any)?.authReducer);
  const [checkedList, setCheckedList] = React.useState([]);
  // --------------------------------

  const [singleCampaignInfo, setSingleCampaignInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const [barCode, setBarCode] = useState("");

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  const isDeleted = "false";

  useEffect(() => {
    setCampaignId((route as any).campaignId || "");
  }, [route]);

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);

  const getProducts = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    if (typeof campaignId === "string") {
      setProductData({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}` +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (merchantId ? `&merchantId=${merchantId}` : ``) +
        (barCode ? `&barCode=${barCode}` : ``) +
        (sortBy ? `&sortBy=${sortBy}` : ``) +
        (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
        (key ? `&key=${key}` : ``) +
        (from ? `&startDate=${from}` : ``) +
        (to ? `&endDate=${to}` : ``) +
        (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``) +
        (page
          ? `&page=${
              page == productData.data?.currentPageNumber ? 0 : page || 0
            }`
          : ``) +
        (limit ? `&limit=${limit}` : ``);
      url = encodeURI(url);
      axios
        .get(
          `${encodedUrl}/admin/product/${pageType ? pageType : `v2`}/${url}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    }
  }, [
    shopId,
    categoryId,
    merchantId,
    barCode,
    sortBy,
    sortOrder,
    brandId,
    campaignId,
    range,
    isActive,
    key,
    pageType,
    page,
    limit,
  ]);

  const getProductByBarcodeOptions = useCallback(
    async (barCode: any) => {
      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (barCode ? `&barCode=${barCode}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductBarcodeOptions({
            loading: false,
            list: res.data?.keys?.map(
              (product: { name: any; barCode: any }) => ({
                label: product?.name,
                value: product?.barCode,
              })
            ),
          });
        })
        .catch((err) => {
          setProductBarcodeOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [barCode, shopId, categoryId, brandId, campaignId, isActive, page, limit]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``) +
        (shopId ? `&shopId=${shopId}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, campaignId, isActive, key, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopsOptions({ loading: true, list: [] });
      const allShop =
        `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``);
      const merchantShop =
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/${
          merchantId ? "v2" : "by-merchant"
        }` +
        (merchantId ? `?merchantId=${merchantId}` : ``) +
        (type ? `&type=${type}` : ``);
      return axios
        .get(`${merchantId ? merchantShop : allShop}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setShopsOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopsOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type, merchantId]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      })),
    });
  }, []);
  // ************************
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getProductOptions,
    getShopOptions,
    getBrandsOptions,
    getProductByBarcodeOptions,
    getCampaignOptions,
  ]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
      getProductByBarcodeOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  function UnLoadWindow() {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
        campaignId: campaignId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = async function (e: Event) {
      const agree = await confirm("Want to store search data?");
      if (agree) {
        if (showExitPrompt) {
          UnLoadWindow();
          return "Reload will store the search filter parameters";
        }
      }
      e.preventDefault();
      if (showExitPrompt) {
        UnLoadWindow();
        return "Reload will store the search filter parameters";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  useEffect(() => {
    if (route?.containFilter !== undefined && !route?.containFilter) {
      dispatch({
        type: SHOW_SEARCHBAR,
        payload: {
          shopId: "",
          categoryId: "",
          brandId: "",
          campaignId: "",
        },
      });
    }
  }, [route?.containFilter]);

  const onClose = () => {
    setSubmitForm(false);
    setOpen(false);
    getProducts();

    setReArrangeDrawer(undefined);
    setProductReArrangeDrawer(undefined);

    setShopToShopVisible(false);
  };

  const fetchCampaignDetails = useCallback((getCampaignId: any) => {
    if (getCampaignId) {
      try {
        setSingleCampaignInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/campaign/details/${getCampaignId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleCampaignInfo({
                loading: false,
                data: data?.data?.campaign,
              });
            } else {
              setSingleCampaignInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleCampaignInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleCampaignInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchCampaignDetails((route as any)?.campaignId);
  }, [fetchCampaignDetails]);
  const campaign = singleCampaignInfo?.data;

  const onSubmitShopToShop = async (data: any) => {
    setLoading(true);

    if (assignSupplier) {
      const readyData = data && {
        barCodes: barcodes,
        shopId: shopId,
        supplierId: supplierId,
      };

      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/supplier-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Supplier Successfully in Selected Shop(s)",
              "success"
            );
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else if (assignCampaign) {
      const readyData = data && {
        campaignId: campaignId,
        barCodeShopIds: barcodes?.map((item) => ({
          barCode: item,
          shopId: onlyThisShop ? shopId : null,
        })),
      };

      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/add-products`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Product assigned successfully done in campaign",
              "success"
            );
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else if (changeCat) {
      const readyData = data && {
        barCodes: barcodes,
        shopIds: selectedShopId || shopId,
        targetCategoryId: targetCategoryId,
      };

      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/category-update`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Product Changed Category Successfully in Selected Shop(s)",
              "success"
            );
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else if (activeClone) {
      const readyData = data && {
        sourceShopId: route?.shopId,
        barCodes: barcodes,
        shopIds: selectedShopId,
        supplierId: supplierId,
      };
      if (selectedShopId === shopInfo?.id) {
        responseNotification("Can't clone product in ", "success");
      } else {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/product/add-from-product`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(readyData),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Product Cloned Successfully in Selected Shop(s)",
                "success"
              );
              info({
                alreadyExists: res?.alreadyExists,
                newAdded: res?.newAdded,
                list: res?.alreadyExistsProductName,
              });
              onClose();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    }
  };

  function info(alreadyExistsData) {
    Modal.info({
      title: `Successfully added ${alreadyExistsData?.newAdded} item(s) and already exists ${alreadyExistsData?.alreadyExists} item(s)`,
      content: (
        <ul>
          {alreadyExistsData?.list?.map((alreadyExistsProductName, i) => (
            <>
              <small>
                {i + 1}. {alreadyExistsProductName}
              </small>
              <br />
            </>
          ))}
        </ul>
      ),
      width: 600,
      onOk() {},
      okButtonProps: { danger: true },
      okText: "GOT IT",
    });
  }

  useEffect(() => {
    if (openGiftProduct) {
      getShopOptions(``);
    }
  }, [openGiftProduct]);

  //new add
  const getMerchantShopOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/${
          merchantId ? "v2" : "by-merchant"
        }` +
          (merchantId ? `?merchantId=${merchantId}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );

  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/merchant/all?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant) => ({
          label: merchant.name,
          value: merchant.id,
        })),
      });
    },
    [type]
  );

  const getSupplierOptions = useCallback(
    async (val?: string) => {
      setSuppliersOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/supplier/by-shop` +
          (selectedShopId ? `?shopId=${selectedShopId}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuppliersOptions({
        loading: false,
        list: res?.data?.suppliers?.map((supplier) => {
          return {
            value: supplier?.id,
            label: supplier?.name,
          };
        }),
      });
    },
    [type, shopId]
  );

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
    setChangeStatus(undefined);
    setChangeStatus(undefined);
  };

  const getCategoriesOptions = useCallback(
    async (val?: string) => {
      setCategoriesOptions({ loading: true, list: [] });
      if (shopInfo?.hubId) {
        if (parentCategoryId) {
          const res = await axios.get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/child-category-by-parent?categoryId=${parentCategoryId}` +
              (val ? `&key=${val}` : ``) +
              (type ? `&type=${type}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategoriesOptions({
            loading: false,
            list: res?.data?.categories?.map((category) => {
              return {
                value: category?.id,
                label: category?.title,
              };
            }),
          });
        } else {
          const res = await axios.get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/child-category-by-hub?hubId=${shopInfo?.hubId}&page=0&limit=20` +
              (val ? `&key=${val}` : ``) +
              (type ? `&type=${type}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategoriesOptions({
            loading: false,
            list: res?.data?.categories?.map((category) => {
              return {
                value: category?.id,
                label: category?.title,
              };
            }),
          });
        }
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/categories?shopId=${shopId}&page=0&limit=20` +
            (val ? `&key=${val}` : ``) +
            (type ? `&type=${type}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategoriesOptions({
          loading: false,
          list: res?.data?.categories?.map((category) => {
            return {
              value: category?.category?.id,
              label: category?.category?.title,
            };
          }),
        });
      }
    },
    [type, parentCategoryId]
  );

  const onMultipleStatusChange = async () => {
    if (changeStatus === "DELETEANDACTIVE") {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/multiple-inactive-but-active-in-other-shops`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            barCodes: barcodes,
            shopId: shopId,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Item(s) Status Updated Successfully",
              "success"
            );
            getProducts();
            setVisible(undefined);
            setChildrenDrawer(false);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/multiple-active-inactive`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            barCodes: barcodes,
            shopIds: selectedShopId || [shopId],
            isActive: changeStatus === "ACTIVE" ? true : false,
            deleted: changeStatus === "DELETE" ? true : false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Selected Item(s) Status Updated Successfully",
              "success"
            );
            getProducts();
            setVisible(undefined);
            setChildrenDrawer(false);
            form.resetFields();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (type) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, type]);

  useEffect(() => {
    if (merchantId) {
      getMerchantShopOptions();
    }
  }, [merchantId, getMerchantShopOptions]);

  useEffect(() => {
    if (selectedShopId) {
      getSupplierOptions();
    }
  }, [getSupplierOptions, selectedShopId]);

  return (
    <>
      <Radio.Group defaultValue="" className="mb-5">
        <Radio.Button
          value="clone_product"
          onChange={() => {
            setActiveClone(true);
            setChangeCat(false);
            setAssignSupplier(false);
            setAssignCampaign(false);
            setChangeStatus(false);
            form.resetFields();
          }}
        >
          <CopyOutlined /> Clone
        </Radio.Button>
        {/* <Radio.Button
          value="change_category"
          onChange={() => {
            setChangeCat(true);
            setActiveClone(false);
            setAssignSupplier(false);
            setAssignCampaign(false);
            setChangeStatus(false);
            form.resetFields();
          }}
          disabled
        >
          <ApartmentOutlined /> Category
        </Radio.Button>
        <Radio.Button
          value="assign_supplier"
          onChange={() => {
            setAssignSupplier(true);
            setAssignCampaign(false);
            setChangeCat(false);
            setActiveClone(false);
            setChangeStatus(false);
            form.resetFields();
          }}
          disabled
        >
          <UserOutlined /> Supplier
        </Radio.Button>
        <Radio.Button
          value="change_status"
          onChange={() => {
            setChangeStatus(true);
            setChangeCat(false);
            setActiveClone(false);
            setAssignSupplier(false);
            setAssignCampaign(false);
            form.resetFields();
          }}
          disabled
        >
          <QuestionCircleOutlined /> Status
        </Radio.Button>
        <Radio.Button
          value="assign_campaign"
          onChange={() => {
            setChangeStatus(false);
            setChangeCat(false);
            setActiveClone(false);
            setAssignSupplier(false);
            setAssignCampaign(true);
            form.resetFields();
          }}
          disabled
        >
          <SoundOutlined /> Campaign
        </Radio.Button> */}
      </Radio.Group>

      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmitShopToShop}
        form={form} // like ref
        layout="vertical"
        autoComplete="off"
        initialValues={{
          shopId: !activeClone ? [shopId] : undefined,
        }}
      >
        <Form.Item label="Product Barcodes">
          <Input.TextArea value={barcodes} disabled rows={8} />
        </Form.Item>

        {activeClone && (
          <Alert
            message="Where do you want to CLONE
                the selected items?"
            type="warning"
            banner
            closable
            className="mb-4"
          />
        )}
        {changeStatus && (
          <Alert
            message="Where do you want to make the STATUS change of selected Items"
            type="error"
            banner
            closable
            className="mb-4"
          />
        )}
        {changeCat && (
          <Alert
            message="Where do you want to make the CATEGORY changes"
            type="warning"
            banner
            closable
            className="mb-4"
          />
        )}
        {assignSupplier && (
          <Alert
            message="Please Select A Supplier To Change/Assign Supplier For Selected Items"
            type="info"
            banner
            closable
            className="mb-4"
          />
        )}
        {assignCampaign && (
          <Alert
            message="Please Select A Campaign to Assign Selected Items"
            type="info"
            banner
            closable
            className="mb-4"
          />
        )}

        {!assignSupplier && activeClone && (
          <>
            <Form.Item label="Select Merchant" initialValue={merchantId}>
              <Select
                allowClear
                showSearch
                placeholder="Select Merchant"
                optionFilterProp="children"
                onChange={(val) => setMerchantId(val)}
                onSearch={(e) => handleSearch(e, "merchant")}
                filterOption={() => {
                  return true;
                }}
                options={merchantOptions.list}
              ></Select>
            </Form.Item>

            <Form.Item
              label="Select Shop"
              name="shopId"
              rules={[
                {
                  required: true,
                  message: "Shop is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                // mode={changeStatus || changeCat ? "tags" : undefined}
                onChange={(val) => {
                  setSelectedShopId(val);
                }}
                onSearch={(e) => handleSearch(e, "shop")}
                options={shopsOptions?.list}
                // maxTagTextLength={20}
                disabled={
                  shopsOptions?.list?.find((shop) => shop?.id) === shopInfo?.id
                    ? true
                    : false
                }
                placeholder="Select Shop"
                filterOption={() => {
                  return true;
                }}
                mode="multiple"
              ></Select>
            </Form.Item>

            {/* {suppliersOptions?.list?.length ? (
              <Form.Item label="Select Supplier" name="supplierId">
                <Select
                  allowClear
                  autoClearSearchValue
                  onSearch={(e) => handleSearch(e, "supplier")}
                  onChange={(val) => setSupplierId(val)}
                  options={suppliersOptions?.list}
                  maxTagTextLength={20}
                  // disabled={!shopId || !selectedShopId}
                  placeholder="Select Supplier if Needed"
                ></Select>
              </Form.Item>
            ) : (
              <Alert
                message={
                  <TextLoop springConfig={{ stiffness: 220, damping: 5 }}>
                    <span>No Supplier Found</span>
                    <span>In this selected Shop!</span>
                  </TextLoop>
                }
                type="error"
                className="mb-4"
              />
            )} */}
          </>
        )}

        {assignSupplier && (
          <Form.Item label="Select Supplier" name="supplierId">
            <Select
              allowClear
              // showSearch
              autoClearSearchValue
              onSearch={(e) => handleSearch(e, "supplier")}
              onChange={(val) => setSupplierId(val)}
              options={suppliersOptions?.list}
              maxTagTextLength={20}
              placeholder="Select Supplier if Needed"
            ></Select>
          </Form.Item>
        )}

        {assignCampaign && (
          <>
            <div className="my-3">
              <h4>Where you Want to Update?</h4>
              <Switch
                checkedChildren="This Shop"
                unCheckedChildren="Any Shop"
                className="ml-2"
                onChange={(val) => setOnlyThisShop(val)}
              />
            </div>
            <Form.Item label="Select Campaign" name="campaignId">
              <Select
                allowClear
                // showSearch
                autoClearSearchValue
                onSearch={(e) => handleSearch(e, "campaign")}
                onChange={(val) => setCampaignId(val)}
                options={campaignOptions?.list}
                maxTagTextLength={20}
                placeholder="Select Campaign"
              ></Select>
            </Form.Item>
          </>
        )}

        {changeCat && (
          <>
            <Form.Item
              style={{ minWidth: "100%" }}
              label="Select Category"
              name="targetCategoryId"
            >
              <Select
                allowClear
                showSearch
                autoClearSearchValue
                onSearch={() => {
                  getCategoriesOptions();
                }}
                onChange={(val) => {
                  setTargetCategoryId(val);
                }}
                options={categoriesOptions?.list}
                maxTagTextLength={20}
                placeholder="Select Category"
              ></Select>
            </Form.Item>
          </>
        )}

        {changeStatus ? (
          <>
            <Button
              type="dashed"
              danger
              size="large"
              onClick={() => setChildrenDrawer(true)}
            >
              <FontAwesomeIcon icon={faToggleOn} className="mr-2" />
              Change Status
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            danger
            title="Clone Selected Items"
            htmlType="submit"
            size="large"
            disabled={
              (!changeCat &&
                !activeClone &&
                !assignSupplier &&
                !assignCampaign) ||
              loading
            }
            loading={(loading ? "loading" : undefined) as any}
          >
            Submit
          </Button>
        )}
      </Form>

      <Drawer
        title="Change Status"
        width={365}
        closable={false}
        onClose={onChildrenDrawerClose}
        open={childrenDrawer}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio
              value="1"
              onChange={() => setChangeStatus("ACTIVE")}
              disabled={shopInfo?.deleted}
            >
              <CheckCircleFilled className="text-success" /> ACTIVE SELECTED
              ITEMS
            </Radio>
            <Radio value="2" onChange={() => setChangeStatus("INACTIVE")}>
              <WarningFilled className="text-warning" /> INACTIVE SELECTED ITEMS
            </Radio>
            <Radio
              value="3"
              onChange={() => setChangeStatus("DELETEANDACTIVE")}
              disabled={shopInfo?.deleted}
            >
              <IssuesCloseOutlined className="text-danger mr-1" />
              INACTIVE &amp; ACTIVE IN ANOTHER SHOP
            </Radio>
            <Radio value="4" onChange={() => setChangeStatus("DELETE")}>
              <DeleteFilled className="text-danger" /> DELETE SELECTED ITEMS
            </Radio>
          </Space>
        </Radio.Group>
        <Button
          type="primary"
          danger
          title="Clone Selected Items"
          disabled={!checkedList || !changeStatus}
          htmlType="submit"
          size="large"
          className="mt-4"
          onClick={() => onMultipleStatusChange()}
        >
          Change Status
        </Button>
      </Drawer>
    </>
  );
};

export default ProductExtra;
