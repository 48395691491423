import React from "react";
import CourseSyllabusList from "../../components/Course-Syllabus";
import MainLayout from "../../components/Layouts";

const CourseSyllabusPage = () => {
  return (
    <MainLayout>
      <CourseSyllabusList />
    </MainLayout>
  );
};

export default CourseSyllabusPage;
