import { Alert, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";

const AddCountry = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [countryData, setCountryData] = useState<any>(undefined);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl) {
      setLoading(true);
      const readyData = data && {
        name: data.name,
        capital: data.capital,
        currency_name: data.currency_name,
        currency_symbol: generatedImgUrl ? generatedImgUrl : data?.currency_symbol,
      };

      if (countryData) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-country`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: countryData?.id,
            deleted: false,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Country Updated Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/add-country`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Country Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Image required`, "error");
    }
  };



  useEffect(() => {
    if (visibleData) {
      setCountryData(visibleData);
      setGeneratedImgUrl(visibleData?.currency_symbol);
    }
  }, [visibleData]);

  useEffect(() => {
    if (countryData) {
      form.resetFields(Object.keys(visibleData));
      setGeneratedImgUrl(visibleData?.currency_symbol);
    }
  }, [countryData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
       
      }
    };

    return debounce(loadOptions, 800);
  }, []);


  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...countryData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Country Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Country Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Capital"
          rules={[
            {
              required: false,
              message: "Capital is Required!",
            },
          ]}
          name="capital"
        >
          <Input id="capital" type="text" placeholder="Enter Country Capital" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Currency Name"
          rules={[
            {
              required: false,
              message: "Currency name is Required!",
            },
          ]}
          name="currency_name"
        >
          <Input id="currency_name" type="text" placeholder="Enter Country Name" />
        </Form.Item>


        <Divider orientation="left">Currency Symbol</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={cropImage ? 2.5 : 0}
          />
          <div className=" pr-8">
            <Switch
              onChange={() => setCropImage(!cropImage)}
              defaultChecked={cropImage}
              checkedChildren={<FontAwesomeIcon icon={faCropAlt} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            />
          </div>
        </div>

        {cropImage && (
          <Alert
            message="Currency Symbol ratio must be 2.5 (1600/640 PX)"
            type="info"
            showIcon
            className="mb-4 rounded-md shadow-sm"
            banner
          />
        )}
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setCountryUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddCountry;
