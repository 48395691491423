import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import * as React from "react";
import Logo from "../../../images/logo.png";

const styles = StyleSheet.create({
  table: {
    width: "100%",
    border: "1px solid #dee2e6",
    borderRadius: "5px",
  },
  thead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  th: {
    fontSize: "10px",
    fontWeight: "bold",
    padding: "8px",
  },
  td: {
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
  },
  total: {
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "right",
    width: "70%",
  },
  totalAmount: {
    fontSize: "10px",
    fontWeight: "normal",
    padding: "8px",
    textAlign: "right",
    width: "30%",
  },
  borderBottom: {
    borderBottom: "1px solid #dee2e6",
  },
  bold: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  normal: {
    fontSize: "12px",
    fontWeight: 100,
  },
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    flexGrow: 1,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    width: "45%",
  },
  logo: {
    flexDirection: "row",
    // height: 40,
    width: 125,
  },
  headerRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  shopName: {
    fontSize: 12,
    color: "#ff1f1f",
    marginBottom: 8,
    fontWeight: 400,

    textTransform: "capitalize",
  },
  address: {
    fontSize: 10,
    color: "#666",
    fontWeight: 400,
    marginBottom: "4px",
  },
  name: {
    fontSize: 10,
    marginBottom: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  dateText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
    color: "#535353",
  },
  titleArea: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    marginBottom: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },
  middleSection: {
    width: "100%",
    height: "80%",
  },
  acountAreaPara: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    padding: "0px 5px",
  },
  //footer style

  footerArea: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    borderTop: "1px solid #d5d5d5",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  textRed: {
    color: "#cd113b",
  },
  //new end
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  // So Declarative and unDRY 👌

  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "space-between",
  },
  header: {
    borderTop: "none",
  },
  // table design
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 2,
  },
  hr: {
    backgroundColor: "#d5d5d5",
    width: "100%",
    height: "1px",
    margin: "16px 0",
  },
});
const InvoiceHistory = ({
  invoiceData,
  corporateInfo,
}: {
  invoiceData?: any;
  corporateInfo?: any;
}) => {
  // console.log(data.data.orderIds);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Image src={Logo} style={styles.logo} />
            </View>
            <View style={styles.headerRight}>
              <View>
                <Text style={styles.shopName}>
                  INVOICE NO :{corporateInfo?.ownerId?.replace("D-I-", "")}
                </Text>
              </View>
              <View>
                <Text style={styles.dateText}>
                  Invoice Date: {moment(new Date()).format("MMMM D, YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.hr}></View>
          <View style={styles.headerArea}>
            <View>
              <View>
                <Text style={styles.shopName}>Invoice To</Text>
              </View>
              <View>
                <Text style={styles.name}>{corporateInfo?.name}</Text>
              </View>
              <View>
                <Text style={styles.address}>{corporateInfo?.address}</Text>
              </View>
              <View>
                <Text style={styles.address}>
                  {corporateInfo?.spokesmanMobileNumber?.replace("+88", "")}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  {corporateInfo?.spokesmanEmail}
                </Text>
              </View>
            </View>
            <View style={styles.headerRight}>
              <View>
                <Text style={styles.shopName}>Invoice From</Text>
              </View>
              <View>
                <Text style={styles.name}>Jachai Limited</Text>
              </View>
              <View>
                <Text style={styles.address}>House# 26, Road# 7, Block# C</Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Niketon, Gulshan -1, Dhaka-1212.
                </Text>
              </View>
            </View>
          </View>
          {/* <View style={styles.hr}></View> */}
          {/* table */}
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.th}>SL</Text>
              <Text style={styles.th}>Date</Text>
              <Text style={styles.th}>Name</Text>
              <Text style={styles.th}>Designation</Text>
              <Text style={styles.th}>Mobile Number</Text>
              <Text style={styles.th}>Status</Text>
              <Text style={styles.th}>Amount</Text>
            </View>
            {invoiceData?.map((data, index) => (
              <View key={index} style={styles.row}>
                <Text style={styles.td}>{index + 1}</Text>
                <Text style={styles.td}>
                  {moment(data?.createdAt).format("lll")}
                </Text>
                <Text style={styles.td}>{data?.name}</Text>
                <Text style={styles.td}>{data?.designation}</Text>
                <Text style={styles.td}>
                  {data?.mobileNumber?.split("+88")}
                </Text>
                <Text style={styles.td}>
                  {data?.failedReason ? data?.failedReason : data?.status}
                </Text>
                <Text style={styles.td}>{parseInt(data?.amount)}</Text>
              </View>
            ))}

            <View style={styles.row}>
              <Text style={styles.total}>Total</Text>
              <Text style={styles.totalAmount}>
                {" "}
                {invoiceData?.reduce(
                  (total, currentValue) =>
                    (total = total + currentValue.amount),
                  0
                )}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default InvoiceHistory;
