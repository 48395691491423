import { Rate } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CustomerLayout from "../Layout";
import { SET_USER_INFO } from "../../../redux/auth/authType";

const UserDetails = (props: any): ReactElement => {  
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [singleUserInfo, setSingleUserInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const route = useParams();
  const dispatch = useDispatch();

  const fetchUserDetails = useCallback((getUserId: any) => {
    if (getUserId) {
      try {
        setSingleUserInfo({ loading: true, data: null });
        axios
          .get(`${process.env.REACT_APP_RIDER_API}/admin/user/${getUserId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((data) => {
            if (data.status === 200) {
              setSingleUserInfo({
                loading: false,
                data: data?.data?.user,
              });
              dispatch({
                type: SET_USER_INFO,
                payload: {
                  customerInfo: {
                    id: data?.data?.user?.id,
                    fullName: data?.data?.user?.fullName,
                    profilePicture: data?.data?.user?.profilePicture,
                    mobileNumber: data?.data?.user?.mobileNumber,
                    balance: data?.data?.user?.balance,
                    rating: data?.data?.user?.rating,
                    numberOfRating: data?.data?.user?.numberOfRating,
                    status: data?.data?.user?.status,
                  },
                },
              });
            } else {
              setSingleUserInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleUserInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleUserInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchUserDetails((route as any)?.userId);
  }, [fetchUserDetails]);

  const user = singleUserInfo?.data;
  return (
    <React.Fragment>
      <CustomerLayout userId={(route as any)?.userId as string}>
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            <h3 className="mb-4 text-xl font-bold">General information</h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Customer Name
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {user?.fullName}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Mobile Number
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {user?.mobileNumber?.split("+88")}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Current Balance
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  &#2547; {user?.balance || 0}
                </dd>
              </div>

              {user?.city && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">City</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.city}
                  </dd>
                </div>
              )}

              {user?.zone && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Zone</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.zone}
                  </dd>
                </div>
              )}

              {user?.type && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Type</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.type}
                  </dd>
                </div>
              )}

              {user?.vehicleType && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Vehicle Type
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.vehicleType}
                  </dd>
                </div>
              )}

              {!!user?.personalInfoStatus && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Personal Info Status
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.personalInfoStatus}
                  </dd>
                </div>
              )}

              {!!user?.status && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {user?.status?.split("_").join(" ")}
                  </dd>
                </div>
              )}

              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Joining Date
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {moment(user?.createdAt).format("lll")}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-900">Rattings</dt>
                <dd className="text-sm font-semibold text-gray-500">
                  <Rate value={user?.rating || 1} disabled />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                    {user?.numberOfRating || 0}
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </CustomerLayout>
    </React.Fragment>
  );
};
UserDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default UserDetails;
