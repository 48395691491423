import React, { useCallback, useEffect, useState } from "react";
import CorporateLayout from "../Layout";
import { Button, Empty, Pagination } from "antd";
import axios from "axios";
import { useParams, useLocation } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPage, getParamValue } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TopupHistoryInvoice from "../Invoice/InvoiceHistory";
import { FileDoneOutlined } from "@ant-design/icons";
import { Loader } from "../../common";

const TopUpDetailsList = ({ params }: any) => {
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [selectedData, setSelectedData] = useState(null);
  const [showSearch, setShowSearch] = useState(true);

  const [topUpData, setTopUpData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTopupHistory = useCallback(async () => {
    setTopUpData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup/company-history-details?companyTopUpId=${
          (route as any).corporateId
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTopUpData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTopUpData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [route]);

  useEffect(() => {
    getTopupHistory();
  }, [getTopupHistory]);

  const onClose = () => {
    setSelectedData(null);
  };

  return (
    <React.Fragment>
      <CorporateLayout corporateId={corporateInfo?.id}>
        <>
          <div className={styles?.searchBox}>
            <PDFDownloadLink
              document={
                <TopupHistoryInvoice
                  invoiceData={topUpData?.data?.topUps}
                  corporateInfo={corporateInfo}
                />
              }
              fileName={`Invoice`}
            >
              <Button
                shape="circle"
                className="bg-transparent border-dashed"
                // onClick={() =>
                //   navigate(`/merchants/${data?.id}/invoice`)
                // }
              >
                <FileDoneOutlined />
              </Button>
            </PDFDownloadLink>
          </div>

          <div className={styles.contentWrapper}>
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div
                  className={
                    showSearch ? `content-body-withSearch` : `content-body`
                  }
                >
                  {topUpData?.loading ? (
                    <Loader />
                  ) : (
                    <table className={styles.mainTable}>
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col">SL</th>
                          <th scope="col">Date</th>
                          <th scope="col">Name</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Operator</th>
                          <th scope="col">Type</th>
                          <th scope="col">TopUpId</th>
                          <th scope="col">Gateway</th>
                          <th scope="col">Status</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {topUpData?.data?.topUps?.length ? (
                          topUpData?.data?.topUps?.map(
                            (topUp: any, index: any) => (
                              <tr
                                className="border-t hover:bg-gray-100 cursor-pointer"
                                key={index}
                              >
                                <td>{index + 1}</td>
                                <td>
                                  {" "}
                                  <span className="name">
                                    {moment(topUp?.createdAt).format("lll")}
                                  </span>
                                </td>
                                <td>{topUp?.name}</td>
                                <td>{topUp?.designation}</td>
                                <td>{topUp?.mobileNumber?.split("+88")}</td>
                                <td>{topUp?.operator}</td>
                                <td>{topUp?.type}</td>
                                <td>{topUp?.topUpId}</td>
                                <td>{topUp?.gatewayName}</td>
                                <td
                                  style={
                                    topUp?.status == "Success" &&
                                    topUp?.rechargeStatus == "900"
                                      ? { color: "green" }
                                      : { color: "red" }
                                  }
                                >
                                  {topUp?.status}
                                </td>
                                <td>&#2547;{parseInt(topUp?.amount)}</td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td>
                              <Empty />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            <Pagination
              {...topUpData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
        </>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default TopUpDetailsList;
