import {
  ApartmentOutlined,
  BarsOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddCategory from "./AddCategory";
import React from "react";
import { debounce } from "lodash";
import SingleCategory from "./Details/SingleCategory";
import moment from "moment";
import { isAllowedService } from "../../utils/services";

const SecondLevelCategoryList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [isDeleted, setIsDeleted] = useState("");
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [changeParent, setChangeParent] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] =
    useState<any>(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };
  const [categoryData, setCategoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const [categoryOptions, setCategoryOptions] = useState({
    loading: false,
    list: [],
  });

  const getCategories = useCallback(async () => {
    setCategoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/category/child-by-type?type=${type}&level=1` +
          (key ? `&key=${key}` : ``) +
          (isDeleted ? `&isDeleted=${isDeleted}` : ``) +
          (page ? `&page=${page}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCategoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCategoryData({ loading: false, data: [] });
        console.error("Category: Error", err);
      });
  }, [key,type, isDeleted, page, limit]);

  const getCategoriesOptions = useCallback(
    async (key?: string) => {
      setCategoryOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/admin/category/parent-by-type?page=0&limit=20&isParent=false` +
          (key ? `&key=${key}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCategoryOptions({
        loading: false,
        list: res.data?.categories?.map((category: any) => ({
          label: category.title,
          value: category.title,
        })),
      });
    },
    [type]
  );
  const onSubCatRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/category?categoryId=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Category Remove Successfully", "success");
            getCategories();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //
  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/category`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: !val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getCategories();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  categoryData?.data?.categories?.length;

  const fetchRef = useRef(0);

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "category") getCategoriesOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCategoriesOptions]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onClose = () => {
    getCategories();
    setVisible(undefined);
    setSelectedCategory(undefined);
    setSelectedCategoryForEdit(undefined);
  };

  const reseAllFieldData = () => {
    setIsDeleted("");
    setKey("");
    setType("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title="Sub Category List"
        subTitle={`${
          categoryData?.data?.totalElements ||
          categoryData?.data?.categories?.length ||
          0
        } Category(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          // <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "category")}
                onSelect={(val: any) => setKey(val?.toString())}
                options={categoryOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  categoryOptions?.loading ? <Spin size="small" /> : null
                }
                allowClear
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={categoryOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="deleted_search"
              initialValue={
                isDeleted == "true"
                  ? "ACTIVE"
                  : isDeleted == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                allowClear
                placeholder="isDeleted"
                onChange={(val) => setIsDeleted(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"false"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"true"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {categoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Immediate Parent</th>
                      <th scope="col">Total Product</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {categoryData?.data?.categories?.length ? (
                      categoryData?.data?.categories?.map(
                        (category: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(`/categories/${category?.id}/products`)
                            }
                          >
                            <td>
                              <Avatar size={40} src={category?.image} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {category?.title}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {category?.immediateParentTitle}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {category?.numberOfProducts}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!category?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(category?.id, val);
                                }}
                              />
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    category?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(category?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(category?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {category?.updatedBy?.name ||
                                  category?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedCategoryForEdit(category);
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                                {isAllowedService(`Categories Delete`) && (
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={category?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      console.log(true);
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onSubCatRemove(category?.id);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(category?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...categoryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Category"
            : selectedCategoryForEdit
            ? "Edit Category"
            : "Category Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedCategory || selectedCategoryForEdit}
        width={500}
        extra={[
          selectedCategoryForEdit && (
            <Switch
              checkedChildren={<ApartmentOutlined />}
              unCheckedChildren={<BarsOutlined />}
              onChange={(val, e) => {
                e.preventDefault();
                e.stopPropagation();
                setChangeParent(val);
              }}
            />
          ),
        ]}
      >
        {visible || selectedCategoryForEdit ? (
          <AddCategory
            onCloseMethod={onClose}
            visibleData={selectedCategoryForEdit?.id}
            parentCategoryId={selectedCategoryForEdit?.parent}
            changeParent={changeParent}
          />
        ) : (
          <SingleCategory selectedCategoryId={selectedCategory} />
        )}
      </Drawer>
    </>
  );
};

export default SecondLevelCategoryList;
