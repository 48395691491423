import { Avatar, Divider, Modal, Tag } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";

const SingleUser = ({
  UserDetails,
  onCloseMethod,
}: {
  UserDetails: any;
  onCloseMethod: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [adminData, setAdminData] = useState<any>();
  const [adminGroup, setAdminGroup] = useState([]);
  const [groupIds, setGroupIds] = useState([]);

  // ******************************
  const fetchCorporateDetails = async (visibleData: any) => {
    if (visibleData) {
      await fetch(
        `${process.env.REACT_APP_AUTH_API}/admin/details?email=${visibleData}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setAdminData(res?.admin);
            setAdminGroup(res?.groups);
            console.log("res", res);

            // setGeneratedImgUrl(res?.company?.image);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onDelete = async () => {
    const readyData = {
      email: adminData?.email,
      groupIds: [groupIds],
    };

    await fetch(
      `${process.env.REACT_APP_AUTH_API}/admin/remove-service-group`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Role Removed Successfully", "success");
          onCloseMethod();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (UserDetails) {
      fetchCorporateDetails(UserDetails);
    }
  }, [UserDetails]);

  const onRemoveServiceGroup = () => {
    Modal.confirm({
      title: "Do you confirm?",
      icon: <ExclamationCircleOutlined />,
      content: `Will you Confirm to Remove This Group`,
      okText: "Remove",
      cancelText: "Cancel",
      onOk: onDelete,
    });
  };

  useEffect(() => {
    if (groupIds.length) {
      onRemoveServiceGroup();
    }
  }, [groupIds]);

  return (
    <>
      <div className="">
        <Avatar
          src={adminData?.profilePicture}
          size={200}
          className="border-8 border-gray-100 shadow-lg m-auto block"
        />
        <div className="w-100 text-center shadow-md border-gray-100 border-4 rounded-full p-2 bg-red-500 mt-8">
          <h2 className="text-white font-bold uppercase text-xl">
            {adminData?.fullName}
          </h2>
          <h4 className="text-gray-100">{adminData?.email}</h4>
        </div>

        {adminGroup?.length ? (
          <div className="mt-5 text-center">
            <Divider>User Roles</Divider>
            {adminGroup?.map((grp: any, index: any) => (
              <Tag
                color="#f50"
                key={index}
                className="bg-gradient-to-br from-green-500 to-green-700 text-xs font-bold uppercase text-white px-3 py-1.5 rounded-md mb-2 mr-2"
                closable
                onClose={() => setGroupIds(grp?.group?.id || [])}
              >
                {grp?.group?.name}
              </Tag>
            ))}
          </div>
        ) : undefined}
        {/* <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Driver Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{UserDetails?.fullName}</span>
            </div>
          </div>
        </div>
        */}
      </div>
    </>
  );
};

export default SingleUser;
