import { Image, Col, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ReactElement, useEffect, useState } from "react";
//import "react-responsive-carousel/lib/styles/carousel.min.css";

const SingleHistory = ({
  HistoryDetails,
}: {
  HistoryDetails: any;
}): ReactElement => {
  const [getHistoryDetails, setHistoryDetails] = useState(undefined);

  useEffect(() => {
    setHistoryDetails(HistoryDetails);
  }, [HistoryDetails]);

  return (
    <React.Fragment>
      <Row gutter={20}>
        {getHistoryDetails?.imageUrl && (
          <Col span={24}>
            <Image src={getHistoryDetails?.imageUrl} className="rounded" />
          </Col>
        )}
        <Col span={24}>
          <div className="inner-body-wrapper mt-4">
            <div className="inner-body">
              <div className="single">
                <div className="info-name">
                  <span>Amount</span>
                </div>
                <div className="info-desc">
                  <span>&#2547;{getHistoryDetails?.amount}</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Created At</span>
                </div>
                <div className="info-desc">
                  <span>
                    {moment(getHistoryDetails?.createdAt)?.format("lll")}
                  </span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Creadet By</span>
                </div>
                <div className="info-desc">
                  <span>{getHistoryDetails?.createdBy?.name}</span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Contact</span>
                </div>
                <div className="info-desc">
                  <span>
                    {getHistoryDetails?.createdBy?.mobileNumber?.replace(
                      "+88",
                      ""
                    )}
                  </span>
                </div>
              </div>

              <div className="single">
                <div className="info-name">
                  <span>Description</span>
                </div>
                <div className="info-desc">
                  <span>{getHistoryDetails?.description}</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
SingleHistory.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default SingleHistory;
