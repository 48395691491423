import {
  EditOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
} from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { responseNotification } from "../../../utils/notify";
import { getPage, getParamValue, jcTypeArray, MerchantProductStatus } from "../../../utils";
import { useLocation, useParams } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../../redux/search/searchType";
import QuickUpdate from "./QuickUpdate";
import moment from "moment";
import SingleRequestedProduct from "./Details";
import React from "react";
import { Link } from "react-router-dom";
import {
  Loader,
  LoaderFull,
  PaginationThree,
  TreeSelectTwo,
} from "../../common";
const { confirm } = Modal;

const MerchantRequestedProductList = ({
  inShop = false,
  inMerchant = false,
  getShopId,
  getMerchantId,
}: {
  inShop?: boolean;
  inMerchant?: boolean;
  getShopId?: string;
  getMerchantId?: string;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [form] = Form.useForm();
  const route = useParams();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");
  const [shopId, setShopId] = useState<any>(getShopId);
  const [merchantId, setMerchantId] = useState<any>(getMerchantId);
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);
  const [showSearch, setShowSearch] = useState(true);

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);

  const getProducts = useCallback(async () => {
    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/merchant/product/resell/all-requests?type=${type}` +
          (shopId ? `&shopId=${shopId}` : ``) +
          (categoryId ? `&categoryId=${categoryId}` : ``) +
          (brandId ? `&brandId=${brandId}` : ``) +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (key ? `&name=${key}` : ``) +
          (page
            ? `&page=${
                page == productData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [shopId, categoryId, merchantId, brandId,type, key, page, limit]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/merchant/product?type=${type}&isDeleted=false&page=0&limit=20` +
            (key ? `&name=${key}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (categoryId ? `&categoryId=${categoryId}` : ``) +
            (brandId ? `&brandId=${brandId}` : ``) +
            (page ? `&page=${page}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [shopId, categoryId, brandId, key,type, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, [type]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions]);

  const onStatusChange = async (status: any) => {
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/admin/merchant/product/resell/update`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productWithProfitMargin:[{
            productId: selectedProductForEdit?.productId,
          }],
          status: status,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          getProducts();
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setShopId("");
    setCategoryId("");
    setBrandId("");
    setType("");
  };

  function UnLoadWindow() {
    console.log("Un Load Window");
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        UnLoadWindow();
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  const onClose = () => {
    getProducts();
    // setChildrenDrawer(false);
    setSelectedProductForEdit(undefined);
  };

  return (
    <>
      {!inMerchant && (
        <BreadCrumb
          title="Requested Product List"
          subTitle={`${productData?.data?.totalElements || 0} Product(s)`}
          childComponent={inShop ? true : false}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
          ]}
        />
      )}

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>          
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            {!inShop && (
              <Form.Item name="shop_search">
                <Select
                  allowClear
                  showSearch
                  placeholder="Filter by Shop"
                  optionFilterProp="children"
                  onChange={(val) => setShopId(val)}
                  onSearch={(e) => handleSearch(e, "shop")}
                  filterOption={() => {
                    return true;
                  }}
                  options={shopOptions?.list}
                ></Select>
              </Form.Item>
            )}

            <Form.Item name="category_search">
              <TreeSelectTwo setCategoryId={setCategoryId} value={categoryId} />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Requested By</th>
                      <th scope="col">Requested At</th>
                      {/* <th scope="col">Status</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.merchantResellRequests?.length ? (
                      productData?.data?.merchantResellRequests?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // navigate(
                                //   `${
                                //     inShop ? `/shops/${getShopId}` : ``
                                //   }/products/${product?.slug}/details`
                                // );
                              }}
                            >
                              <Avatar
                                size={45}
                                src={product?.productImage}
                                shape="square"
                              />
                              <span className="font-medium text-gray-500 ml-2">
                                {product?.productName?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              <Avatar
                                size={35}
                                src={product?.shop?.logo}
                                shape="circle"
                              />
                              <span className="ml-2">
                                {product?.shop?.name?.slice(0, 50)}
                              </span>
                            </td>

                            <td>{moment(product?.createdAt).format("lll")}</td>

                            <td>
                              <div className="flex flex-grow gap-2">
                                <Button
                                  shape="round"
                                  type="primary"
                                  danger
                                  className="ml-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedProductForEdit(product);
                                  }}
                                >
                                  <FileDoneOutlined />
                                </Button>
                                <Link
                                  title="Edit Product"
                                  to={`/merchants/requested-product/${product?.slug}`}
                                  className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                >
                                  <EditOutlined />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Review Product"
        placement="right"
        onClose={onClose}
        open={selectedProductForEdit}
        width={600}
        extra={
          <div className="flex flex-1">
            <Dropdown.Button
              className="bg-white"
              overlay={
                <Menu>
                  {Object?.values(MerchantProductStatus)?.map((status) => (
                    <Menu.Item
                      key={status}
                      onClick={(val) => {
                        showConfirm(val?.key);
                      }}
                    >
                      {status?.split("_").join(" ") || "Status"}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              {selectedProductForEdit?.status?.split("_").join(" ") || "Status"}
            </Dropdown.Button>{" "}
            {/* <Button className="ml-2" onClick={showChildrenDrawer}>
              <EditOutlined />
            </Button> */}
          </div>
        }
      >
        <SingleRequestedProduct ProductDetails={selectedProductForEdit} />

        <Drawer
          title="Edit Product"
          width={420}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <QuickUpdate
            onCloseMethod={() => setChildrenDrawer(false)}
            visibleData={selectedProductForEdit}
          />
        </Drawer>
      </Drawer>
    </>
  );
};

export default MerchantRequestedProductList;
