import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios";

const AddProgram = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();

  const [videoUrl, setVideoUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [programData, setProgramData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");
  const [shopId, setShopId] = useState<any>("");

  console.log("shopId ::", shopId);
  const [convertedTextForTnC, setConvertedTextForTnC] = useState("");
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      colorCode: data.colorCode,
      shopId: shopId || data.shopId,
      type: data.type,
      withCourseDisplay: data.withCourseDisplay,
      uploadDetails: {
        bannerImageUrl: bannerImgUrl
          ? bannerImgUrl
          : data?.uploadDetails?.bannerImageUrl || null,
        imageUrl: generatedImgUrl
          ? generatedImgUrl
          : data?.uploadDetails?.imageUrl || null,
        videoUrl: videoUrl ? videoUrl : data?.uploadDetails?.videoUrl || null,
        title: data.name,
      },
    };

    if (programData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/program`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: programData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Program Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/program`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Program Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setProgramData(visibleData);
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
    }
  }, [visibleData]);

  useEffect(() => {
    if (programData) {
      form.resetFields(Object.keys(visibleData));
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
      setVideoUrl(visibleData?.uploadDetails?.videoUrl);
    }
  }, [programData, form, visibleData]);

  const getShopsOptions = useCallback(
    async (shopId?: any) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop?type=JC_COMMERCE&page=0&limit=20` +
          (shopId ? `&key=${shopId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: any) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [shopId]
  );
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "shop") getShopsOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopsOptions, type]);
  const resetData = () => {
    form?.resetFields();
    setVideoUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...programData,
          videoUrl: videoUrl || programData?.uploadDetails?.videoUrl,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>
        <div>
          <Form.Item hasFeedback label="Program Type" name="type">
            <Select
              placeholder="Select Type"
              options={[
                {
                  value: "COURSE",
                  label: "COURSE",
                },
                {
                  value: "DISCIPLINE",
                  label: "DISCIPLINE",
                },
                {
                  value: "CURRICULUM",
                  label: "CURRICULUM",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div className="">
          <Form.Item
            hasFeedback
            label="Organization Name"
            rules={[
              {
                required: true,
                message: "Organization name is required!",
              },
            ]}
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback
          label="Video Url"
          rules={[
            {
              required: false,
              message: "Video Url is Required!",
            },
          ]}
          name="videoUrl"
        >
          <Input
            id="videoUrl"
            type="text"
            placeholder="Enter videoUrl Url"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Color"
          rules={[
            {
              required: true,
              message: "Color is Required!",
            },
          ]}
          name="colorCode"
        >
          <Input id="color" type="color" placeholder="Enter color code" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="With Course Display?"
          name="withCourseDisplay"
          className="mr-5"
        >
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            defaultChecked={(programData as any)?.data?.withCourseDisplay}
          />
        </Form.Item>

        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Image
              <Tooltip
                placement="bottom"
                title={
                  "Program image must be transparent & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">Banner Image</Divider>
            <ImageUploader
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>

        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
            setStartUpload2("Uploading");
          }}
          onClickReset={resetData}
          disabled={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
          loading={
            loading ||
            startUpload === "Uploading" ||
            startUpload2 === "Uploading" ||
            !!error
          }
        />
      </Form>
    </>
  );
};

export default AddProgram;
