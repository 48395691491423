import { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import axios from "axios";
import { Form, DatePicker, Button } from "antd";
import { useParams } from "react-router";
import moment from "moment";
import DriverLayout from "./Layout";
import { useSelector } from "react-redux";
import DriverMarker from "../../../images/icon/DriverMarker.svg";
import styles from "../../../styles/tailwind/List.module.css";
import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const containerStyle = {
  width: "100%",
  height: "500px",
};

const DriverLocationHistoryMap = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);

  const [driverLocation, setDriverLocation] = useState<any>([]);
  const [form] = Form.useForm();
  const [path, setPath] = useState([]);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [getLocationHistory, setGetLocationHistory] = useState<any>({
    loading: false,
    data: null,
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const shopActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const route = useParams();
  // new add

  const getDriverLocationHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();

    setGetLocationHistory({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/location/history?driverId=${route?.id}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            //Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2MTk2NDRjNTBmYTYyNDdmN2QxZjA2ZmQiLCJpc3MiOiJBVVRIIiwibW9iaWxlTnVtYmVyIjoiKzg4MDE3MjM0NDM3NTEiLCJuYW1lIjoiTWQgQWJkdWwgR2FmdXIiLCJ0eXBlIjoiREVMSVZFUllfTUFOIiwiZXhwIjoxNjY0MzQ2NzA0fQ.sYXuumXp0kX7a0PD4PxUe1RM0O09jC0HI2L--sSGCvQ`,
          },
        }
      )
      .then((res) => {
        setPath(
          res.data?.locationHistories.map((point: any) => ({
            lat: point?.location?.latitude,
            lng: point?.location?.longitude,
          }))
        );

        setGetLocationHistory({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGetLocationHistory({ loading: false, data: [] });
        console.error("Location: Error", err);
      });
  }, [range.from, range.to]);

  useEffect(() => {
    getDriverLocationHistory();
  }, [getDriverLocationHistory]);

  // get polygon data
  const locationHistory = getLocationHistory[getLocationHistory.length - 1];

  const position = {
    lat: locationHistory?.location?.latitude,
    lng: locationHistory?.location?.longitude,
  };

  const position2 = {
    lat: 23.773414356724892,
    lng: 90.41340705259854,
  };
  //new add order details map

  const reseAllFieldData = () => {
    form.resetFields();
  };

  return (
    <>
      <DriverLayout
        driverId={(route as any)?.id as string}
        title="Riding History"
        extra={
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>
        }
      >
        <div
          className={showSearch ? `content-body-withSearch` : `content-body`}
        >
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            {showSearch && (
              <div className={styles.searchBox}>
                <Form form={form} layout="inline" className={styles.formInline}>
                  <Form.Item name="range">
                    <RangePicker
                      onChange={(e, f) => {
                        console.log("momnet", moment(f[0])?.toISOString());
                        setRange({
                          from: moment.utc(f[0])?.toISOString(),
                          to: moment.utc(f[1])?.toISOString(),
                        });
                      }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss"),
                        ],
                      }}
                      inputReadOnly
                      allowClear
                    />
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  danger
                  size="large"
                  onClick={reseAllFieldData}
                >
                  Reset
                </Button>
              </div>
            )}
            <div className="">
              <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyBAV5ux84Aast-onJ1DOq7uspbN6doNxXQ"
                language="en"
                region="us"
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={
                    driverLocation?.location?.latitude ? position : position2
                  }
                  zoom={15}
                >
                  <Polyline
                    options={{
                      strokeColor: "red",
                      strokeOpacity: 0.75,
                      strokeWeight: 4,
                      icons: [
                        {
                          // icon: lineSymbol,
                          offset: "0",
                          repeat: "20px",
                        },
                      ],
                    }}
                    path={
                      path
                    } /* All these coordinates are coming from props */
                  />

                  {getLocationHistory?.data?.locationHistories?.map(
                    (driverLocation: any) => (
                      <Marker
                        icon={DriverMarker}
                        title={moment(driverLocation?.createdAt).format("lll")}
                        position={{
                          lat: driverLocation?.location?.latitude,
                          lng: driverLocation?.location?.longitude,
                        }}
                        onClick={() => shopActiveMarker(driverLocation?.id)}
                      >
                        {activeMarker === driverLocation?.id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div className="inner-body-wrapper">
                              <div className="inner-body">
                                <div className="single">
                                  <div className="info-name">
                                    <span>Name</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>{driverLocation?.user?.name}</span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Mobile Number</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>
                                      {driverLocation?.user?.mobileNumber}
                                    </span>
                                  </div>
                                </div>
                                <div className="single">
                                  <div className="info-name">
                                    <span>Stay Time</span>
                                  </div>
                                  <div className="info-desc">
                                    <span>
                                      {moment(driverLocation?.createdAt).format(
                                        "lll"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    )
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </DriverLayout>
    </>
  );
};

export default DriverLocationHistoryMap;
