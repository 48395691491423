import { AutoComplete, Button, Empty, Form, Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination/Pagination";
import { getPage } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";

const VehicleTypeList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const loc = useLocation();
  const [showSearch, setShowSearch] = useState(true);
  const [limit, setLimit] = useState(16);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [vehicleData, setVehicleData] = useState<any>({
    loading: false,
    data: null,
  });

  const [vehicleOptions, setVehicleOptions] = useState({
    loading: false,
    list: [],
  });

  const [selectedVehicleForEdit, setSelectedVehicleForEdit] =
    useState<any>(undefined);

  const getVehicle = useCallback(async () => {
    setVehicleData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/vehicle-type/all` +
          (key ? `?vehicle-type=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVehicleData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVehicleData({ loading: false, data: null });
        console.error("Locations: Error", err);
      });
  }, [key]);

  const getVehicleOptions = useCallback(async (key: any) => {
    setVehicleOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    return axios
      .get(
        `${encodedUri}/admin/vehicle-type/all` +
          (key ? `?vehicle-type=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVehicleOptions({
          loading: false,
          list: res.data?.vehicleTypes?.map(
            (vehicle: { vehicleType: any }) => ({
              label: vehicle.vehicleType,
              value: vehicle.vehicleType,
            })
          ),
        });
      })
      .catch((err) => {
        setVehicleOptions({ loading: false, list: [] });
        console.error("Locations: Error", err);
      });
  }, []);

  const fetchRef = useRef(0);
  const debounceLocationOptionsFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      getVehicleOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getVehicleOptions]);

  //******************************** */
  const onSubmit = async (data: any) => {
    console.log("data", data);
    setLoading(true);

    if (selectedVehicleForEdit) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle-type`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vehicleType: data.vehicleType,
          id: selectedVehicleForEdit?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Type Updated Successfully", "success");
            form.resetFields();
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/vehicle-type`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ vehicleType: data.vehicleType }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Type Create Successfully", "success");
            form.resetFields();
            onClose();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //******************************** */

  useEffect(() => {
    getVehicle();
  }, [getVehicle]);

  useEffect(() => {
    if (showSearch) {
      getVehicleOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (visible || selectedVehicleForEdit) {
      form.resetFields();
    }
  }, [visible, selectedVehicleForEdit]);

  const onClose = () => {
    setSelectedVehicleForEdit(null);
    getVehicle();
    setVisible(false);
    form.resetFields();
  };

  const reseAllFieldData = () => {
    setKey("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title="Vehicle Type"
        subTitle={`${
          vehicleData?.data?.totalElements ||
          vehicleData?.data?.vehicleTypes?.length
        } Vehicle(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />
      {showSearch && (
        <div className={styles.searchBox}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={debounceLocationOptionsFetcher}
                onSelect={(val: any) => setKey(val?.toString())}
                options={vehicleOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  vehicleOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  style={{ width: "calc(100% - 40px)" }}
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={vehicleOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {vehicleData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {vehicleData?.data?.vehicleTypes?.length ? (
                      vehicleData?.data?.vehicleTypes?.map(
                        (type: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>{type?.vehicleType}</td>
                            <td>
                              <Button
                                type="dashed"
                                shape="round"
                                onClick={() => setSelectedVehicleForEdit(type)}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...vehicleData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Modal
        title={visible ? "Add Vehicle" : "Edit Vehicle"}
        open={visible || selectedVehicleForEdit}
        onCancel={onClose}
        okText={`Submit`}
        okButtonProps={{
          loading: loading,
          onClick: form.submit,
        }}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...selectedVehicleForEdit,
          }}
        >
          <Form.Item
            hasFeedback
            label="Vehicle Type"
            rules={[
              {
                required: true,
                message: "Vehicle Type is Required!",
              },
            ]}
            name="vehicleType"
          >
            <Input
              id="vehicleType"
              type="text"
              placeholder="Enter Vehicle Type"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VehicleTypeList;
