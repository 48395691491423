import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleBusinessType from "./Details";
import AddBusinessType from "./AddBusinessType";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";

const BusinessTypeList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedBusinessType, setSelectedBusinessType] = useState(undefined);
  const [selectedBusinessTypeForEdit, setSelectedBusinessTypeForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [businessTypeData, setBusinessTypeData] = useState<any>({
    loading: false,
    data: null,
  });

  const getBusinessTypes = useCallback(async () => {
    setBusinessTypeData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_WRITER_API}`;
    axios
      .get(
        `${encodedUri}/shop/service-type-list?limit=${limit}` +
          (page
            ? `&page=${
                page == businessTypeData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBusinessTypeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBusinessTypeData({ loading: false, data: [] });
        console.error("BusinessTypes: Error", err);
      });
  }, [isForLanding]);

  //
  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: !val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getBusinessTypes();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onBusinessTypeRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Business Type Remove Successfully",
              "success"
            );
            getBusinessTypes();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getBusinessTypes();
  }, [getBusinessTypes]);

  const onClose = () => {
    getBusinessTypes();
    setVisible(undefined);
    setSelectedBusinessType(undefined);
    setSelectedBusinessTypeForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Business Type List"
        subTitle={`${
          businessTypeData?.data?.totalElements ||
          businessTypeData?.data?.businessTypes?.length ||
          0
        } BusinessType(s)`}
        extra={[
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]} 
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {businessTypeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Business Type</th>
                      <th scope="col">Business Value</th>
                      <th scope="col">Status</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {businessTypeData?.data?.businessTypes?.length ? (
                      businessTypeData?.data?.businessTypes?.map(
                        (businessType: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedBusinessType(businessType)}
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {businessType?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {businessType?.value}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!businessType?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(businessType?.id, val);
                                }}
                              />
                            </td>
                           
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {businessType?.updatedBy?.name ||
                                  businessType?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedBusinessTypeForEdit(businessType)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={businessType?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBusinessTypeRemove(businessType?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(businessType?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...businessTypeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Business Type"
            : selectedBusinessTypeForEdit
            ? "Edit Business Type"
            : "Business Type Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBusinessType || selectedBusinessTypeForEdit}
        width={450}
      >
        {visible || selectedBusinessTypeForEdit ? (
          <AddBusinessType
            onCloseMethod={onClose}
            visibleData={selectedBusinessTypeForEdit}
          />
        ) : (
          <SingleBusinessType BusinessTypeDetails={selectedBusinessType} />
        )}
      </Drawer>
    </>
  );
};

export default BusinessTypeList;
