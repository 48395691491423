import React from "react";
import MainLayout from "../../components/Layouts";
import PointConfigurationList from "../../components/Referral/point-configuration";

const PointConfigurationPage = () => {
  return (
    <MainLayout>
      <PointConfigurationList />
    </MainLayout>
  );
};

export default PointConfigurationPage;
