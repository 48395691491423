import React from "react";
import MainLayout from "../../../components/Layouts";
import CorporateBlanceHistoryList from "../../../components/Corporate/Blance/index";

const CorporateBlanceHistoryPage = () => {
  return (
    <MainLayout>
      <CorporateBlanceHistoryList />
    </MainLayout>
  );
};

export default CorporateBlanceHistoryPage;
