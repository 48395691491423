import React from "react";
import MainLayout from "../../components/Layouts";
import CourseList from "../../components/Course";

const CoursePage = () => {
  return (
    <MainLayout>
      <CourseList />
    </MainLayout>
  );
};

export default CoursePage;
