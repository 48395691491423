import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import SingleMergeOrder from "../../components/MergeOrder/Details/index";
import React from "react";

const SingleMergeOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <SingleMergeOrder />
    </MainLayout>
  );
};

export default SingleMergeOrderPage;
