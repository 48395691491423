import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios";

const AddChgapter = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [quizOptions, setQuizOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [shopId, setShopId] = useState<any>("");
  const [quizId, setQuizId] = useState<any>([]); 
  const [courseId, setCourseId] = useState<any>("");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [chapterData, setChapterData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");

  const [courseOptions, setCourseOptions] = useState<any>({
    list: [],
    loading: false,
  });
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      description: convertedText || data.description,
      courseId: courseId || data.courseId,
      shopId: shopId || data.shopId,
      isPrerequisite: data.isPrerequisite,
      isActive: true,
      quizIdList: quizId,
      uploadDetails: {
        bannerImageUrl: bannerImgUrl
          ? bannerImgUrl
          : data?.uploadDetails?.bannerImage || "NULL",
        imageUrl: generatedImgUrl
          ? generatedImgUrl
          : data?.uploadDetails?.imageUrl || "NULL",
        videoUrl: data.videoUrl || "NULL",
        title: data.name,
      },
    };

    if (chapterData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/chapter`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: chapterData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Chapter Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/chapter`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Chapter Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setChapterData(visibleData);
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
    }
  }, [visibleData]);

  useEffect(() => {
    if (chapterData) {
      form.resetFields(Object.keys(visibleData));
      setBannerImgUrl(visibleData?.uploadDetails?.bannerImageUrl);
      setGeneratedImgUrl(visibleData?.uploadDetails?.imageUrl);
    }
  }, [chapterData, form, visibleData]);

  const getCourseOptions = useCallback(async (key?: any) => {
    setCourseOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/course?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCourseOptions({
      loading: false,
      list: res?.data?.courses?.map((course: any) => {
        return {
          value: course?.id,
          label: course?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getCourseOptions();
  }, [getCourseOptions]);

  const getShopsOptions = useCallback(async (key?: string) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);

  const getQuizOptions = useCallback(async (key?: string) => {
    setQuizOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/course-quiz?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setQuizOptions({
      loading: false,
      list: res?.data?.courseQuiz?.map((quiz: any) => {
        return {
          value: quiz?.id,
          label: quiz?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getQuizOptions();
  }, [getQuizOptions]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "program") getCourseOptions(value);
          if (field === "course") getCourseOptions(value);
          if (field === "shop") getShopsOptions(value);
          if (field === "quiz") getQuizOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getCourseOptions, getShopsOptions, getQuizOptions, type]);
  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...chapterData,
        }}
      >
        <div className="">
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Name" />
          </Form.Item>
        </div>

        <Form.Item hasFeedback label="Description" name="description">
          <JoditEditor
            ref={editor}
            value={convertedText}
            config={jodiConfig as any}
            onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Is Prerequisite?"
          name="isPrerequisite"
          className="mr-5"
        >
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            defaultChecked={(chapterData as any)?.isPrerequisite}
          />
        </Form.Item>
        <div className="">
          <Form.Item
            hasFeedback
            label="Course"
            rules={[
              {
                required: true,
                message: "Course is required!",
              },
            ]}
            name="courseId"
          >
            <Select
              showSearch
              placeholder="Select Course"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "course");
              }}
              onChange={(val) => setCourseId(val)}
              filterOption={() => {
                return true;
              }}
              options={courseOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Organization Name"
            rules={[
              {
                required: true,
                message: "Organization name is required!",
              },
            ]}
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Quiz List"
            rules={[
              {
                required: false,
                message: "Quiz list name is required!",
              },
            ]}
            name="quizIdList"
          >
            <Select
              showSearch
              placeholder="Select Quiz List"
              mode="multiple"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "quiz");
              }}
              onChange={(val) => setQuizId(val)}
              filterOption={() => {
                return true;
              }}
              options={quizOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback
          label="Video Url"
          rules={[
            {
              required: false,
              message: "Video Url is Required!",
            },
          ]}
          name="videoUrl"
        >
          <Input id="videoUrl" type="text" placeholder="Enter videoUrl Url" />
        </Form.Item>
        <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Image
              <Tooltip
                placement="bottom"
                title={
                  "Program image must be transparent & max height 100px and width auto"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">Banner Image</Divider>
            <ImageUploader
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>

        <div className="mt-2">
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        </div>
      </Form>
    </>
  );
};

export default AddChgapter;
