import { Divider, Form, Input, Select, Tooltip } from "antd";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { responseNotification } from "../../utils/notify";
import { useNavigate, useParams } from "react-router";
import ImageUploader from "../common/ImageUploader";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useSelector } from "react-redux";
import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SubmitResetBtn from "../common/SubmitBtn";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";

const AddContent = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);
  const navigate = useNavigate();
  const [convertedText, setConvertedText] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedThumbUrl, setGeneratedThumbUrl] = useState<string>();
  const route = useParams();

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singleContentInfo, setSingleContentInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const [categoryOptions, setCategoryOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const fetchContentDetails = useCallback((contentSlug: any) => {
    if (contentSlug) {
      try {
        setSingleContentInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/web-content/details?slug=${contentSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleContentInfo({
                loading: false, 
                data: data?.data?.webContentResponse,
              });
              setConvertedText(
                data?.data?.webContentResponse?.description || ""
              );
              setGeneratedThumbUrl(data?.data?.webContentResponse?.thumbnail);
              setGeneratedImgUrl(data?.data?.webContentResponse?.bannerImage);
            } else {
              setSingleContentInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleContentInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleContentInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    // if (generatedImgUrl || generatedThumbUrl) {
    setLoading(true);
    let readyData = data && {
      title: data.title,
      description: convertedText || "<p></p>",
      shortDescription: data.shortDescription,
      contentType: data.contentType || "BLOG",
      categoryId: data.categoryId,
      keyword: data.keyword,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      type: type,
      thumbnail: generatedThumbUrl,
      bannerImage: generatedImgUrl,
    };

    if (singleContentInfo?.data) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/web-content`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: singleContentInfo?.data?.id,
          isActive: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Content Updated Successfully", "success");
            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/web-content`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Content Create Successfully", "success");

            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
    // } else {
    //   responseNotification(`Thumbnail & Image required`, "error");
    // }
  };

  // **********************************
  // Search Options
  // **********************************

  const getCategoryOptions = useCallback(
    async (key) => {
      setCategoryOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/web-content/category?type=${type}&contentType=BLOG`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCategoryOptions({
        loading: false,
        list: res?.data?.categories?.map(
          (category: { id: any; title: any }) => {
            return {
              value: category?.id,
              label: category?.title,
            };
          }
        ),
      });
    },
    [type]
  );

  // **********************************
  // Search Options
  // **********************************

  useEffect(() => {
    getCategoryOptions(``);
  }, [getCategoryOptions]);

  useEffect(() => {
    fetchContentDetails((route as any)?.slug);
  }, [fetchContentDetails, route]);

  useEffect(() => {
    if (singleContentInfo?.data) {
      // form.resetFields(Object.keys(singleContentInfo?.data));
      form.resetFields();
      setGeneratedThumbUrl(singleContentInfo?.data?.thumbnail);
      setGeneratedImgUrl(singleContentInfo?.data?.bannerImage);
    }
  }, [form, singleContentInfo?.data]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(singleContentInfo?.data?.bannerImage);
    setGeneratedThumbUrl(singleContentInfo?.data?.thumbnail);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={singleContentInfo?.data ? "Edit Content" : "Add Content"}
      />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleContentInfo?.data,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
              <h3 className="text-xl font-bold col-span-2 mb-10 border-b pb-2 sticky top-0 bg-white z-30">
                Content Info
              </h3>
              <div>
                <Form.Item
                  hasFeedback
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Title is Required!",
                    },
                  ]}
                  name="title"
                >
                  <Input id="title" type="text" placeholder="Enter Title" />
                </Form.Item>
              </div>
              <div className="">
                <Form.Item
                  hasFeedback
                  label="Category"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Category is Required!",
                  //   },
                  // ]}
                  name="categoryId"
                >
                  <Select
                    options={categoryOptions?.list}
                    placeholder="Select Category"
                  ></Select>
                </Form.Item>
              </div>
              {/* <div className="">
                    <Form.Item
                      hasFeedback
                      label="Content Type"
                      rules={[
                        {
                          required: true,
                          message: "Content Type is Required!",
                        },
                      ]}
                      name="contentType"
                      initialValue="BLOG"
                    >
                      <Select
                        options={[
                          { value: "BLOG", label: "BLOG" },
                          // { value: "ARTICLE", label: "SINGLE ARTICLE" },
                        ]}
                        id="contentType"
                        placeholder="Select Content Type"
                      />
                    </Form.Item>
                  </div> */}
              <div className="col-span-2">
                <Form.Item
                  hasFeedback
                  label="Short Description"
                  rules={[
                    {
                      required: true,
                      message: "Short Description is Required!",
                    },
                  ]}
                  name="shortDescription"
                >
                  <Input.TextArea
                    id="shortDescription"
                    rows={5}
                    placeholder="Enter Content Short Description"
                  />
                </Form.Item>
              </div>
              <div className="col-span-2 mb-8">
                <h4 className="font-semibold mb-1">Content Details</h4>
                <JoditEditor
                  ref={editor}
                  value={convertedText}
                  config={jodiConfig as any}
                  // tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                />
              </div>
              <h3 className="mb-4 text-xl font-bold col-span-2">SEO</h3>

              <div className="col-span-2">
                <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                  <Input
                    id="metaTitle"
                    type="text"
                    placeholder="Enter Meta Title"
                  />
                </Form.Item>
              </div>
              <div className="">
                <Form.Item hasFeedback label="Keywords" name="keyword">
                  <Input.TextArea
                    id="keyword"
                    placeholder="Enter Product Keywords"
                    rows={3}
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  hasFeedback
                  label="Meta Description"
                  name="metaDescription"
                >
                  <Input.TextArea
                    id="metaDescription"
                    placeholder="Enter Meta Description"
                    rows={3}
                  />
                </Form.Item>
              </div>

              <div className="flex gap-12">
                <div className="">
                  <Divider orientation="left">
                    Thumbnail
                    <Tooltip
                      placement="bottom"
                      title={
                        "Thumbnail Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    // crop={1}
                    imgUrl={generatedThumbUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => {
                      setGeneratedThumbUrl(url);
                    }}
                  />
                </div>
                <div className="">
                  <Divider orientation="left">Banner Image</Divider>
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload2}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>
              </div>

              <div className="col-span-2 my-6">
                {" "}
                <SubmitResetBtn
                  onClickSubmit={(e) => {
                    e.preventDefault();
                    setStartUpload("Uploading");
                    setStartUpload2("Uploading");
                  }}
                  onClickReset={resetData}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddContent;
