import React from "react";
import MainLayout from "../../components/Layouts";
import DefaultDeliveryConfigCreate from "../../components/Config/DefaultDeliveryConfigCreate";

const DefaultDeliveryConfigCreatePage = () => {
  return (
    <MainLayout>
      <DefaultDeliveryConfigCreate />
    </MainLayout>
  );
};

export default DefaultDeliveryConfigCreatePage;
