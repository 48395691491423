import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import RevenueHistoryList from "../../components/Orders/RevenueHistory";
import React from "react";

const RevenueHistoryPage = (): ReactElement => {
  return (
    <MainLayout>
      <RevenueHistoryList />
    </MainLayout>
  );
};

export default RevenueHistoryPage;
