import React, { useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  CloseOutlined,
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";
import { convertLegacyProps } from "antd/lib/button/button";
import moment from "moment";
import dayjs from "dayjs";
import { jcDayArray } from "../../utils";
import { responseNotification } from "../../utils/notify";
const format = "HH:mm:ss";

interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const ShippingRate = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [size, setSize] = useState<SizeType>("large");
  console.log("type ::", type);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
  const [shippingRateType, setShippingRateType] =
    useState<any>("setUpYourOwnRates");
  const [conditionType, setConditionType] = useState<any>();
  const [getCondition, setGetCondition] = useState<any>(false);
  const [teacherData, setTeacherData] = useState<any>({
    loading: false,
    data: null,
  });
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [conditionChecked, setConditionChecked] = useState(false);

  const [checked, setChecked] = useState(false);
  const [businessDay, setBusinessDay] = useState();
  const [departureTime, setDepartureTime] = useState("12:08:00");
  const [arrivalTime, setArrivalTime] = useState("12:08:00");
  const [selected, setSelected] = useState<any>(1);
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      shippingRates: [
        {
          basedOnAreas: data?.experienceList?.map(
            (area: any, areaId: string) => ({
              charge: area.charge,
              country: area.country,
              zoneFrom: area.zoneFrom,
              zoneTo: area.zoneTo,
            })
          ),
          basedOnDistances: data?.basedOnDistanceList?.map(
            (distances: any, distancesId: string) => ({
              charge: distances.charge,
              customerWithinMaximumRadios:
                distances.customerWithinMaximumRadios,
              customerWithinMinimumRadios:
                distances.customerWithinMinimumRadios,
            })
          ),
          basedOnItemSchedules: data?.basedOnScheduleList?.map(
            (schedules: any, schedulesId: string) => ({
              businessDay: schedules.businessDay,
              charge: schedules.charge,
              scheduleDeliveryTimeFrom: schedules.scheduleDeliveryTimeFrom,
              scheduleDeliveryTimeTo: schedules.scheduleDeliveryTimeTo,
            })
          ),
          basedOnItemWeights: data?.basedOnItemWeightList?.map(
            (itemWeight: any, itemWeightId: string) => ({
              charge: itemWeight.charge,
              maximumWeight: itemWeight.maximumWeight,
              minimumWeight: itemWeight.minimumWeight,
            })
          ),
          basedOnOrderPrices: data?.basedOnItemPriceList?.map(
            (orderPrice: any, orderPriceId: string) => ({
              aboveOrderPrice: orderPrice.aboveOrderPrice,
              charge: orderPrice.charge,
              maximumAboveOrderPrice: orderPrice.maximumAboveOrderPrice,
            })
          ),
          carrierLogo: data.carrierLogo,
          carrierName: data.carrierName,
          conditions: data.conditions,
          flatAmount: data.flatAmount,
          percentageAmount: data.percentageAmount,
          rateAmountType: data.rateAmountType,
          setUpYourOwnRates: data.setUpYourOwnRates,
          shippingHandlingFee: data.shippingHandlingFee,
          shippingPrice: data.shippingPrice,
          shippingTransitTime: {
            day: data.day,
            hour: data.hour,
            minute: data.minute,
          },
          shippingTransitType: data.shippingTransitType,
          useCarrierOrAppToCalculateRates: data.useCarrierOrAppToCalculateRates,
        },
      ],
    };

    await fetch(
      `${process.env.REACT_APP_ORDER_API}/delivery-configuration/add-shipping-rate`,
      { 
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Create Successfully", "success");
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8 ">
            <div className="content-body">
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  onFinish={onSubmit}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full mt-8">
                      <h3 className="mb-2 text-2xl text-primary">Add Rate</h3>
                    </div>
                    <div className="float-left w-full">
                      <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group
                          onChange={(val) =>
                            setShippingRateType((val as any).target.value)
                          }
                          value={shippingRateType || "setUpYourOwnRates"}
                          defaultValue={"setUpYourOwnRates"}
                          name="shippingRateType"
                        >
                          <Radio
                            value={"setUpYourOwnRates"}
                            name="setUpYourOwnRates"
                            className="float-left w-full"
                          >
                            Set up your own rates
                          </Radio>

                          <Radio
                            value={"useCarrierOrAppToCalculateRates"}
                            className="float-left w-full"
                          >
                            Use carrier or app to calculate rates
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="float-left w-full">
                      <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                    </div>
                    {shippingRateType === "setUpYourOwnRates" && (
                      <>
                        <div className="float-left w-full">
                          <div className="float-left w-1/2 p-4">
                            <Form.Item
                              hasFeedback
                              label="Shipping Transit Type"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/2 p-4">
                            <Form.Item
                              hasFeedback
                              label="Shipping Transit time"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-full">
                            <p className="float-left w-full text-left">
                              At least 3 services typically meet this transit
                              time. Show Services
                            </p>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <Form.Item
                            hasFeedback
                            label="Shipping Price"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Type your country  Zone Name is Required!",
                              },
                            ]}
                            name="name"
                          >
                            <Input
                              id="name"
                              type="text"
                              placeholder="Type your Shipping profile name"
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}

                    {shippingRateType === "useCarrierOrAppToCalculateRates" && (
                      <>
                        <div className="float-left w-full">
                          <div className="float-left w-full">
                            <div className="float-left w-1/2">
                              <Form.Item
                                hasFeedback
                                label="Carrier name"
                                rules={[
                                  {
                                    required: false,
                                    message:
                                      "Type your country  Zone Name is Required!",
                                  },
                                ]}
                                name="name"
                              >
                                <Input
                                  id="name"
                                  type="text"
                                  placeholder="Type your Shipping profile name"
                                />
                              </Form.Item>
                            </div>
                            <div className="float-left w-1/2">
                              <p className="float-left w-full text-left text-2xl">
                                Logo
                              </p>
                              <Avatar
                                shape="square"
                                className="w-20 h-20 p-6 m-4"
                                icon={<UserOutlined />}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <div className="float-left w-1/2 p-4">
                            <Form.Item
                              hasFeedback
                              label="Shipping Transit Type"
                              rules={[
                                {
                                  required: false,
                                  message: "Your processing Time is Required!",
                                },
                              ]}
                              name="campaignType"
                            >
                              <Select
                                options={[
                                  { value: "DISCOUNT", label: "DISCOUNT" },
                                  { value: "BOGO", label: "BUY ONE GET ONE" },
                                  { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                  { value: "GROUP_BUY", label: "GROUP BUY" },
                                  {
                                    value: "PURCHASE_VOUCHER",
                                    label: "PURCHASE VOUCHER",
                                  },
                                  {
                                    value: "FREE_DELIVERY",
                                    label: "FREE DELIVERY",
                                  },
                                ]}
                                placeholder="Enter Campaign Type"
                              />
                            </Form.Item>
                          </div>
                          <div className="float-left w-1/2 p-4">
                            <Form.Item
                              hasFeedback
                              label="Shipping Transit time"
                              rules={[
                                {
                                  required: false,
                                  message: "Your processing Time is Required!",
                                },
                              ]}
                              name="campaignType"
                            >
                              <Select
                                options={[
                                  { value: "DISCOUNT", label: "DISCOUNT" },
                                  { value: "BOGO", label: "BUY ONE GET ONE" },
                                  { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                  { value: "GROUP_BUY", label: "GROUP BUY" },
                                  {
                                    value: "PURCHASE_VOUCHER",
                                    label: "PURCHASE VOUCHER",
                                  },
                                  {
                                    value: "FREE_DELIVERY",
                                    label: "FREE DELIVERY",
                                  },
                                ]}
                                placeholder="Enter Campaign Type"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="float-left w-full">
                          <div className="float-left w-full">
                            <Form.Item
                              hasFeedback
                              label="Shipping Price"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                id="name"
                                type="text"
                                placeholder="Type your Shipping profile name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    {/* new add  */}

                    <div className="float-left w-full">
                      <label>
                        Add Conditions
                        <input
                          name="checkbox"
                          type="checkbox"
                          checked={conditionChecked}
                          onChange={() => {
                            setConditionChecked(!conditionChecked);
                          }}
                        />
                      </label>
                    
                    </div>
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        name="shippingDeliveryType"
                        initialValue={shippingDeliveryType}
                      >
                        <Radio.Group
                          onChange={(val) =>
                            setConditionType((val as any).target.value)
                          }
                          value={conditionType}
                          defaultValue={conditionType}
                          disabled={!conditionChecked}
                        >
                          <Radio
                            value={"basedOnItemWeights"}
                            className="float-left w-full"
                          >
                            Based on item weight
                          </Radio>
                          <Radio
                            value={"basedOnOrderPrices"}
                            className="float-left w-full mt-4"
                          >
                            Based on order price
                          </Radio>
                          <Radio
                            value={"basedOnDistances"}
                            className="float-left w-full mt-4"
                          >
                            Based on Distance
                          </Radio>
                          <Radio
                            value={"basedOnAreas"}
                            className="float-left w-full mt-4"
                          >
                            Based on Area
                          </Radio>
                          <Radio
                            value={"basedOnItemSchedules"}
                            className="float-left w-full mt-4"
                          >
                            Based on Schedule
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {conditionType === "basedOnItemWeights" && (
                      <>
                        <div className="float-left w-full">
                          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                            <h3 className="mb-4 text-xl font-bold">
                              Based on item weight
                            </h3>

                            <Form.List
                              name="basedOnItemWeightList"
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                      return Promise.reject(
                                        new Error(
                                          "At Least 1 Based on item weight Required!"
                                        )
                                      );
                                    }
                                  },
                                },
                              ]}
                              initialValue={[{}]}
                            >
                              {(
                                basedOnItemWeightFields,
                                { add, remove },
                                { errors }
                              ) => (
                                <>
                                  {basedOnItemWeightFields.map(
                                    ({
                                      basedOnItemKey,
                                      name,
                                      baseOnItemFieldKey,
                                      ...baseOnItemRestField
                                    }: any) => (
                                      <Card
                                        className="mb-2"
                                        size="small"
                                        key={basedOnItemKey}
                                      >
                                        <Space wrap align="center">
                                          <Form.Item
                                            {...baseOnItemRestField}
                                            name={[name, "minimumWeight"]}
                                            fieldKey={[
                                              baseOnItemFieldKey,
                                              "minimumWeight",
                                            ]}
                                            hasFeedback
                                            label="Minimum weight"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.minimumWeight
                                            }
                                          >
                                            <Input placeholder="Enter minimum weight as like 0 kg" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnItemRestField}
                                            name={[name, "maximumWeight"]}
                                            fieldKey={[
                                              baseOnItemFieldKey,
                                              "maximumWeight",
                                            ]}
                                            hasFeedback
                                            label="Maximum weight"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.maximumWeight
                                            }
                                          >
                                            <Input placeholder="Enter Maximum weight as like 1 kg" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnItemRestField}
                                            name={[name, "charge"]}
                                            fieldKey={[
                                              baseOnItemFieldKey,
                                              "charge",
                                            ]}
                                            hasFeedback
                                            label="Charge"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.charge
                                            }
                                          >
                                            <Input placeholder="Enter Charge" />
                                          </Form.Item>
                                        </Space>

                                        <Button
                                          type="primary"
                                          danger
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          onClick={() => {
                                            remove(name);
                                            setVariationImageUrl((prev) => {
                                              const newArr = prev.filter(
                                                (_, i) => i !== name
                                              );
                                              return newArr;
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </Card>
                                    )
                                  )}

                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      flex: "1 1 100%",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr.push("");
                                          return newArr;
                                        });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add More
                                    </Button>
                                  </Form.Item>

                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      </>
                    )}

                    {conditionType === "basedOnOrderPrices" && (
                      <>
                        <div className="float-left w-full">
                          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                            <h3 className="mb-4 text-xl font-bold">
                              Based on order price
                            </h3>

                            <Form.List
                              name="basedOnItemPriceList"
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                      return Promise.reject(
                                        new Error(
                                          "At Least 1 Based on item price Required!"
                                        )
                                      );
                                    }
                                  },
                                },
                              ]}
                              initialValue={[{}]}
                            >
                              {(
                                basedOnItemPriceFields,
                                { add, remove },
                                { errors }
                              ) => (
                                <>
                                  {basedOnItemPriceFields.map(
                                    ({
                                      basedOnItemPriceKey,
                                      name,
                                      baseOnItemPriceFieldKey,
                                      ...baseOnItemPriceRestField
                                    }: any) => (
                                      <Card
                                        className="mb-2"
                                        size="small"
                                        key={basedOnItemPriceKey}
                                      >
                                        <Space wrap align="center">
                                          <Form.Item
                                            {...baseOnItemPriceRestField}
                                            name={[name, "aboveOrderPrice"]}
                                            fieldKey={[
                                              baseOnItemPriceFieldKey,
                                              "aboveOrderPrice",
                                            ]}
                                            hasFeedback
                                            label="Above Order Price"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnOrderPrices?.[name]
                                                ?.aboveOrderPrice
                                            }
                                          >
                                            <Input placeholder="Enter Above Order Price" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnItemPriceRestField}
                                            name={[
                                              name,
                                              "maximumAboveOrderPrice",
                                            ]}
                                            fieldKey={[
                                              baseOnItemPriceFieldKey,
                                              "maximumAboveOrderPrice",
                                            ]}
                                            hasFeedback
                                            label="Maximum Above Order Price"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnOrderPrices?.[name]
                                                ?.maximumAboveOrderPrice
                                            }
                                          >
                                            <Input placeholder="Enter Maximum Above Order Price" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnItemPriceRestField}
                                            name={[name, "charge"]}
                                            fieldKey={[
                                              baseOnItemPriceFieldKey,
                                              "charge",
                                            ]}
                                            hasFeedback
                                            label="Charge"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnOrderPrices?.[name]
                                                ?.charge
                                            }
                                          >
                                            <Input placeholder="Enter Charge" />
                                          </Form.Item>
                                        </Space>

                                        <Button
                                          type="primary"
                                          danger
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          onClick={() => {
                                            remove(name);
                                            setVariationImageUrl((prev) => {
                                              const newArr = prev.filter(
                                                (_, i) => i !== name
                                              );
                                              return newArr;
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </Card>
                                    )
                                  )}

                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      flex: "1 1 100%",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr.push("");
                                          return newArr;
                                        });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add More
                                    </Button>
                                  </Form.Item>

                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      </>
                    )}

                    {conditionType === "basedOnDistances" && (
                      <>
                        <div className="float-left w-full">
                          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                            <h3 className="mb-4 text-xl font-bold">
                              Based on Distance
                            </h3>

                            <Form.List
                              name="basedOnDistanceList"
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                      return Promise.reject(
                                        new Error(
                                          "At Least 1 Based on Distance Required!"
                                        )
                                      );
                                    }
                                  },
                                },
                              ]}
                              initialValue={[{}]}
                            >
                              {(
                                basedOnDistanceFields,
                                { add, remove },
                                { errors }
                              ) => (
                                <>
                                  {basedOnDistanceFields.map(
                                    ({
                                      basedOnDistanceKey,
                                      name,
                                      baseOnDistanceFieldKey,
                                      ...baseOnDistanceRestField
                                    }: any) => (
                                      <Card
                                        className="mb-2"
                                        size="small"
                                        key={basedOnDistanceKey}
                                      >
                                        <Space wrap align="center">
                                          <Form.Item
                                            {...baseOnDistanceRestField}
                                            name={[
                                              name,
                                              "customerWithinMinimumRadios",
                                            ]}
                                            fieldKey={[
                                              baseOnDistanceFieldKey,
                                              "customerWithinMinimumRadios",
                                            ]}
                                            hasFeedback
                                            label="Customer within Minimum Radios"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.customerWithinMinimumRadios
                                            }
                                          >
                                            <Input placeholder="Enter Customer within Minimum Radios" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnDistanceRestField}
                                            name={[
                                              name,
                                              "customerWithinMaximumRadios",
                                            ]}
                                            fieldKey={[
                                              baseOnDistanceFieldKey,
                                              "customerWithinMaximumRadios",
                                            ]}
                                            hasFeedback
                                            label="Customer within Maximum Radios"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.customerWithinMaximumRadios
                                            }
                                          >
                                            <Input placeholder="Enter Customer within Maximum Radios" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnDistanceRestField}
                                            name={[name, "charge"]}
                                            fieldKey={[
                                              baseOnDistanceFieldKey,
                                              "charge",
                                            ]}
                                            hasFeedback
                                            label="Charge"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.charge
                                            }
                                          >
                                            <Input placeholder="Enter Charge" />
                                          </Form.Item>
                                        </Space>

                                        <Button
                                          type="primary"
                                          danger
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          onClick={() => {
                                            remove(name);
                                            setVariationImageUrl((prev) => {
                                              const newArr = prev.filter(
                                                (_, i) => i !== name
                                              );
                                              return newArr;
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </Card>
                                    )
                                  )}

                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      flex: "1 1 100%",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr.push("");
                                          return newArr;
                                        });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add More
                                    </Button>
                                  </Form.Item>

                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      </>
                    )}

                    {conditionType === "basedOnAreas" && (
                      <>
                        <div className="float-left w-full">
                          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                            <h3 className="mb-4 text-xl font-bold">
                              Based on Area
                            </h3>

                            <Form.List
                              name="basedOnAreaList"
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                      return Promise.reject(
                                        new Error(
                                          "At Least 1 Based on area Required!"
                                        )
                                      );
                                    }
                                  },
                                },
                              ]}
                              initialValue={[{}]}
                            >
                              {(
                                basedOnAreaFields,
                                { add, remove },
                                { errors }
                              ) => (
                                <>
                                  {basedOnAreaFields.map(
                                    ({
                                      basedOnAreaKey,
                                      name,
                                      baseOnAreaFieldKey,
                                      ...baseOnAreaRestField
                                    }: any) => (
                                      <Card
                                        className="mb-2"
                                        size="small"
                                        key={basedOnAreaKey}
                                      >
                                        <Space wrap align="center">
                                          <Form.Item
                                            {...baseOnAreaRestField}
                                            name={[name, "minimumWeight"]}
                                            fieldKey={[
                                              baseOnAreaFieldKey,
                                              "minimumWeight",
                                            ]}
                                            hasFeedback
                                            label="Minimum weight"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.minimumWeight
                                            }
                                          >
                                            <Input placeholder="Enter minimum weight as like 0 kg" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnAreaRestField}
                                            name={[name, "minimumWeight"]}
                                            fieldKey={[
                                              baseOnAreaFieldKey,
                                              "minimumWeight",
                                            ]}
                                            hasFeedback
                                            label="Minimum weight"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.minimumWeight
                                            }
                                          >
                                            <Input placeholder="Enter minimum weight as like 0 kg" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnAreaRestField}
                                            name={[name, "maximumWeight"]}
                                            fieldKey={[
                                              baseOnAreaFieldKey,
                                              "maximumWeight",
                                            ]}
                                            hasFeedback
                                            label="Maximum weight"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.maximumWeight
                                            }
                                          >
                                            <Input placeholder="Enter Maximum weight as like 1 kg" />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnAreaRestField}
                                            name={[name, "charge"]}
                                            fieldKey={[
                                              baseOnAreaFieldKey,
                                              "charge",
                                            ]}
                                            hasFeedback
                                            label="Charge"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.charge
                                            }
                                          >
                                            <Input placeholder="Enter Charge" />
                                          </Form.Item>
                                        </Space>

                                        <Button
                                          type="primary"
                                          danger
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          onClick={() => {
                                            remove(name);
                                            setVariationImageUrl((prev) => {
                                              const newArr = prev.filter(
                                                (_, i) => i !== name
                                              );
                                              return newArr;
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </Card>
                                    )
                                  )}

                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      flex: "1 1 100%",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr.push("");
                                          return newArr;
                                        });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add More
                                    </Button>
                                  </Form.Item>

                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      </>
                    )}

                    {conditionType === "basedOnItemSchedules" && (
                      <>
                        <div className="float-left w-full">
                          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                            <h3 className="mb-4 text-xl font-bold">
                              Based on Schedule
                            </h3>

                            <Form.List
                              name="basedOnScheduleList"
                              rules={[
                                {
                                  validator: async (_, names) => {
                                    if (!names || names.length < 1) {
                                      return Promise.reject(
                                        new Error(
                                          "At Least 1 Based on Schedule Required!"
                                        )
                                      );
                                    }
                                  },
                                },
                              ]}
                              initialValue={[{}]}
                            >
                              {(
                                basedOnScheduleFields,
                                { add, remove },
                                { errors }
                              ) => (
                                <>
                                  {basedOnScheduleFields.map(
                                    ({
                                      basedOnScheduleKey,
                                      name,
                                      baseOnScheduleFieldKey,
                                      ...baseOnScheduleRestField
                                    }: any) => (
                                      <Card
                                        className="mb-2"
                                        size="small"
                                        key={basedOnScheduleKey}
                                      >
                                        <Space wrap align="center">
                                          <Form.Item
                                            {...baseOnScheduleRestField}
                                            name={[name, "businessDay"]}
                                            fieldKey={[
                                              baseOnScheduleFieldKey,
                                              "businessDay",
                                            ]}
                                            hasFeedback
                                            label="Business Day"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemSchedules?.[name]
                                                ?.businessDay
                                            }
                                          >
                                            <Select
                                              placeholder="Day"
                                              onChange={(val) =>
                                                setBusinessDay(val)
                                              }
                                              value={businessDay}
                                              style={{ width: 250 }}
                                            >
                                              {Object.values(jcDayArray)?.map(
                                                (dayType, dayIndex) => (
                                                  <Option
                                                    value={dayType}
                                                    key={dayIndex}
                                                  >
                                                    {dayType.replace("_", " ")}
                                                  </Option>
                                                )
                                              )}
                                            </Select>
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnScheduleRestField}
                                            name={[
                                              name,
                                              "scheduleDeliveryTimeFrom",
                                            ]}
                                            fieldKey={[
                                              baseOnScheduleFieldKey,
                                              "scheduleDeliveryTimeFrom",
                                            ]}
                                            hasFeedback
                                            label="Schedule Delivery Time( From)"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemSchedules?.[name]
                                                ?.scheduleDeliveryTimeFrom
                                            }
                                          >
                                            <TimePicker
                                              format={format}
                                              value={moment(
                                                departureTime,
                                                format
                                              )}
                                              placeholder="10:20:00"
                                              // onChange?: ((value: moment.Moment | null, dateString: string) => void) | undefined
                                              onChange={(value, dateString) => {
                                                console.log(
                                                  "Time",
                                                  value,
                                                  dateString
                                                );
                                                setDepartureTime(dateString);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnScheduleRestField}
                                            name={[
                                              name,
                                              "scheduleDeliveryTimeTo",
                                            ]}
                                            fieldKey={[
                                              baseOnScheduleFieldKey,
                                              "scheduleDeliveryTimeTo",
                                            ]}
                                            hasFeedback
                                            label="Schedule Delivery Time (to)"
                                            style={{ width: 300 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemSchedules?.[name]
                                                ?.scheduleDeliveryTimeTo
                                            }
                                          >
                                            <TimePicker
                                              format={format}
                                              value={moment(
                                                arrivalTime,
                                                format
                                              )}
                                              placeholder="12:10:00"
                                              onChange={(value, dateString) => {
                                                console.log(
                                                  "Time",
                                                  value,
                                                  dateString
                                                );
                                                setArrivalTime(dateString);
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            {...baseOnScheduleRestField}
                                            name={[name, "charge"]}
                                            fieldKey={[
                                              baseOnScheduleFieldKey,
                                              "charge",
                                            ]}
                                            hasFeedback
                                            label="Charge"
                                            style={{ width: 350 }}
                                            initialValue={
                                              teacherData?.data?.teacherDetails
                                                ?.basedOnItemWeights?.[name]
                                                ?.charge
                                            }
                                          >
                                            <Input placeholder="Enter Charge" />
                                          </Form.Item>
                                        </Space>

                                        <Button
                                          type="primary"
                                          danger
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          onClick={() => {
                                            remove(name);
                                            setVariationImageUrl((prev) => {
                                              const newArr = prev.filter(
                                                (_, i) => i !== name
                                              );
                                              return newArr;
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      </Card>
                                    )
                                  )}

                                  <Form.Item
                                    style={{
                                      width: "100%",
                                      flex: "1 1 100%",
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      onClick={() => {
                                        add();
                                        setVariationImageUrl((prev) => {
                                          const newArr = Array.from(prev);
                                          newArr.push("");
                                          return newArr;
                                        });
                                      }}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add More
                                    </Button>
                                  </Form.Item>

                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="float-left w-full">
                      <label>
                        Shipping Handling fee
                        <input
                          name="checkbox"
                          type="checkbox"
                          checked={checked}
                          onChange={() => {
                            setChecked(!checked);
                          }}
                        />
                      </label>
                    </div>
                    <div className="float-left w-full">
                      <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                        Adjust calculated rates to account for packaging and
                        handling costs
                      </p>
                    </div>
                    <div className="float-left w-full">
                      <div className="float-left w-1/2 p-2">
                        <Form.Item
                          hasFeedback
                          label="Percentage"
                          rules={[
                            {
                              required: false,
                              message:
                                "Type your country  Zone Name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Type your Shipping profile name"
                            disabled={!checked}
                          />
                        </Form.Item>
                      </div>
                      <div className="float-left w-1/2 p-2">
                        <Form.Item
                          hasFeedback
                          label="Flat Amount"
                          rules={[
                            {
                              required: false,
                              message:
                                "Type your country  Zone Name is Required!",
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Type your Shipping profile name"
                            disabled={!checked}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Card>

                  <div className="w-full mx-8 py-0">
                    <div className="float-left w-11/12 text-right">
                      <SubmitResetBtn
                        onClickReset={() => {
                          form?.resetFields();
                        }}
                        disabled={loading || !!error}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingRate;
