import { Avatar } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";

const SingleCategory = ({
  selectedCategoryId,
}: {
  selectedCategoryId: any;
}): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);

  const [categoryData, setCategoryData] = useState<any>(undefined);
  const fetchCategoryDetails = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/category/detail?categoryId=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            setCategoryData(res?.category);
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (selectedCategoryId) {
      fetchCategoryDetails(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  return (
    <>
      <div className="">
        <img
          src={categoryData?.bannerImage}
          alt=""
          className="shadow-md rounded-xl"
        />
        <div className="-mt-14">
          <Avatar
            size={100}
            src={categoryData?.image}
            className="rounded-xl shadow-lg border border-white ml-4"
          />
          <div className="flex flex-no-wrap py-2 mt-8">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Title</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{categoryData?.title}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Slug</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{categoryData?.slug}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">ID</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{categoryData?.id}</span>
              </div>
            </div>
          </div>
          {categoryData?.level ? (
            <div className="flex flex-no-wrap py-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">level</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>{categoryData?.level}</span>
                </div>
              </div>
            </div>
          ) : undefined}

          {categoryData?.position ? (
            <div className="flex flex-no-wrap py-2">
              <div className="w-2/5 flex-none">
                <div className="text-gray-500">
                  <span className="font-bold">position</span>
                </div>
              </div>
              <div className="w-2/5 flex-none">
                <div className="text-gray-700">
                  <span>{categoryData?.position}</span>
                </div>
              </div>
            </div>
          ) : undefined}

          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Description</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{categoryData?.description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCategory;
