import { Button, DatePicker, Empty, Form, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, OrderTypeArray } from "../../utils";
import { debounce } from "lodash";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../common/Loader";
import TopHeading from "./TopHeading";

const { RangePicker } = DatePicker;

const SalesReportList = () => {
  const [form] = Form.useForm();
  const loc = useLocation();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [hubId, setHubId] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [range, setRange] = useState<any>({
    fromDate: Date().toLocaleString(),
    toDate: Date().toLocaleString(),
  });

  const [salesData, setSalesData] = useState<any>({
    loading: false,
    data: null,
  });

  const [downloadData, setDownloadData] = useState<any>({
    loading: false,
    data: null,
  });

  const getSalesReport = useCallback(async () => {
    const fromDate = moment(range.fromDate)?.toISOString();
    const toDate = moment(range.toDate)?.toISOString();

    setSalesData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/finance/sales` +
          (fromDate ? `?fromDate=${fromDate}` : ``) +
          (toDate ? `&toDate=${toDate}` : ``) +
          (type ? `&type=${type}` : ``) +
          (status ? `&status=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSalesData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSalesData({ loading: false, data: [] });
        console.error("Data: Error", err);
      });
  }, [type, range, status]);

  const getSalesReportDownload = useCallback(async () => {
    const fromDate = moment(range.fromDate)?.toISOString();
    const toDate = moment(range.toDate)?.toISOString();

    setDownloadData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    fetch(
      `${encodedUri}/finance/sales/download?page=0` +
        (fromDate ? `&fromDate=${fromDate}` : ``) +
        (toDate ? `&toDate=${toDate}` : ``) +
        (type ? `&type=${type}` : ``) +
        (status ? `&status=${status}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((res) => {
        setDownloadData({ loading: false, data: res });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Sales_Report-${moment(range.fromDate).format("YYYY_MM_DD")}-${moment(
            range.to
          ).format("YYYY_MM_DD")}.xlsx`
        );
        link.click();
      })
      .catch((err) => {
        console.error("Data: Error", err);
      });
  }, [type, range, range.fromDate, range.toDate, status]);

  const fetchRef: any = useRef(null);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    getSalesReport();
  }, [getSalesReport]);

  const reseAllFieldData = () => {
    form.resetFields();
    setStatus("");
    setRange({
      fromDate: null,
      toDate: null,
    });
  };

  const paymentMethods = useMemo(() => {
    let allPayments: any = {};

    salesData?.data?.sales?.find((shop: any) => {
      if (shop?.methodAmount?.length > 0) {
        shop?.methodAmount.forEach((method: any) => {
          allPayments[method?.paymentMethod] = 1;
        });
      }
    });
    return Object.keys(allPayments);
  }, [salesData]);

  const formatedData = useMemo(() => {
    const obj: any = {};
    salesData?.data?.sales?.map((shop: any) => {
      const method: any = {};
      shop?.methodAmount?.map((item: any) => {
        method[item?.paymentMethod] = item?.total;
      });
      obj[shop?.shopName] = method;
    });
    return obj;
  }, [salesData]);

  const totalData = useMemo(() => {
    return (
      salesData?.data?.sales?.reduce((obj: any, data: any) => {
        const newObj: any = {};
        console.log(1, obj, data?.methodAmount);

        data?.methodAmount?.map((item: any) => {
          newObj[item?.paymentMethod] =
            (obj[item?.paymentMethod] || 0) + (item?.total || 0);
        });

        const onlyMethods = { ...obj, ...newObj };
        let allMethodsTotal = 0;
        Object.keys(onlyMethods)?.forEach((item) => {
          if (item !== "total") {
            allMethodsTotal += onlyMethods?.[item];
          }
        });

        return {
          ...onlyMethods,
          total: allMethodsTotal,
        };
      }, {}) || {}
    );
  }, [salesData]);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  return (
    <React.Fragment>
      <BreadCrumb
        title="Sales Order Report"
        subTitle={`${
          salesData?.data?.totalElements || salesData?.data?.sales?.length || 0
        } Order(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <TopHeading
            {...salesData?.data?.totalElements}
            refetch={getSalesReport}
            title={"Sales Report"}
            download={() => getSalesReportDownload()}
            dissable={!salesData?.data?.sales?.length ? true : false}
            loading={salesData?.loading}
          />,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  console.log("momnet", moment.utc(f[0])?.toISOString());
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>

            <Form.Item name="status_search">
              <Select
                showSearch
                placeholder="Filter by Status"
                onChange={(val) => setStatus(val)}
              >
                <Select.Option value="">ALL STATUS</Select.Option>
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Select.Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {salesData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Shop Name</th>
                      <th scope="col">COD</th>
                    </tr>
                  </thead>

                  <tbody>
                    {salesData?.data?.sales?.length ? (
                      salesData?.data?.sales?.map(
                        (salesData: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                {salesData?.shopName?.slice(0, 50)}
                              </span>
                            </td>
                            <td>
                              {paymentMethods?.map((pm) => {
                                return (
                                  <div className="single-product">
                                    <span className="name">
                                      {formatedData[salesData?.shopName][pm] ||
                                        0}
                                    </span>
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...salesData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </React.Fragment>
  );
};

export default SalesReportList;
