import React from "react";
import MainLayout from "../../components/Layouts";
import TpUpdateProductList from "../../components/Product/TpUpdateProducts";

const TpUpdateProductPage = () => {
  return (
    <MainLayout>
      <TpUpdateProductList />
    </MainLayout>
  );
};

export default TpUpdateProductPage;
