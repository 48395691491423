import { Avatar, Button, Card, Form, Input, Radio } from "antd";
import { Comment } from "@ant-design/compatible";

import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { SET_FEEDBACK_INFO } from "../../../redux/auth/authType";
import { responseNotification } from "../../../utils/notify";
import FeedbackLayout from "../Layout";
import { MinusOutlined, SendOutlined } from "@ant-design/icons";

const FeedbackDetails = ({ inOrder, orderId }): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const route = useParams();
  const [loading, setLoading] = useState<any>(false);
  const [visible, setVisible] = useState<any>(undefined);

  const [singleFeedbackInfo, setSingleFeedbackInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchFeedbackDetails = useCallback(
    (getFeedbackId) => {
      if (getFeedbackId) {
        try {
          setSingleFeedbackInfo({ loading: true, data: undefined });
          axios
            .get(
              `${process.env.REACT_APP_ORDER_API}/feedback/admin/details/${getFeedbackId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((data) => {
              if (data.status === 200) {
                setSingleFeedbackInfo({
                  loading: false,
                  data: data?.data?.feedback,
                });
                dispatch({
                  type: SET_FEEDBACK_INFO,
                  payload: {
                    feedbackInfo: {
                      customerImage:
                        data?.data?.feedback?.customer?.profilePicture,
                      customerName: data?.data?.feedback?.customer?.name,
                      customerContact:
                        data?.data?.feedback?.customer?.mobileNumber,
                      id: data?.data?.feedback?.id,
                      customerType: data?.data?.feedback?.customer?.type,
                      createdAt: data?.data?.feedback?.createdAt,
                    },
                  },
                });
              } else {
                setSingleFeedbackInfo({ loading: false, data: null });
                responseNotification(
                  data.statusText || "something went wrong",
                  "error"
                );
              }
            })
            .catch((err) => {
              setSingleFeedbackInfo({ loading: false, data: null });

              console.log(err);
            });
        } catch (error) {
          setSingleFeedbackInfo({ loading: false, data: null });

          console.log(error, "error");
        }
      }
    },
    [inOrder, orderId]
  );

  useEffect(() => {
    if (orderId) {
      fetchFeedbackDetails(orderId);
    } else {
      fetchFeedbackDetails((route as any)?.feedbackId);
    }
  }, [fetchFeedbackDetails, orderId]);

  const onReplySubmit = async (data) => {
    setLoading(true);

    await fetch(`${process.env.REACT_APP_ORDER_API}/feedback/admin/status`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        feedbackId: (route as any).feedbackId,
        note: data.note,
        status: data.status,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Reply send Successfully", "success");
          fetchFeedbackDetails((route as any)?.feedbackId);
          form.resetFields();
          setVisible(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const feedback = singleFeedbackInfo?.data;

  return (
    <React.Fragment>
      <FeedbackLayout
        feedbackId={(route as any)?.id as string}
        title="Feedback Details"
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-900">
                  Issue Date
                </dt>
                <dd className="text-sm font-semibold text-gray-500">
                  {moment(feedback?.createdAt).format("lll")}{" "}
                  <small
                    className={`text-capitalize ${
                      moment().diff(feedback?.createdAt, "minutes") >= 60
                        ? "font-weight-bold text-danger"
                        : "text-dark"
                    }`}
                  >
                    ({moment(feedback?.createdAt).fromNow()})
                  </small>
                </dd>
              </div>

              {feedback?.orderId && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">
                    Order ID
                  </dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    {feedback?.orderId}
                  </dd>
                </div>
              )}

              {feedback?.status && (
                <div>
                  <dt className="text-sm font-medium text-gray-900">Status</dt>
                  <dd className="text-sm font-semibold text-gray-500">
                    <span
                      className={`radius-btn ${feedback?.status?.toLowerCase()}`}
                    >
                      {feedback?.status?.split("_").join(" ")}
                    </span>
                  </dd>
                </div>
              )}
            </dl>

            <div className="">
              <div
                className="p-4 bg-slate-100 rounded-lg mt-4"
                style={{
                  height: 400,
                  overflowY: "scroll",
                }}
              >
                {feedback?.feedbacks?.map((log, i) => (
                  <Card
                    key={i}
                    style={{
                      maxWidth: "49%",
                    }}
                    className={`shadow_ mb-2 ${
                      log?.type === "ADMIN" ? "ml-auto" : "mr-auto"
                    }`}
                    size="small"
                    title={log?.feedbackTypeName}
                  >
                    {log?.textFeedback && <h6>Message: {log?.textFeedback}</h6>}
                    {log?.products?.length ? (
                      <>
                        <h6 className="mb-0">Items</h6>
                        <ul>
                          {log?.products?.map((product, i) => (
                            <li key={i}>
                              <MinusOutlined />{" "}
                              <small>{product?.productName}</small>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      ``
                    )}
                  </Card>
                ))}
                {feedback?.note && (
                  <Card
                    style={{
                      maxWidth: "49%",
                    }}
                    className={`shadow_ mb-4 ${"ml-auto"}`}
                    size="small"
                  >
                    <Comment content={feedback?.note} />
                  </Card>
                )}
              </div>

              {visible && (
                <div
                  style={{
                    boxShadow: "2px -3px 10px 0 rgba(0,0,0,0.05)",
                  }}
                  className="pr-5 pl-2"
                >
                  <Comment
                    avatar={
                      <Avatar
                        src="https://joeschmoe.io/api/v1/random"
                        alt="Han Solo"
                      />
                    }
                    content={
                      <Form onFinish={onReplySubmit}>
                        <Form.Item
                          name="note"
                          rules={[
                            {
                              required: true,
                              message: "Note is Required!",
                            },
                          ]}
                        >
                          <Input.TextArea rows={2} />
                        </Form.Item>
                        <div className="d-flex align-items-center justify-content-between">
                          <Form.Item
                            name="status"
                            rules={[
                              {
                                required: true,
                                message: "Status is Required!",
                              },
                            ]}
                            initialValue="PROCESSING"
                            className="mb-0"
                          >
                            <Radio.Group>
                              {/* <Radio value="PROCESSING">PROCESSING</Radio> */}
                              <Radio value="RESOLVED">RESOLVED</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <div className="">
                            <Button
                              type="dashed"
                              danger
                              onClick={() => setVisible(!visible)}
                              className="mr-2"
                            >
                              Cancel
                            </Button>
                            <Button htmlType="submit" type="primary">
                              <SendOutlined />
                              Reply Issue
                            </Button>
                          </div>
                        </div>
                      </Form>
                    }
                  />
                </div>
              )}

              {!visible && (
                <Button
                  type="primary"
                  danger
                  size="large"
                  onClick={() => setVisible(true)}
                  disabled={!!visible || feedback?.status === "RESOLVED"}
                  className="px-5 mt-4"
                >
                  {feedback?.status === "RESOLVED" ? "RESOLVED" : "REPLY"}
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* <FeedbackDetailsData inOrder={false} orderId={null} /> */}
      </FeedbackLayout>
    </React.Fragment>
  );
};
FeedbackDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default FeedbackDetails;
