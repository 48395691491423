import {
  AutoComplete,
  Avatar,
  Badge,
  Button,
  DatePicker,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
} from "antd";
import {
  ExclamationCircleOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, imageOrderStatus, jcTypeArray } from "../../utils";
import { debounce } from "lodash";
import styles from "../../styles/tailwind/List.module.css";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";
import React from "react";
import { PaginationThree, Loader } from "../common";
import SingleOrder from "./Details";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const RevenueHistoryList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [showSearch, setShowSearch] = useState(true);
  const [orderId, setOrderId] = useState("");
  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });
  const [revenueData, setRevenueData] = useState<any>({
    loading: false,
    data: null,
  });

  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order/v2?page=${page || 0}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            (type ? `&type=${type}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { baseOrderId: string; orderId: string }) => ({
                label: `${order?.baseOrderId} || ${order?.orderId}`,
                value: order?.orderId,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("orders: Error", err);
        });
    },
    [limit, page, type]
  );

  const getRevenue = useCallback(async () => {
    setRevenueData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/revenue-history?serviceType=${type}` +
          `&page=${page || 0}` +
          (orderId ? `&orderId=${orderId}` : ``) +
          (page
            ? `?page=${
                page == revenueData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRevenueData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setRevenueData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [limit, page, type, orderId]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);

  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getRevenue();
  }, [getRevenue]);

  const reseAllFieldData = () => {
    form?.resetFields();
    setType("");
    setOrderId("");
  };

  return (
    <>
      <BreadCrumb
        title="Order Revenue History"
        subTitle={`${revenueData?.data?.totalElements} Revenue(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
            <Form.Item name="baseOrderIdSearch">
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID or Base Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {revenueData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Total Revenue</th>
                      <th scope="col">Retained Revenue</th>
                      <th scope="col">Referral Subsidy</th>
                      <th scope="col">Delivery Charge Subsidy</th>
                      <th scope="col">Created Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {revenueData?.data?.revenueHistories?.length ? (
                      revenueData?.data?.revenueHistories?.map(
                        (revenue: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/orders/order-image/${revenue?.id}/details`
                              )
                            }
                          >
                            <td>
                              <span className="font-medium text-slate-700">
                                {revenue?.orderId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {revenue?.totalRevenue}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {revenue?.retainedRevenue}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {revenue?.referralSubsidy}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {revenue?.deliveryChargeSubsidy}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {revenue?.createdBy?.name ||
                                  revenue?.createdBy?.mobileNumber?.replace(
                                    "+88",
                                    ""
                                  )}
                                {" - "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    revenue?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(revenue?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(revenue?.createdAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...revenueData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default RevenueHistoryList;
