import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import {
  CommentOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import { getPage, getParamValue } from "../../utils";
import { debounce } from "lodash";
import Loader from "../common/Loader";
import LoaderFull from "../common/LoaderFull";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import BreadCrumb from "../Layouts/Breadcrumb";
import { SubmitResetBtn } from "../common";
import { useParams } from "react-router";
import { responseNotification } from "../../utils/notify";
const { RangePicker } = DatePicker;

const CompanyBlanceHistoryReport = ({
  inCorporate = false,
  getComanyId,
}: {
  inCorporate?: boolean;
  getComanyId?: string;
}) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const router = useLocation();
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const route = useParams();
  const [companyId, setCompanyId] = useState<any>(route?.corporateId);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [key, setKey] = useState(getParamValue(router.search, "key"));
  const [corporateOptions, setCorporateOptions] = useState<any>({
    loading: false,
    list: [],
  });
  const [blanceHistoryData, setBlanceHistoryData] = useState<any>({
    loading: false,
    data: null,
  });
  const [selectedBlanceApproved, setSelectedBlanceApproved] =
    useState<any>(undefined);

  const getCorporateOptions = useCallback(async (key?: string) => {
    setCorporateOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    const res = await axios.get(
      `${encodedUri}/company/all?` +
        (page ? `&page=${page}` : ``) +
        (limit ? `&limit=${limit}` : ``) +
        +(key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCorporateOptions({
      loading: false,
      list: res?.data?.companies?.map((company: any) => ({
        label: company.name,
        value: company.id,
      })),
    });
  }, []);

  const getCompanyBlanceHistoryData = useCallback(async () => {
    setBlanceHistoryData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/company/balance-history-for-admin?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBlanceHistoryData({ loading: false, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        setBlanceHistoryData({ loading: false, data: null });
        console.error("Promo: Error", err);
      });
  }, [companyId]);

  useEffect(() => {
    getCompanyBlanceHistoryData();
  }, [getCompanyBlanceHistoryData]);

  const reseAllFieldData = () => {
    form.resetFields();
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      balanceHistoryId: selectedBlanceApproved?.id,
      note: data.note,
    };
    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/transaction/cash-collection-from-company`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Approved Successfully", "success");
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "corporate") getCorporateOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCorporateOptions]);
  useEffect(() => {
    if (showSearch) {
      getCorporateOptions("");
    }
  }, [showSearch]);

  const fetchRef = useRef(0);
  const onClose = () => {
    form.resetFields();
    getCompanyBlanceHistoryData();
    setSelectedBlanceApproved(undefined);
  };

  const handleOk = () => {
    setSelectedBlanceApproved(false);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <BreadCrumb
        title="Company Balance History List"
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {!companyId && showSearch && (
        <div className={`${styles.searchBox} `}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Company"
                optionFilterProp="children"
                onChange={(val) => setCompanyId(val)}
                onSearch={(e) => handleSearch(e, "corporate")}
                filterOption={() => {
                  return true;
                }}
                options={corporateOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {blanceHistoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Account Name</th>
                      <th scope="col">Deposited Amount</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Transaction Reference</th>
                      <th scope="col">Picture Of Document</th>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Bank Account No</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {blanceHistoryData?.data?.companyBalanceHistoryList
                      ?.length ? (
                      blanceHistoryData?.data?.companyBalanceHistoryList?.map(
                        (blanceHistory: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.accountName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.depositedAmount}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.paymentMethod}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.transactionReference}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.pictureOfDocument}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.bankName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.bankAccountNo}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium">
                                {blanceHistory?.creditStatus}
                              </span>
                            </td>
                            <td>
                              {blanceHistory?.creditStatus === "Requested" ? (
                                <Button
                                  shape="round"
                                  type="primary"
                                  danger
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedBlanceApproved(blanceHistory);
                                  }}
                                >
                                  Approved
                                </Button>
                              ) : (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  {blanceHistory?.creditStatus}
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Modal
              title="Approved Blance"
              onCancel={onClose}
              open={selectedBlanceApproved}
              onOk={handleOk}
              width={700}
              centered
              footer={false}
            >
              <div>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="ant-form ant-form-vertical"
                  onFinish={onSubmit}
                  form={form} // like ref
                  layout="vertical"
                  autoComplete="off"
                >
                  <Form.Item
                    hasFeedback
                    label="Note"
                    name="note"
                    rules={[
                      {
                        required: true,
                        message: "Note is Required!",
                      },
                    ]}
                  >
                    <Input id="note" type="text" placeholder="Enter Note" />
                  </Form.Item>
                  <div className="buttons-container mt-3">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="add-submit-btn text-center mr-2"
                    >
                      Submit
                    </Button>
                    <Button
                      type="ghost"
                      htmlType="button"
                      onClick={() => form?.resetFields()}
                      className="reset-submit-btn text-center mr-2"
                    >
                      Reset
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyBlanceHistoryReport;
