import React from "react";
import MainLayout from "../../components/Layouts";
import MerchantAppVersion from "../../components/AppVersion/MerchantApp";

const MerchantAppPage = () => {
  return (
    <MainLayout>
      <MerchantAppVersion />
    </MainLayout>
  );
};

export default MerchantAppPage;
