import React from "react";
import MainLayout from "../../components/Layouts";
import ShippingPlace from "../../components/DeliveryShipping/ShippingPlace";

const ShippingPlacePage = () => {
  return (
    <MainLayout>
      <ShippingPlace />
    </MainLayout>
  );
};

export default ShippingPlacePage;
