import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CorporateLayout from "../Layout";
import { getPage, getParamValue } from "../../../utils/index";
import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Empty, Form, Pagination, Tabs } from "antd";
import AddEmployeeGroup from "./AddEmployeeGroup";
import styles from "../../../styles/tailwind/List.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { Loader, LoaderFull } from "../../common";
const { TabPane } = Tabs;

const CorporateEmployeeGroupList = ({ params }: any) => {
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const companyId = corporateInfo?.ownerId;
  const route = useParams();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedGroupForEdit, setSelectedGroupForEdit] =
    useState<any>(undefined);

  const [groupData, setGroupData] = useState<any>({
    loading: false,
    data: null,
  });
  const getEmployeeGroup = useCallback(async () => {
    setGroupData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/company/admin/all?companyId=${companyId}` +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGroupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setGroupData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [companyId, limit, page]);

  useEffect(() => {
    getEmployeeGroup();
  }, [getEmployeeGroup]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(undefined);
    setSelectedGroupForEdit(!setSelectedGroupForEdit);
    form.resetFields();
    getEmployeeGroup();
  };

  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={(route as any)?.corporateId as string}
        title="Employee Group List"
        subTitle="Employee Group List"
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
            <Tabs
              tabPosition="top"
              tabBarExtraContent={
                <Button type="primary" danger onClick={showDrawer}>
                  Add New
                </Button>
              }
            >
              <TabPane tab="CORPORATE DETAILS" key="General">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
                      {confirmLoading && <LoaderFull />}
                      {groupData?.loading ? (
                        <Loader />
                      ) : (
                        <table className={styles.mainTable}>
                          <thead className="bg-white border-b">
                            <tr>
                              <th scope="col">Group Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {groupData?.data?.groups?.length ? (
                              groupData?.data?.groups?.map(
                                (group: any, index: any) => (
                                  <tr
                                    className="border-t hover:bg-gray-100 cursor-pointer"
                                    key={index}
                                  >
                                    <td className="flex gap-2 items-center">
                                      <span className="name">
                                        {group?.groupName}
                                      </span>
                                    </td>

                                    <td>
                                      <span className="name">
                                        {group?.status}
                                      </span>
                                    </td>

                                    <td>
                                      <Link
                                        to=""
                                        className="btn btn-sm btn-warning mx-1"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedGroupForEdit(group);
                                        }}
                                      >
                                        <EditOutlined />
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>
                                  <Empty />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
                <Drawer
                  destroyOnClose={true}
                  title="Add Group"
                  width={500}
                  onClose={onClose}
                  open={!!visible || selectedGroupForEdit}
                  bodyStyle={{ paddingBottom: 0 }}
                  footer={
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    ></div>
                  }
                >
                  <AddEmployeeGroup
                    visibleData={selectedGroupForEdit}
                    onCloseMethod={onClose}
                  />
                </Drawer>
                <Pagination
                  {...groupData?.data}
                  limit={limit}
                  page={getPage(loc.search)}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateEmployeeGroupList;
