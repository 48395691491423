import React from "react";
import MainLayout from "../../components/Layouts";
import ShippingProfile from "../../components/DeliveryShipping/ShippingProfile";

const ShippingProfilePage = () => {
  return (
    <MainLayout>
      <ShippingProfile />
    </MainLayout>
  );
};

export default ShippingProfilePage;
