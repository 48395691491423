import React from "react";
import MainLayout from "../../components/Layouts";
import BagList from "../../components/Bags/index";

const BagPage = () => {
  return (
    <MainLayout>
      <BagList />
    </MainLayout>
  );
};

export default BagPage;
