import { Image, Tag } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleMenu = ({ MenuDetails }: { MenuDetails: any }): ReactElement => {
 
  return (
    <>
      <div className="">
 
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Menu Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{MenuDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Menu URL</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{MenuDetails?.url}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Sub Menu List</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <div className="flex_">
                {MenuDetails?.subMenus?.length
                  ? MenuDetails?.subMenus?.map(
                      (subMenu: any, subIndex: string) => (
                        <React.Fragment key={subIndex}>
                          <div className="flex justify-content-start mb-1">
                            <Tag color={`green`}>{subMenu.name}</Tag>
                          </div>
                        </React.Fragment>
                      )
                    )
                  : undefined}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleMenu;
