import React from "react";
import BusinessTypeList from "../../components/BusinessType/index";
import MainLayout from "../../components/Layouts";

const BusinessTypePage = () => {
  return (
    <MainLayout>
      <BusinessTypeList />
    </MainLayout>
  );
};

export default BusinessTypePage;
