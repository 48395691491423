import { Button, Form, message, Upload } from "antd";
import React, { useRef, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { getImgUrl } from "../../utils/index";
import BreadCrumb from "../Layouts/Breadcrumb";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";

interface FileType {
  originFileObj: any;
  uid: string;
  name: string;
  status: string;
  url: string;
}

const FileManager = () => {
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [generatedUrl, setGeneratedUrl] = useState();

  const textRef = useRef<any>(null);
  const [form] = Form.useForm();

  const onChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("frontImage", fileList?.[0]?.originFileObj);
    formData.append("documentType", "static_image");

    await fetch(`${process.env.REACT_APP_RIDER_API}/admin/document/upload`, {
      method: "POST",

      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setGeneratedUrl(res.url);
        setFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const success = () => {
    message.success("Image URL Copied!");
  };

  return (
    <React.Fragment>
      <BreadCrumb title={`File Manager`} />

      <div className={styles.contentWrapper}>
        <div className="flex items-start p-8 pb-0">
          <div className="mr-20">
            <Form form={form}>
              <Form.Item name="image">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  onChange={onChange}
                  onPreview={onPreview}
                  multiple
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </Form.Item>
            </Form>
          </div>

          {generatedUrl && (
            <div className="flex items-start">
              <div className="flex items-center d-flex-nowrap">
                <img
                  src={getImgUrl(generatedUrl)}
                  alt=""
                  className="mr-2 rounded-md h-28"
                />
                <small ref={textRef}>{generatedUrl}</small>
              </div>
              <Button
                type="dashed"
                onClick={() => {
                  navigator.clipboard.writeText(textRef?.current?.innerText);
                  success();
                }}
              >
                <CopyOutlined title="Copy" />
              </Button>
            </div>
          )}
        </div>

        <div className="px-8 pb-8">
          <Button
            onClick={() => uploadImage()}
            disabled={!fileList.length}
            type="dashed"
            shape="round"
            danger
            className="px-7"
          >
            Upload
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileManager;
