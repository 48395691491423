import React from "react";
import MainLayout from "../../components/Layouts";
import BookList from "../../components/Books/index";

const ProductPage = () => {
  return (
    <MainLayout>
      <BookList />
    </MainLayout>
  );
};

export default ProductPage;
