import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Empty, Switch } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import React from "react";

const ContentList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);

  const [contentData, setContentData] = useState<any>({
    loading: false,
    data: null,
  });

  const getContents = useCallback(async () => {
    setContentData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/web-content?type=${type}` +
          (status ? `&deleted=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setContentData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setContentData({ loading: false, data: [] });
        console.error("Contents: Error", err);
      });
  }, []);

  //
  const onDelete = async (id: any, val: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/web-content`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: !val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getContents();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getContents();
  }, [getContents]);


  return (
    <>
      <BreadCrumb
        title="Content List"
        subTitle={`${
          contentData?.data?.totalElements ||
          contentData?.data?.webContentResponses?.length ||
          0
        } Content(s)`}
        extra={[
          <AddBtn
            onClick={() => {
              navigate(`/contents/create`);
            }}
            key={1}
          />,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {contentData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Content Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {contentData?.data?.webContentResponses?.length ? (
                      contentData?.data?.webContentResponses?.map(
                        (content: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() => setSelectedContent(content)}
                            onClick={() =>
                              navigate(`/contents/${content?.slug}`)
                            }
                          >
                            <td>
                              <Avatar size={40} src={content?.thumbnail} />{" "}
                              <span className="font-medium text-gray-500 ml-2">
                                {content?.title}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {content?.contentType}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!content?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(content?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(`/contents/${content?.slug}/edit`);
                                }}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...contentData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default ContentList;
