import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import SingleImaheOrder from "../../components/OrdersImage/Details";
import React from "react";

const SingleImageOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <SingleImaheOrder />
    </MainLayout>
  );
};

export default SingleImageOrderPage;
