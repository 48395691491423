import { Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { responseNotification } from "../../utils/notify";
import SubmitResetBtn from "../common/SubmitBtn";
import styles from "../../styles/tailwind/Add.module.css";
import { useSelector } from "react-redux";
import React from "react";

const QuickUpdate = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState<any>(undefined);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      id: visibleData?.id,
      displayOrder: data.displayOrder,
      excludeFromPromo: data.excludeFromPromo || false,
      // isActive: data.isActive || true,
      metaDescription: data.metaDescription,
      metaTitle: data.metaTitle,
    };

    if (productData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/merchant/product`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            merchantProductId: productData?.id,
            deleted: false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Product Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setProductData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (productData) {
      form.resetFields();
    }
  }, [productData, form, visibleData]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...productData,
        }}
      >
        <Form.Item hasFeedback label="Meta Title" name="metaTitle">
          <Input.TextArea id="metaTitle" placeholder="Enter Meta Title" />
        </Form.Item>

        <Form.Item hasFeedback label="Meta Description" name="metaDescription">
          <Input.TextArea
            rows={5}
            id="metaDescription"
            placeholder="Enter Meta Description"
          />
        </Form.Item>

        <Form.Item hasFeedback label="Display Order" name="displayOrder">
          <Input
            id="displayOrder"
            type="number"
            min={0}
            placeholder="Enter Display Order"
          />
        </Form.Item>

        <div className="grid grid-cols-2 gap-4">
          {/* <Form.Item hasFeedback label="Status" name="isActive">
            <Switch
              checkedChildren={`Active`}
              unCheckedChildren={`Inactive`}
              defaultChecked={productData?.data?.isActive}
            />
          </Form.Item> */}

          <Form.Item
            hasFeedback
            label="Exclude From Promo?"
            name="excludeFromPromo"
          >
            <Switch
              checkedChildren={`Exclude`}
              unCheckedChildren={`Include`}
              defaultChecked={productData?.data?.excludeFromPromo}
            />
          </Form.Item>
        </div>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default QuickUpdate;
