import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, useCallback, useEffect, useState } from "react";
  import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation, useNavigate } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
  const DefaultDeliveryConfigList = (): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const navigate = useNavigate();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const [showSearch, setShowSearch] = useState(true);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedZone, setSelectedZone] = useState(undefined);
    const [selectedZoneForEdit, setSelectedZoneForEdit] = useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [deliveryConfigData, setDeliveryConfigData] = useState<any>({
      loading: false,
      data: null,
    });
  
    const getDeliveryConfig = useCallback(async () => {
        setDeliveryConfigData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/default-delivery-country-zone-config?page=0&limit=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            setDeliveryConfigData({ loading: false, data: res.data });
        })
        .catch((err) => {
            setDeliveryConfigData({ loading: false, data: [] });
          console.error("zones: Error", err);
        });
    }, []);
  
    //
    useEffect(() => {
        getDeliveryConfig();
    }, [getDeliveryConfig]);
  
    const onClose = () => {
        getDeliveryConfig();
      setVisible(undefined);
      setSelectedZone(undefined);
      setSelectedZoneForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
  
    return (
      <>
        <BreadCrumb
          title="Default Delivery Zone List"
          subTitle={`${
            deliveryConfigData?.data?.totalElements ||
            deliveryConfigData?.data?.deliveryCountryZones?.length ||
            0
          } Zone(s)`}
          extra={[
            <AddBtn onClick={() => navigate("/configurations/default-delivery-config-create")} key={2} />,
          ]}
        />
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {deliveryConfigData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {deliveryConfigData?.data?.deliveryCountryZones?.length ? (
                        deliveryConfigData?.data?.deliveryCountryZones?.map(
                          (zone: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedZone(zone)}
                            > 
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {zone?.id}
                                </span>
                              </td>
                              <td>
                               okk
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(zone?.updatedAt, "minutes") >=
                                    60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(zone?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(zone?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {zone?.updatedBy?.name ||
                                    zone?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedZoneForEdit(zone)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                  
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...deliveryConfigData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  

      </>
    );
  };
  
  export default DefaultDeliveryConfigList;
  