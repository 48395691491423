import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios";

const AddCourseSyllabus = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  
  const [programId, setProgramId] = useState<any>("");
  const [courseId, setCourseId] = useState<any>("");
  const [teacherId, setTeacherId] = useState<any>("");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [courseSyllabusData, setCourseSyllabusData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");
  const [programOptions, setProgramOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [courseOptions, setCourseOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [teacherOptions, setTeacherOptions] = useState<any>({
    list: [],
    loading: false,
  });
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name, 
      description: convertedText || data.description,
      programId: programId || data.programId,
      courseId: courseId || data.courseId,
      teacherId: teacherId || data.teacherId,
    };

    if (courseSyllabusData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course-syllabus`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: courseSyllabusData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Course Syllabus Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course-syllabus`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Course Syllabus Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setCourseSyllabusData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (courseSyllabusData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [courseSyllabusData, form, visibleData]);


  const getProgramOptions = useCallback(async (programId?: string) => {
    setProgramOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/program?page=0&limit=20` +
        (programId ? `&name=${programId}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => {
        return {
          value: program?.id,
          label: program?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getProgramOptions();
  }, [getProgramOptions]);


  const getCourseOptions = useCallback(async (key?: any) => {
    setCourseOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/course?page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCourseOptions({
      loading: false,
      list: res?.data?.courses?.map((course: any) => {
        return {
          value: course?.id,
          label: course?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getCourseOptions();
  }, [getCourseOptions]);

  const getTeacherOptions = useCallback(async (key?: any) => {
    setTeacherOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/teacher?page=0&limit=20` +
        (key ? `&fullName=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTeacherOptions({
      loading: false,
      list: res?.data?.teachers?.map((teacher: any) => {
        return {
          value: teacher?.id,
          label: teacher?.fullName,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getTeacherOptions();
  }, [getTeacherOptions]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "program") getProgramOptions(value);
          if (field === "course") getCourseOptions(value);
          if (field === "teacher") getTeacherOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getProgramOptions,getCourseOptions,getTeacherOptions, type]);
  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...courseSyllabusData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item hasFeedback label="Description" name="description">
          <JoditEditor
            ref={editor}
            value={convertedText}
            config={jodiConfig as any}
            onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Form.Item>
        <div className="">
          <Form.Item
            hasFeedback
            label="Program"
            rules={[
              {
                required: true,
                message: "Program is required!",
              },
            ]}
            name="programId"
          >
            <Select
              showSearch
              placeholder="Select Program"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "program");
              }}
              onChange={(val) => setProgramId(val)}
              filterOption={() => {
                return true;
              }}
              options={programOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Course"
            rules={[
              {
                required: true,
                message: "Course is required!",
              },
            ]}
            name="courseId"
          >
            <Select
              showSearch
              placeholder="Select Course"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "course");
              }}
              onChange={(val) => setCourseId(val)}
              filterOption={() => {
                return true;
              }}
              options={courseOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Teacher"
            rules={[
              {
                required: true,
                message: "Teacher is required!",
              },
            ]}
            name="teacherId"
          >
            <Select
              showSearch
              placeholder="Select Teacher"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "teacher");
              }}
              onChange={(val) => setTeacherId(val)}
              filterOption={() => {
                return true;
              }}
              options={teacherOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="mt-2">
          <SubmitResetBtn
            onClickReset={() => {
              form?.resetFields();
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </div>
      </Form>
    </>
  );
};
export default AddCourseSyllabus;
