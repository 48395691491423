import React from "react";
import CountryList from "../../components/Country";
import MainLayout from "../../components/Layouts";

const CountryPage = () => {
  return (
    <MainLayout>
      <CountryList />
    </MainLayout>
  );
};

export default CountryPage;
