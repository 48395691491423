import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  SwapOutlined,
  DownOutlined,
  DeleteOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Skeleton,
  Switch,
} from "antd";
import axios from "axios";
import { responseNotification } from "../../utils/notify";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddBrand from "./AddBrand";
import { debounce } from "lodash";
import moment from "moment";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { isAllowedService } from "../../utils/services";
import _ from "lodash";
import { AddBtn, Loader, Pagination } from "../common";
const BrandList = ({
  inCampaign = false,
  inShop = false,
  getCampaignId,
  getShopId,
}: {
  inCampaign?: boolean;
  inShop?: boolean; 
  getCampaignId?: string;
  getShopId?: string;
}): ReactElement => {
  const navigate = useNavigate();
  console.log("okey get", inShop);
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedBrandForEdit, setSelectedBrandForEdit] = useState(undefined);
  const [brandId, setBrandId] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("displayOrder");
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const isDeleted = "false";
  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });
  const [brandData, setBrandData] = useState<any>({
    loading: false,
    data: null,
  });

  const getBrands = useCallback(async () => {
    setBrandData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/brand${
          inCampaign ? "/by-campaign" : inShop ? "/by-shop" : ``
        }?type=${type}` +
          (getCampaignId ? `&campaignId=${getCampaignId}` : ``) +
          (getShopId ? `&shopId=${getShopId}` : ``) +
          (brandId ? `&key=${brandId}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (isDeleted ? `&deleted=${isDeleted}` : ``) +
          (page
            ? `&page=${
                page == brandData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBrandData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setBrandData({ loading: false, data: [] });
        console.error("Brand: Error", err);
      });
  }, [
    type,
    getCampaignId,
    getShopId,
    brandId,
    sortBy,
    sortOrder,
    isActive,
    isDeleted,
    limit,
    page,
  ]);

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  const onClose = () => {
    getBrands();
    setVisible(undefined);
    setSelectedBrand(undefined);
    setSelectedBrandForEdit(undefined);
    setReArrangeDrawer(undefined);
  };

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.name,
      })),
    });
  }, []);

  const onDelete = async (id: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          //setConfirmLoading(undefined);
          if (res.statusCode === 200) {
            responseNotification("Brand Deleted Successfully", "success");
            setVisible(undefined);
            // setVisiblePopconfirm(false);
            getBrands();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getBrands();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    brandData?.data?.brands?.find((brand: any, i: string) => {
      if (brand?.id === node?.key) {
        curr = i;
        item = brand;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? brandData?.data?.brands
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : brandData?.data?.brands?.slice(0, pos),
        item,
        curr > pos
          ? brandData?.data?.brands
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, brandData?.data?.brands?.length - 1)
          : brandData?.data?.brands?.slice(pos, brandData?.data?.brands?.length)
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/brand/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: formated,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getBrands();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onBrandMoveToTop = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/brand/update-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: [
              {
                id: id,
                isSendTop: true,
              },
            ],
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Brand Move To Top Successfully", "success");
            getBrands();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onBrandRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/brand`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Brand Remove Successfully", "success");
            getBrands();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "brand") getBrandsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getBrandsOptions]);

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  const reseAllFieldData = () => {
    setBrandId("");
    setKey("");
    setSortBy("");
    setSortOrder("");
    setIsActive("");
    setType("");
    form.resetFields();
  };
  useEffect(() => {
    if (showSearch) {
      getBrandsOptions("");
    }
  }, [showSearch]);
  return (
    <>
      <BreadCrumb
        title={`Brand List`}
        subTitle={`${
          brandData?.data?.totalElements || brandData?.data?.brands?.length || 0
        } Brand(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,
          !inCampaign && !inShop && (
            <AddBtn onClick={() => setVisible(true)} key={2} />
          ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>          
            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }} 
                options={brandOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {brandData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Brand</th>
                      <th scope="col">Total Product</th>
                      <th scope="col">Total Sell</th>
                      {!inCampaign && !inShop && (
                        <>
                          <th scope="col">Status</th>
                          <th scope="col">Move TO Top</th>
                          <th scope="col">Action</th>
                          <th scope="col">Created Info</th>
                          <th scope="col">Updated Info</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {brandData?.data?.brands?.length ? (
                      brandData?.data?.brands?.map((brand: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() =>
                            navigate(`/brands/${brand?.id}/products`)
                          }
                          draggable="true"
                        >
                          <td>
                            <Avatar size={40} src={brand?.logo} />{" "}
                            <span className="font-medium text-gray-500 ml-2">
                              {brand?.name}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {brand?.numberOfProducts}
                            </span>
                          </td>
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {brand?.sellCount}
                            </span>
                          </td>
                          {!inCampaign && !inShop && (
                            <>
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={brand?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(brand?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    brand?.id === confirmLoading
                                  }
                                />
                              </td>

                              <td>
                                <Button
                                  shape="round"
                                  type="primary"
                                  danger
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBrandMoveToTop(brand?.id);
                                  }}
                                >
                                  <ToTopOutlined />
                                </Button>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedBrandForEdit(brand);
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                  {isAllowedService(`Brands Delete`) && (
                                    <Popconfirm
                                      placement="left"
                                      title="Are you sure to remove?"
                                      visible={brand?.id === visiblePopconfirm}
                                      onConfirm={(e: any) => {
                                        console.log(true);
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onBrandRemove(brand?.id);
                                      }}
                                      okButtonProps={{
                                        loading: confirmLoading,
                                        className: "bg-blue-400",
                                      }}
                                      onCancel={handleCancel}
                                    >
                                      <Button
                                        className="bg-white"
                                        type="dashed"
                                        danger
                                        shape="round"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          showPopconfirm(brand?.id);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  )}
                                  {/* <Popconfirm
                              placement="left"
                              title="Are you sure to delete?"
                              visible={brand?.id === visiblePopconfirm}
                              onConfirm={(e: any) => {
                                console.log(true);
                                e.preventDefault();
                                e.stopPropagation();
                                onDelete(brand?.id);
                              }}
                              okButtonProps={{
                                loading: confirmLoading,
                                className: "bg-blue-400",
                              }}
                              onCancel={handleCancel}
                            >
                              <Button
                                className="mx-1"
                                type="dashed"
                                danger
                                shape="round"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  showPopconfirm(brand?.id);
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm> */}
                                </div>
                              </td>
                              <td>
                                <small className="font-bold uppercase text-gray-500">
                                  {brand?.createdBy?.name ||
                                    brand?.createdBy?.mobileNumber}{" "}
                                  -{" "}
                                </small>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                      brand?.createdAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(brand?.createdAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(brand?.createdAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <small className="font-bold uppercase text-gray-500">
                                  {brand?.updatedBy?.name ||
                                    brand?.updatedBy?.mobileNumber}{" "}
                                  -{" "}
                                </small>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                      brand?.updatedAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(brand?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(brand?.updatedAt).format("lll")}
                                </span>
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...brandData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
      <Drawer
        title="Re Arrange Shop Tag"
        placement="right"
        onClose={onClose}
        open={reArrangeDrawer}
        width={450}
      >
        {brandData?.loading ? (
          <Skeleton />
        ) : brandData?.data?.brands?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={brandData?.data?.brands?.map((brand: any) => ({
              title: brand?.name,
              key: brand?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>
      <Drawer
        title={
          visible
            ? "Add Brand"
            : selectedBrandForEdit
            ? "Edit Brand"
            : "Brand Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBrand || selectedBrandForEdit}
        width={450}
      >
        {visible || selectedBrandForEdit ? (
          <AddBrand
            onCloseMethod={onClose}
            visibleData={selectedBrandForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>
    </>
  );
};

export default BrandList;
