import React from "react";
import CountryCityList from "../../components/City";
import MainLayout from "../../components/Layouts";

const CountryCityPage = () => {
  return (
    <MainLayout>
      <CountryCityList />
    </MainLayout>
  );
};

export default CountryCityPage;
