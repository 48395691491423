import {
  AutoComplete,
  Button,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
  Tag,
} from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray, tpUpdateStatus } from "../../utils";
import { debounce } from "lodash";
import styles from "../../styles/tailwind/List.module.css";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";
import React from "react";
import { PaginationThree, Loader } from "../common";
const { confirm } = Modal;

const TpUpdateProductList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type); 
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [showSearch, setShowSearch] = useState(true);

  const [orderOptions, setOrderOptions] = useState({
    loading: false,
    list: [],
  });
  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const getTpUpdateProduct = useCallback(async () => {
    setProductData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/product-tp-update/requests` +
          `?page=${page || 0}` +
          (page
            ? `?page=${
                page == productData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (orderId ? `&orderId=${orderId}` : ``) +
          (type ? `&serviceType=${type}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setProductData({ loading: false, data: [] });
        console.error("product: Error", err);
      });
  }, [limit, page, orderId, type]);
  const onStatusChange = async (productTpUpdateId: any) => {
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/product-tp-update/approve?productTpUpdateId=${productTpUpdateId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({
        //   productTpUpdateId: productTpUpdateId,
        // }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          getTpUpdateProduct();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(productTpUpdateId: any) {
    confirm({
      title: "Do you want to approved status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(productTpUpdateId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
      okButtonProps: {
        danger: true,
      },
    });
  }
  const fetchRef = useRef(0);

  useEffect(() => {
    getTpUpdateProduct();
  }, [getTpUpdateProduct]);
  const getOrderOptions = useCallback(
    async (getOrderId: any) => {
      setOrderOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order/v2?page=${page || 0}` +
            (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderOptions({
            loading: false,
            list: res.data?.orders?.map(
              (order: { baseOrderId: string; orderId: string }) => ({
                label: `${order?.baseOrderId} || ${order?.orderId}`,
                value: order?.orderId,
              })
            ),
          });
        })
        .catch((err) => {
          setOrderOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status, type]
  );
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getOrderOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getOrderOptions]);
  useEffect(() => {
    if (showSearch) {
      getOrderOptions("");
    }
  }, [showSearch]);
  const reseAllFieldData = () => {
    form?.resetFields();
    setOrderId("");
    setType("");
  };

  return (
    <>
      <BreadCrumb
        title="TP Update Product List"
        subTitle={`${productData?.data?.totalElements} Product(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
          <Form.Item initialValue={type}>
                    <Select
                      placeholder="Shop Type"
                      onChange={(val) => setType(val)}
                      value={type}
                      style={{ width: 250 }}
                    >
                      {Object.values(jcTypeArray)?.map((type, i) => (
                        <Option value={type} key={i}>
                          {type.replace("_", " ")}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
            <Form.Item name="baseOrderIdSearch">
              <AutoComplete
                backfill
                allowClear
                onSearch={(e) => handleSearch(e, "order")}
                onSelect={(val: any) => {
                  setOrderId(val);
                }}
                options={orderOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  orderOptions?.loading ? <Spin size="small" /> : null
                }
                dropdownMatchSelectWidth={335}
              >
                <Input.Search
                  placeholder="Order ID or Base Order ID"
                  onSearch={(val) => {
                    setOrderId(val);
                  }}
                  enterButton
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {productData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Current TP </th>
                      <th scope="col">Requested TP</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Requested By</th>
                      <th scope="col">Approved By</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.productTpUpdateList?.length ? (
                      productData?.data?.productTpUpdateList?.map(
                        (products: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-slate-700">
                                {products?.orderId}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-slate-700">
                                {products?.product?.name}
                              </span>
                            </td>
                            <td>
                              <div className="flex_">
                                {products?.product?.variations?.length
                                  ? products?.product?.variations?.map(
                                      (variant: any, index: string) => (
                                        <React.Fragment key={index}>
                                          <div className="flex justify-content-start mb-1">
                                            <Tag>
                                              TP {variant?.price?.tp}
                                              {variant?.price?.currencyCode}
                                            </Tag>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )
                                  : undefined}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <Tag>TP {parseInt(products?.newTp)} </Tag>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    products?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(products?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(products?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {products?.requestedBy?.name ||
                                  products?.requestedBy?.mobileNumber?.replace(
                                    "+88",
                                    ""
                                  )}
                                {" - "}
                              </small>

                              <br />
                              <span className="name">
                                {products?.requestedBy?.type
                                  ?.split("_")
                                  .join(" ")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {products?.approvedBy?.name ||
                                  products?.approvedBy?.mobileNumber?.replace(
                                    "+88",
                                    ""
                                  )}
                                {" - "}
                              </small>

                              <br />
                              <span className="name">
                                {products?.approvedBy?.type
                                  ?.split("_")
                                  .join(" ")}
                              </span>
                            </td>

                            <td>
                              {products?.status === "REQUESTED" ? (
                                <Dropdown.Button
                                  className="bg-white"
                                  overlay={
                                    <Menu>
                                      {Object?.values(tpUpdateStatus)?.map(
                                        (status) => (
                                          <Menu.Item
                                            key={status}
                                            onClick={(val) => {
                                              //showConfirm(val?.key);
                                              showConfirm(products?.id);
                                            }}
                                          >
                                            {status?.split("_").join(" ") ||
                                              "Status"}
                                          </Menu.Item>
                                        )
                                      )}
                                    </Menu>
                                  }
                                >
                                  {products?.status?.split("_").join(" ") ||
                                    "Status"}
                                </Dropdown.Button>
                              ) : (
                                <span className="border p-1 px-5 rounded-2xl bg-white">
                                  {products?.status}
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default TpUpdateProductList;
