import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import OrderImageList from "../../components/OrdersImage";
import React from "react";

const OrderImagePage = (): ReactElement => {
  return (
    <MainLayout>
      <OrderImageList />
    </MainLayout>
  );
};

export default OrderImagePage;
