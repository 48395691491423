import { Alert, Divider, Form, Input, Select, Switch } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../utils";

const AddPickupZone = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [countryId, setCountryId] = useState<any>('');
  const [stateId, setStateId] = useState<any>();
  const [cityId, setCityId] = useState<any>([]);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [pickupZoneData, setPickupZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const [pickupZoneType, setPickupZoneType] = useState<any>(null);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState({
    loading: false,
    list: [],
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);


  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
        name: data.name,
        countryId: countryId,
        stateId: stateId,
        cityIds: cityId, 
    };
    if (pickupZoneData) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/pickup-zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: pickupZoneData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Pickup Zone Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ORDER_API}/pickup-zone/add-zone-v2`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Pickup Zone Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };


  const getCountryOptions = useCallback(async (countryName?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?page=0&limit=20` +
        (countryName ? `&key=${countryName}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });

  }, []);
  const getStateOptions = useCallback(
    async (key: any) => {
      setStateOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (countryId ? `&countryId=${countryId}` : ``)+
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/states${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStateOptions({
            loading: false,
            list: res.data?.states?.map((state:any) => ({
              label: state.name,
              value: state.id,
            })),
          });
        })
        .catch((err) => {
          setStateOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [countryId,key]
  );

  const getCityOptions = useCallback(
    async (key: any) => {
        setCityOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` +
        (stateId ? `&stateId=${stateId}` : ``)+
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/country-state/cities${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
            setCityOptions({
            loading: false,
            list: res.data?.cities?.map((city:any) => ({
              label: city.name,
              value: city.id,
            })),
          });
        })
        .catch((err) => {
            setCityOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [stateId,key]
  );



  useEffect(() => {
    if (visibleData) {
      setPickupZoneData(visibleData);
      setIsActive(visibleData?.isActive === "true");
    }
  }, [visibleData]);

  useEffect(() => {
    if (pickupZoneData) {
        form.resetFields(Object.keys(pickupZoneData));
        form.resetFields();
     // setCountryId(pickupZoneData?.countryId);
     setCountryOptions({
        loading: false,
        list: [
          {
            value: pickupZoneData?.country?.id,
            label: pickupZoneData?.country?.name,
          },
          ...(countryOptions.list?.filter(
            (item) => item.value !== pickupZoneData?.country?.id
          ) || []),
        ],
      });
    }
  }, [pickupZoneData, form]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
        if (field === "state") getStateOptions(value);
        if (field === "city") getCityOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions,getStateOptions,getCityOptions]);
  useEffect(() => {
    getCountryOptions("");
    getStateOptions("");
    getCityOptions("");
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
  ]);


  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...pickupZoneData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Pickup Zone Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Pickup Zone Name" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`Country`}
          rules={[
            {
              required: true,
              message: "Region is required!",
            },
          ]}
          name="businessCountry"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={(val) => setCountryId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "country");
            }}
            filterOption={() => {
              return true;
            }}
            options={countryOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          name="state_search"
          label="State"
        >
          <Select
            allowClear
            showSearch
            placeholder={`Filter by State`}
            optionFilterProp="children"
            onChange={(val) => setStateId(val)}
            onSearch={(e) => handleSearch(e, "state")}
            filterOption={() => {
              return true;
            }}
            options={stateOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`City`}
          rules={[
            {
              required: false,
              message: "City is required!",
            },
          ]}
          name="businessCity"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select City"
            optionFilterProp="children"
            onChange={(val) => setCityId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "city");
            }}
            filterOption={() => {
              return true;
            }}
            mode="multiple"
            options={cityOptions?.list}
          ></Select>
        </Form.Item>

        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddPickupZone;
