import { AutoComplete, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getParamValue } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";

import BreadCrumb from "../Layouts/Breadcrumb";

const PushNotifications = () => {
  const router = useLocation();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [notificationType, setNotificationType] = useState("NONE");
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [typeId, setTypeId] = useState<any>();
  const [brandSlug, setBrandSlug] = useState<any>();

  const [key, setKey] = useState("");

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      title: data.title,
      message: data.message,
      image: generatedImgUrl || data.image,
      serviceType: type,
      type: notificationType,
      typeId: typeId,
    };
    await fetch(
      `${process.env.REACT_APP_NOTIFICATION_API}/notification/topic`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Notification Sent Successfully", "success");
          form.resetFields();
          setGeneratedImgUrl(undefined);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const resetData = () => {
    form?.resetFields();
  };

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url = `?type=${type}&page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/search${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any; slug: any }) => ({
              label: product.name,
              value: product.slug,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.slug,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.slug,
      })),
    });
  }, []);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign/by-type?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.slug,
      })),
    });
  }, []);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions, getCampaignOptions]);
  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
      getCampaignOptions();
    }
  }, [showSearch]);

  return (
    <React.Fragment>
      <BreadCrumb title="Send Notification" />
      <div>
        <Row
          gutter={25}
          align="middle"
          justify="center"
          className="product-table"
        >
          <Col span={12}>
            <Card>
              <Form layout="vertical" onFinish={onSubmit} form={form}>
                <Form.Item
                  hasFeedback
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input
                    id="title"
                    type="text"
                    placeholder="Notification Title"
                  />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  label="Notification Type"
                  rules={[
                    {
                      required: true,
                      message: "Notification Type is Required!",
                    },
                  ]}
                  name="commissionType"
                  initialValue={notificationType}
                >
                  <Select
                    options={[
                      { value: "NULL", label: "NONE" },
                      { value: "PRODUCTS", label: "PRODUCT" },
                      { value: "CAMPAIGNS", label: "CAMPAIGN" },
                      { value: "BRANDS", label: "BRAND" },
                      { value: "CATEGORIES", label: "CATEGORY" },
                      { value: "SHOPS", label: "SHOP" },
                    ]}
                    placeholder="Enter Notification Type"
                    onChange={(val) => setNotificationType(val)}
                  />
                </Form.Item>

                {notificationType === "PRODUCTS" && (
                  <>
                    <Form.Item name="product_search">
                      <Select
                        allowClear
                        showSearch
                        placeholder="Filter by product"
                        optionFilterProp="children"
                        onChange={(val) => setTypeId(val)}
                        onSearch={(e) => handleSearch(e, "product")}
                        filterOption={() => {
                          return true;
                        }}
                        options={productOptions?.list}
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {notificationType === "SHOPS" && (
                  <>
                    <Form.Item name="shop_search">
                      <Select
                        allowClear
                        showSearch
                        placeholder="Filter by Shop"
                        optionFilterProp="children"
                        onChange={(val) => setTypeId(val)}
                        onSearch={(e) => handleSearch(e, "shop")}
                        filterOption={() => {
                          return true;
                        }}
                        options={shopOptions?.list}
                      ></Select>
                    </Form.Item>
                  </>
                )}

                {notificationType === "BRANDS" && (
                  <>
                    <Form.Item name="brand_search">
                      <Select
                        allowClear
                        showSearch
                        placeholder="Filter by Brand"
                        optionFilterProp="children"
                        onChange={(val) => setTypeId(val)}
                        onSearch={(e) => handleSearch(e, "brand")}
                        filterOption={() => {
                          return true;
                        }}
                        options={brandOptions?.list}
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {notificationType === "CAMPAIGNS" && (
                  <>
                    <Form.Item name="campaign_search">
                      <Select
                        allowClear
                        showSearch
                        placeholder="Filter by Campaign"
                        optionFilterProp="children"
                        onChange={(val) => setTypeId(val)}
                        onSearch={(e) => handleSearch(e, "campaign")}
                        filterOption={() => {
                          return true;
                        }}
                        options={campaignOptions?.list}
                      ></Select>
                    </Form.Item>
                  </>
                )}
                {notificationType === "CATEGORIES" && (
                  <>
                    <Form.Item name="category_search">
                      <TreeSelectSlugCat setCategoryId={setTypeId} />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  hasFeedback
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Message is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    id="message"
                    placeholder="Notification Message"
                  />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  label="Image URL"
                  name="imageUrl"
                  rules={[
                    {
                      required: false,
                      message: "Image url is required",
                    },
                  ]}
                >
                  <Input
                    id="imageUrl"
                    type="text"
                    placeholder="Pest image url"
                    onChange={(e: any) => setGeneratedImgUrl(e.target.value)}
                  />
                </Form.Item>
                <p>Or Upload Image</p>
                <div className="">
                  <ImageUploader
                    imgUrl={generatedImgUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>

                <div className="mt-10">
                  <SubmitResetBtn
                    onClickSubmit={(e) => {
                      e.preventDefault();
                      setStartUpload("Uploading");
                    }}
                    onClickReset={() => {
                      form?.resetFields();
                      setGeneratedImgUrl(undefined);
                      setStartUpload(undefined);
                    }}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PushNotifications;
