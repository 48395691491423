import React from "react";
import CorporateLayout from "../Layout";
import { useSelector } from "react-redux";
import BlanceHistoryList from "../../Finance/CompanyBlanceHistory";

const CorporateBlanceHistoryList = () => {
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const { corporateId } = corporateInfo?.id;
  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={corporateId as any}
        title="Company Balance History List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <BlanceHistoryList getComanyId={corporateId} />
          </div>
        </div>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateBlanceHistoryList;
