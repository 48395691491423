import axios from "axios";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { responseNotification } from "../../../utils/notify";
import { useParams } from "react-router";
import ShoppingMallLayout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { SET_MALL_INFO } from "../../../redux/auth/authType";
import Loader from "../../common/Loader";
import ShoppingMallShopList from "../../Shop";
import { Button, Form, Modal, Select } from "antd";
import { debounce } from "lodash";

const ShoppingMallDetails = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const route = useParams();
  const [shopId, setShopId] = useState();
  const [singleShoppingMallInfo, setSingleShoppingMallInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const shoppingMall = singleShoppingMallInfo?.data;

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=100` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { name: any; id: any }) => ({
              label: shop.name,
              value: shop.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const fetchShoppingMallDetails = useCallback((getShoppingMallId: any) => {
    if (getShoppingMallId) {
      try {
        setSingleShoppingMallInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shopping-mall/details?mallId=${getShoppingMallId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleShoppingMallInfo({
                loading: false,
                data: data?.data?.shoppingMall,
              });
              dispatch({
                type: SET_MALL_INFO,
                payload: {
                  mallInfo: {
                    id: data?.data?.shoppingMall?.id,
                    name: data?.data?.shoppingMall?.name,
                    address: data?.data?.shoppingMall?.address,
                    image: data?.data?.shoppingMall?.image,
                  },
                },
              });
            } else {
              setSingleShoppingMallInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShoppingMallInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleShoppingMallInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  useEffect(() => {
    fetchShoppingMallDetails((route as any)?.mallId);
  }, [fetchShoppingMallDetails]);


  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 500);
  }, [getShopOptions]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      mallId: shoppingMall?.id,
      shopId: shopId || data.shopId,
    };
    await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/mall-shop`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Shop Assigned Successfully", "success");
          form.resetFields();
          setOpen(false);
          setRefetch(true);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    getShopOptions("");
  }, [getShopOptions]);

  if (singleShoppingMallInfo.loading) return <Loader />;

  return (
    <React.Fragment>
      <ShoppingMallLayout
        mallId={shoppingMall?.id}
        title={`Shopping Mall Details`}
        subTitle={`Details`}
        extra={
          <>
            <Button
              type="dashed"
              shape="round"
              className="bg-white"
              onClick={() => setOpen(true)}
            >
              Assign Shop
            </Button>
          </>
        }
      >
        <div className="content-body rounded-2xl">
          <div className="bg-white">
            <ShoppingMallShopList
              refetch={refetch}
              inShoppingMall={true}
              getShoppingMallId={shoppingMall?.id}
            />
          </div>
        </div>
      </ShoppingMallLayout>

      <Modal
        title="Assign Shop"
        centered
        open={open}
        okText="Assign Shop"
        onCancel={() => setOpen(!open)}
        width={400}
        okButtonProps={{ disabled: !shopId, htmlType: "submit" }}
        onOk={onSubmit}
      >
        <div>
          <Form
            onFinish={onSubmit}
            name="control-hooks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form} // like ref
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="search">
              <Select
                filterOption={() => {
                  return true;
                }}
                showSearch
                onSearch={(e) => handleSearch(e, "shop")}
                onChange={(val) => setShopId(val)}
                options={shopOptions?.list}
                loading={shopOptions?.loading}
                placeholder="Select Shop"
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

ShoppingMallDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShoppingMallDetails;
