import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleState = ({
  StateDetails,
}: {
  StateDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">

        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">State Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{StateDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">State Code</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{StateDetails?.state_code}</span>
            </div>
          </div>
        </div>
  
      </div>
    </>
  );
};

export default SingleState;
