import { Image, Tag } from "antd";
import React from "react";
import { ReactElement } from "react";

const SingleProgram = ({ programDetails }: { programDetails: any }): ReactElement => {
  return (
    <>
      <div className="">
        <h1>Banner Image</h1>
        <Image
          // height={140}
          width="auto"
          src={programDetails?.uploadDetails?.bannerImageUrl}
          className="rounded-lg shadow-lg"
        />
        <h1>Banner Image</h1>
         <Image
          // height={140}
          width="auto"
          src={programDetails?.uploadDetails?.imageUrl}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{programDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{programDetails?.type}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Video URL</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{programDetails?.uploadDetails?.videoUrl}</span>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default SingleProgram;
