import { Button, Card, Form, Input } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";

const AddKeyword = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [keywordData, setKeywordData] = useState(undefined);
  const [error, setError] = useState("");

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      keywords: [
        {
          barCode: keywordData?.barCode,
          keyword: data.keyword,
        },
      ],
    };

    if (keywordData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/product/keywords`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            // barCode: keywordData?.barCode,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Keyword Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setKeywordData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (keywordData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [keywordData]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...keywordData,
          }}
        >
          <Card size="small" style={{ minWidth: "100%", marginBottom: 25 }}>
            <p className="text-center mb-0">
              <b>N.B:</b> Every Key must be seperate by comma(",")
            </p>
          </Card>
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
            style={{ minWidth: "100%" }}
          >
            <Input
              id="name"
              type="text"
              placeholder="Enter Search Name"
              readOnly
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Keyword"
            rules={[
              {
                required: true,
                message: "Keyword is Required!",
              },
            ]}
            name="keyword"
            style={{ minWidth: "100%" }}
          >
            <Input.TextArea
              id="keyword"
              rows={8}
              placeholder="Enter Product Keyword"
            />
          </Form.Item>

          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>

            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddKeyword;
