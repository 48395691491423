import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
  import { AutoComplete, Avatar, Button, Drawer, Empty, Form, Input, Popconfirm, Select, Spin, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue, jcTypeArray } from "../../utils";
  import { useLocation } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleCountry from "./Details";
  import AddCountry from "./AddCountry";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
  
  const CountryList = (): ReactElement => {
    const {  token } = useSelector((state) => (state as any)?.authReducer);
    const initialState = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const [form] = Form.useForm();
    const [showSearch, setShowSearch] = useState(true);
    const [isForLanding, setIsForLanding] = useState<boolean>(false);
    const [visible, setVisible] = useState<any>(undefined);
    const [key, setKey] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [selectedCountryForEdit, setSelectedCountryForEdit] = useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [type, setType] = useState(initialState?.type);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [countryData, setCountryData] = useState<any>({
      loading: false,
      data: null,
    });
   
    const [countryOptions, setCountryOptions] = useState({
      loading: false,
      list: [],
    });

    const getCountrys = useCallback(async () => {
      setCountryData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/country-state/countries?limit=${limit}`+
          (page
            ? `&page=${
                page == countryData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (key ? `&key=${key}` : ``) ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setCountryData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setCountryData({ loading: false, data: [] });
          console.error("Countrys: Error", err);
        });
    }, [limit,page,key]);
  
    const getCountryOptions = useCallback(
      async (key: any) => {
        setCountryOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
  
        return axios
          .get(`${encodedUri}/admin/country-state/countries${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setCountryOptions({
              loading: false,
              list: res.data?.countries?.map((countrie: { name: any }) => ({
                label: countrie.name,
                value: countrie.name,
              })),
            });
          })
          .catch((err) => {
            setCountryOptions({ loading: false, list: [] });
            console.error("Countrys: Error", err);
          });
      },
      [key,page, limit]
    );
    const onCountryRemove = async (id: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-country`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Country Remove Successfully", "success");
              getCountrys();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    //

    const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "country") getCountryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions]);

  useEffect(() => {
    if (showSearch) {
      getCountryOptions("");
    }
  }, [showSearch]);

    useEffect(() => {
      getCountrys();
    }, [getCountrys]);
  
    const onClose = () => {
      getCountrys();
      setVisible(undefined);
      setSelectedCountry(undefined);
      setSelectedCountryForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
    const reseAllFieldData = () => {
      form.resetFields();
      setKey("");
    };

    return (
      <>
        <BreadCrumb
          title="Country List"
          subTitle={`${
            countryData?.data?.totalElements ||
            countryData?.data?.countries?.length ||
            0
          } Country(s)`}
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
          <Form.Item name="search" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "country")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={countryOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  countryOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={countryOptions.loading}
                />
              </AutoComplete>
          </Form.Item>                 
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {countryData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Country</th>
                        <th scope="col">Capital</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {countryData?.data?.countries?.length ? (
                        countryData?.data?.countries?.map(
                          (country: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedCountry(country)}
                            > 
                              <td>
                                <Avatar size={40} src={country?.currency_symbol} />
                                <span className="font-medium text-gray-500 ml-2">
                                  {country?.name}
                                </span>
                              </td>
                              <td>
                              <span className="font-medium text-gray-500 ml-2">
                                  {country?.capital}
                                </span>
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(country?.updatedAt, "minutes") >=
                                    60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(country?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(country?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {country?.updatedBy?.name ||
                                    country?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedCountryForEdit(country)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={country?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    console.log(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCountryRemove(country?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(country?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                               
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...countryData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Country"
              : selectedCountryForEdit
              ? "Edit Country"
              : "Country Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedCountry || selectedCountryForEdit}
          width={450}
        >
          {visible || selectedCountryForEdit ? (
            <AddCountry
              onCloseMethod={onClose}
              visibleData={selectedCountryForEdit}
            />
          ) : (
            <SingleCountry CountryDetails={selectedCountry} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default CountryList;
  