import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card, 
  Col,
  Empty,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import axios from "axios";
import { debounce } from "lodash";
import { responseNotification } from "../../utils/notify";
import { getParamValue } from "../../utils";
import moment from "moment";
import { Loader } from "../common";

interface EmptyProps {
  height?: string;
  data?: string;
}
const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const contendStyle: React.CSSProperties = {
  backgroundColor: "#F8F8F8",
};
const gridStyle1: React.CSSProperties = {
  width: "60%",
  textAlign: "center",
};
const gridStyle2: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
};
const totalData = [
  "Small Package 10 x 10 x 5 cm, 1 kg",
  "Medium Package 12 x 12 x 12 cm, 3 kg",
  "Large Package 20 x 20 x 20 cm, 10 kg",
];
const DeliveryShippingSettings = ({ height = "100%", data }: EmptyProps) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [size, setSize] = useState<SizeType>("large");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const sortBy = "name";
  const [selectedDisciplineForEdit, setSelectedDisciplineForEdit] =
  useState(undefined);
  const [shippingDeliveryPackeging, setShippingDeliveryPackeging] =
    useState<any>();
    const [zonesOptions, setZonesOptions] = useState<any>({
      list: [],
      loading: false,
    });
    console.log('zonesOptions:=>',zonesOptions);
    const [zoneData, setZoneData] = useState<any>({
      loading: false,
      data: null,
    });
console.log('zoneData :=>',zoneData);
    const getZonesOptions = useCallback(async (val?: string) => {
      setZonesOptions({ loading: true, list: [] });
  
      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/zone/all`,
  
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setZonesOptions({
        loading: false,
        list: res?.data?.zones?.map((zone: any) => {
          return {
            value: zone?.id,
            label: zone?.name,
          };
        }),
      });
    }, []);

    const getZones = useCallback(async () => {
      setZoneData({ loading: true, data: null });
      const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
      axios
        .get(
          `${encodedUri}/delivery-zone/all?sortBy=${sortBy}` +
            (page
              ? `&page=${
                  page == zoneData.data?.currentPageNumber ? 0 : page || 0
                }`
              : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setZoneData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setZoneData({ loading: false, data: [] });
          console.error("discipline: Error", err);
        });
    }, [type,limit, page]);
  
    useEffect(() => {
      getZones();
    }, [getZones]);
  

    useEffect(() => {
      getZonesOptions();
    }, [getZonesOptions]);
    const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "zone") getZonesOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getZonesOptions, type]);

  return (
    <>
      <BreadCrumb title={`Referral & Share`} extra={false} />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            className="py-2 inline-block min-w-full sm:px-6 lg:px-8 "
            style={contendStyle}
          >
            <div className="content-body">
              <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                Shipping & Delivery Settings 
              </h3>
              <>
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  form={form} // like ref
                  layout="horizontal"
                >
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Type"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Type is Required!",
                        },
                      ]}
                      name="shippingDeliveryType"
                      initialValue={shippingDeliveryType}
                    >
                      <Radio.Group>
                        <Radio value={1}>Fulfillment By Jachai (FBJ)</Radio>
                        <Radio value={2}>Fulfillment By Seller (FBS)</Radio>
                        <Radio value={3}>Fulfillment By Agent/Referral</Radio>
                        <Radio value={4}>Fulfillment By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="px-8">
                    <Form.Item
                      hasFeedback
                      label="Shipping & Delivery Packeging"
                      rules={[
                        {
                          required: true,
                          message: "Shipping & Delivery Packeging is Required!",
                        },
                      ]}
                      name="shippingDeliveryPackeging"
                      initialValue={shippingDeliveryPackeging}
                    >
                      <Radio.Group>
                        <Radio value={10}>Packaging By Jachai (PBJ)</Radio>
                        <Radio value={20}>Packaging By Seller (PBS)</Radio>
                        <Radio value={30}>Packaging By Agent/Referral</Radio>
                        <Radio value={40}>Packaging By Delivery Hero</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Shipping
                  </h3>
                  <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                    Choose Where you ship and how much you charge for shipping
                    at checkout.
                  </p>

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    General Shipping Rates
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-1/4 border-r-2 h-full font-medium">
                      <h4 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                        General
                      </h4>
                      <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                        All Product Category
                      </p>
                    </p>
                    <p className="float-left px-4 w-1/2 text-center font-medium">
                      No Shipping rates for customers to choose from
                    </p>
                    <p className="float-left px-4 w-1/4 text-right font-medium">
                      <Link
                        title="Edit Product"
                        to={`/delivery-shipping/shipping-rates`}
                        //target="_blank"
                      >
                        Manage
                      </Link>
                    </p>
                  </Card>

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Custom Shipping Rates
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      Add custom rates or destination restrictions for groups of
                      products.
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                    <Link
                        title="Edit Product"
                        to={`/delivery-shipping/shipping-profile`}
                        //target="_blank"
                      >
                      Create New Profile
                      </Link>
                    </p>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Processing time
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      Show delivery dates to your customer by setting a
                      processing time. If you process orders in 2 business days
                      or less. This will be added to the transit time of your
                      shipping rates.
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                    <Link
                        title="Edit Product"
                        to={`/delivery-shipping/processing-time`}
                        //target="_blank"
                      >
                      Manage
                      </Link>
                    </p>
                  </Card> 

                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Delivery
                  </h3>
                  <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                    Deliver orders directly to customers in the area.
                  </p>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-1/2 h-full font-medium">
                      <p className="float-left">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-5xl"
                        />
                      </p>
                      <p className="float-left">
                        <h5 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                          Shop Location/Seller Address
                        </h5>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                          Banglades
                        </p>
                      </p>
                    </p>
                    <p className="float-left px-4 w-1/4 text-center font-medium">
                      Doesn’t offer delivery
                    </p>
                    <p className="float-left px-4 w-1/4 text-right font-medium">
                      Manage
                    </p>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local delivery zone
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-4/5 h-full font-medium">
                    <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {zoneData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Country Name</th>
                      <th scope="col">Country Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">City</th>
                      <th scope="col">Action</th>
                      <th scope="col">Created Info</th>
                      <th scope="col">Updated Info</th>
                    </tr>
                  </thead>

                  <tbody>
                    {zoneData?.data?.deliveryZones?.length ? (
                      zoneData?.data?.deliveryZones?.map(
                        (dzone: any, dindex: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={dindex}
                            //onClick={() => setSelectedDiscipline(discipline)}
                            draggable="true"
                          >
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {dzone?.country?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {dzone?.country?.countryName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                              {dzone?.countryCode}
                              </span>
                            </td>
                        
                        
                            <td>
                            <Link
                                title="Edit Book"
                                to={`/delivery-shipping/${dzone?.id}/edit`}
                                className="py-2 px-4 rounded-full leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EditOutlined />
                              </Link>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {dzone?.createdBy?.name ||
                                  dzone?.createdBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    dzone?.createdAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(dzone?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(dzone?.createdAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <small className="font-bold uppercase text-gray-500">
                                {dzone?.updatedBy?.name ||
                                  dzone?.updatedBy?.mobileNumber}{" "}
                                -{" "}
                              </small>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    dzone?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(dzone?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(dzone?.updatedAt).format("lll")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
                     
                    </div>
                    </div>

                    <div className="float-left px-4 w-1/5 text-right font-medium">
                    <Link
                        title="Edit Product"
                        to={`/delivery-shipping/shipping-zone`}
                        //target="_blank"
                      >
                      Create Zone
                      </Link>
                    </div>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Pickup
                  </h3>
                  <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                    Allow local Customers to pick up their orders.
                  </p>
                  <Card className="mx-8 my-4 rounded-xl">
                    <p className="float-left w-4/5 h-full font-medium">
                      <p className="float-left">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="text-5xl"
                        />
                      </p>
                      <p className="float-left">
                        <h5 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                          Shop Location/Seller Address
                        </h5>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary px-8">
                          Banglades
                        </p>
                      </p>
                    </p>

                    <p className="float-left px-4 w-1/5 text-right font-medium">
                      Manage
                    </p>
                  </Card>
                  <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                    Local Pickup Zones
                  </h3>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-4/5 h-full font-medium">
                      <div className="float-left w-1/12">
                        <Avatar
                          shape="square"
                          size={64}
                          icon={<UserOutlined />}
                        />
                      </div>
                      <div className="float-left w-11/12">
                        <div className="w-1/5">
                          <Form.Item
                            hasFeedback
                            label="Domestic"
                            rules={[
                              {
                                required: true,
                                message: "Domestic is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add Shipping Rate
                          </Button>
                        </div>
                        <div className="w-1/4">
                          <Form.Item
                            hasFeedback
                            label="Not covered by your shipping zones"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Not covered by your shipping zones is Required!",
                              },
                            ]}
                            name="campaignType"
                          >
                            <Select
                              options={[
                                { value: "DISCOUNT", label: "DISCOUNT" },
                                { value: "BOGO", label: "BUY ONE GET ONE" },
                                { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                                { value: "GROUP_BUY", label: "GROUP BUY" },
                                {
                                  value: "PURCHASE_VOUCHER",
                                  label: "PURCHASE VOUCHER",
                                },
                                {
                                  value: "FREE_DELIVERY",
                                  label: "FREE DELIVERY",
                                },
                              ]}
                              placeholder="Enter Campaign Type"
                            />
                          </Form.Item>
                        </div>
                        <div className="w-1/5">
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size={size}
                            className="rounded-md"
                            style={{ backgroundColor: "#267E94" }}
                          >
                            Add more shipping places
                          </Button>
                        </div>
                        <p>
                          Add countries/regions to a market to start selling and
                          manage localized settings, including shipping zones.
                        </p>
                      </div>
                    </div>

                    <div className="float-left px-4 w-1/5 text-right font-medium">
                      Create Zone
                    </div>
                  </Card>

                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-4/5 text-left">
                        Saved Packages
                      </p>

                      <p className="float-left w-1/5 text-right">
                      <Link
                        title="Edit Product"
                        to={`/delivery-shipping/add-package`}
                        // target="_blank"
                      >
                        Add Package
                        </Link>
                        </p>

                    </div>
                    <div className="float-left w-full">
                      <List
                        bordered
                        dataSource={totalData}
                        renderItem={(item) => (
                          <List.Item>
                            <p className="float-left w-11/12 text-left">
                              {item}
                            </p>
                            <p className="float-left w-1/12 text-right">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="text-2xl"
                              />
                            </p>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Card>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full flex">
                      <p className="float-left w-4/5 text-left">
                        <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary">
                          Carrier Shipping
                        </h3>
                        <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                          Buy and print discounted shipping labels from Jachai
                          shipping.
                        </p>
                      </p>
                      <p className="float-left w-1/5 text-right">
                        Calculate shipping rates
                      </p>
                    </div>
                    <div className="float-left w-full mt-4">
                      <List
                        bordered
                        dataSource={totalData}
                        renderItem={(item) => (
                          <List.Item>
                            <p className="float-left w-4/5 text-left">
                              <FontAwesomeIcon
                                icon={faCableCar}
                                className="text-2xl"
                              />{" "}
                              {item}
                            </p>
                            <p className="float-left w-1/5 text-right">
                              Discounts up to 82% off
                            </p>
                          </List.Item>
                        )}
                      />
                      <p className="mt-0 font-medium leading-tight text-primary">
                        Check hardware compatibility by printing Test shipping
                        labels. To buy shipping supplies.
                      </p>
                    </div>
                  </Card>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-full text-left">
                        Carrier Accounts
                      </p>
                    </div>
                    <hr className="w-full h-1 mx-auto mt-10 bg-gray-100 border-0 rounded md:mt-10 dark:bg-gray-700" />
                    <div className="float-left w-full">
                      <p className="float-left w-full text-left">
                        If you have an existing account with a shipping carrier,
                        connect it to your seller to use your won rates
                      </p>
                      <Button
                        type="primary"
                        size={size}
                        className="rounded-md mt-4 text-black"
                        style={{ backgroundColor: "#FFFFFF" }}
                      >
                        Connect Carrier Account
                      </Button>
                    </div>
                  </Card>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-4/5 text-left">
                        Packing slip Template
                      </p>
                      <p className="float-left w-1/5 text-right">
                        Edit Template
                      </p>
                    </div>
                    <hr className="w-full h-1 mx-auto mt-10 bg-gray-100 border-0 rounded md:mt-10 dark:bg-gray-700" />
                    <div className="float-left w-full">
                      <p className="mb-2 font-medium text-primary">
                        Customize the packing slips you can print when you’re
                        fulfilling orders.
                      </p>
                    </div>
                  </Card>
                  <Card className="mx-8 my-4 rounded-xl">
                    <div className="float-left w-full pb-4">
                      <p className="float-left w-4/5 text-left">
                        Custom order fulfillment
                      </p>
                      <p className="float-left w-1/5 text-right">
                        Add Fulfillment service
                      </p>
                    </div>
                    <hr className="w-full h-1 mx-auto mt-10 bg-gray-100 border-0 rounded md:mt-10 dark:bg-gray-700" />
                    <div className="float-left w-full">
                      <p className="mb-2 font-medium text-primary">
                        Add an email for a custom fulfillment service that
                        fulfills orders for you.
                      </p>
                    </div>
                  </Card>
                </Form>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryShippingSettings;
