import {
  AutoComplete,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  TimePicker,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageUploader from "../../common/ImageUploader";
import MapBox from "../../common/MapBox";
import { regions } from "../../common/Region";

const WareHouseInfo = () => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [businessHourFrom, setBusinessHourFrom] = useState<any>("00:00");
  const [businessHourTo, setBusinessHourTo] = useState<any>("00:00");
  const [storeLogo, setStoreLogo] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [key, setKey] = useState("");
  const [form] = Form.useForm();

  // **************************************
  const [geoAddress, setGeoAddress] = useState<any>({
    loading: false,
    data: null,
  });

  const [locationOptions, setLocationOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [locationListOptions, setLocationListOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [currentLocation, setCurrentLocation] = useState({
    lat: 23.77340239600077,
    lng: 90.41329051290532,
  });

  const getLocationOptions = useCallback(async (key?: string) => {
    setLocationOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_MAP_API}`;
    const res = await axios.get(
      `${encodedUri}/autocomplete` + (key ? `?key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLocationOptions({
      loading: false,
      list: res?.data?.places,
    });

    setLocationListOptions({
      loading: false,
      list: res?.data?.places?.map((place: any) => {
        return {
          value: place?.address,
          label: place?.address,
        };
      }),
    });
  }, []);

  const fetchGeoAddress = useCallback(() => {
    try {
      setGeoAddress({ loading: true, data: null });
      axios
        .get(
          `${process.env.REACT_APP_MAP_API}/geocode?latitude=${currentLocation?.lat}&longitude=${currentLocation?.lng}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setGeoAddress({
              loading: false,
              data: data?.data?.place,
            });
          } else {
            setGeoAddress({ loading: false, data: null });
          }
        })
        .catch((err) => {
          setGeoAddress({ loading: false, data: null });

          console.log(err);
        });
    } catch (error) {
      setGeoAddress({ loading: false, data: null });
      console.log(error, "error");
    }
  }, [currentLocation?.lat, currentLocation?.lng]);

  useEffect(() => {
    fetchGeoAddress();
  }, [fetchGeoAddress, currentLocation]);

  useEffect(() => {
    form.resetFields(["latitude", "longitude", "address"]);
  }, [currentLocation]);

  useEffect(() => {
    form.resetFields(["address"]);
  }, [geoAddress]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        wareHouseLocation: {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        },
      });
    }
  }, [currentLocation, storeLogo, businessHourFrom, businessHourTo]);

 

  // **************************************

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-6">
        <div className="bg-slate-50 rounded-md p-4 shadow">
          <div className="mb-6 flex justify-between border-b">
            <h4 className="text-lg font-bold text-gray-600">
              Warehouse Address
            </h4>
            <Form.Item hasFeedback name="wareHouseAddressIsSameAsStoreAddress">
              <Checkbox value={true}>Same As Store Address</Checkbox>
            </Form.Item>
          </div>

          <div className="grid_grid-cols-2_gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Address"
                name="address"
                initialValue={geoAddress?.data?.address}
              >
                <AutoComplete
                  autoClearSearchValue={false}
                  allowClear
                  showSearch
                  onSearch={(val) => {
                    getLocationOptions(val);
                    setKey(val);
                  }}
                  onSelect={(val: any) => {
                    const matchData = locationOptions?.list?.find(
                      (place: any) => val === place.address
                    );
                    setCurrentLocation({
                      lat: matchData?.location?.latitude,
                      lng: matchData?.location?.longitude,
                    });
                  }}
                  options={locationListOptions?.list}
                  notFoundContent={
                    locationListOptions?.loading ? <Spin size="small" /> : null
                  }
                  // loading={locationListOptions.loading}
                  placeholder="Search Address"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                initialValue="Dhaka"
              >
                <Select
                  showSearch
                  placeholder="Filter District"
                  optionFilterProp="children"
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(regions.Dhaka)?.map((district, i) => (
                    <Select.Option value={district} key={i}>
                      {district}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="City"
                name="city"
                initialValue="Dhaka"
              >
                <Select
                  showSearch
                  placeholder="Filter by Status"
                  optionFilterProp="children"
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(regions.City)?.map((city, i) => (
                    <Select.Option value={city} key={i}>
                      {city}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item hasFeedback label="Postcode" name="postcode">
                <Input id="postcode" type="text" placeholder="Enter Postcode" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="bg-slate-50 rounded-md p-4 shadow">
          <div className="mb-6 flex justify-between border-b">
            <h4 className="text-lg font-bold text-gray-600">Return Address</h4>

            <Form.Item name="returnAddressIsSameAsStoreAddress">
              <Checkbox value={true}>Same As Store Address</Checkbox>
            </Form.Item>
          </div>

          <div className="grid_grid-cols-2_gap-x-6">
            <div>
              <Form.Item
                hasFeedback
                label="Address"
                name="returnAddress"
                initialValue={geoAddress?.data?.address}
              >
                <AutoComplete
                  autoClearSearchValue={false}
                  allowClear
                  showSearch
                  onSearch={(val) => {
                    getLocationOptions(val);
                    setKey(val);
                  }}
                  onSelect={(val: any) => {
                    const matchData = locationOptions?.list?.find(
                      (place: any) => val === place.address
                    );
                    setCurrentLocation({
                      lat: matchData?.location?.latitude,
                      lng: matchData?.location?.longitude,
                    });
                  }}
                  options={locationListOptions?.list}
                  notFoundContent={
                    locationListOptions?.loading ? <Spin size="small" /> : null
                  }
                  // loading={locationListOptions.loading}
                  placeholder="Search Address"
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                hasFeedback
                label="District"
                name="returnDistrict"
                initialValue="Dhaka"
              >
                <Select
                  showSearch
                  placeholder="Filter District"
                  optionFilterProp="children"
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(regions.Dhaka)?.map((district, i) => (
                    <Select.Option value={district} key={i}>
                      {district}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                hasFeedback
                label="City"
                name="returnCity"
                initialValue="Dhaka"
              >
                <Select
                  showSearch
                  placeholder="Filter by Status"
                  optionFilterProp="children"
                  filterOption={() => {
                    return true;
                  }}
                >
                  {Object.values(regions.City)?.map((city, i) => (
                    <Select.Option value={city} key={i}>
                      {city}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item hasFeedback label="Postcode" name="returnPostcode">
                <Input
                  id="returnPostcode"
                  type="text"
                  placeholder="Enter Postcode"
                />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="bg-white mb-6 col-span-2">
          <h3 className="mb-4 text-xl font-bold">Address</h3>

          <div className="col-span-2">
            <Form.Item name="wareHouseLocation">
              <MapBox
                draggable={true}
                coordinate={currentLocation}
                setCoordinator={(val: any) => setCurrentLocation(val)}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WareHouseInfo;
