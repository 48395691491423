import React from "react";
import CountryStateList from "../../components/State";
import MainLayout from "../../components/Layouts";

const CountryStatePage = () => {
  return (
    <MainLayout>
      <CountryStateList />
    </MainLayout>
  );
};

export default CountryStatePage;
