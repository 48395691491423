import {
  Rate,
  Avatar,
  Layout,
  Button,
  Tabs,
  Empty,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Steps,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import axios from "axios";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router";
import { getImgUrl, ProductStatus } from "../../../utils";
import { Link } from "react-router-dom";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
const { Content } = Layout;
const { TabPane } = Tabs;
const { Step } = Steps;
const { confirm } = Modal;

const GlobalProductDetails = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const navigate = useNavigate();
  const route = useParams();
  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  const fetchProductDetails = useCallback((getSlug: any) => {
    if (getSlug) {
      try {
        setSingleProductInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/global-product/details?slug=${getSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleProductInfo({
                loading: false,
                data: data?.data?.product,
              });
            } else {
              setSingleProductInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleProductInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleProductInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, []);

  const onStatusChange = async (status: any) => {
    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/global-product/approve-product`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productIds: [product?.id],
          status: status,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          fetchProductDetails((route as any)?.slug);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  function showConfirm(status: any) {
    confirm({
      title: "Do you Want to change Status?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  useEffect(() => {
    fetchProductDetails((route as any)?.slug);
  }, [fetchProductDetails, route]);

  const product = singleProductInfo?.data;

  return (
    <React.Fragment>
      <main>
        <div className="grid grid-cols-1  xl:gap-6 xl:grid-cols-3 px-2 pt-4">
          <div className="col-span-full">
            <PageHeader
              className="site-page-header px-0 py-0"
              onBack={() => navigate(-1)}
              title={
                <h1 className="text-xl font-medium text-gray-900 sm:text-2xl">
                  Catalogue Details
                </h1>
              }
              extra={
                <>
                  <Button
                    type="dashed"
                    shape="round"
                    className="bg-white"
                    onClick={() =>
                      navigate(`/global-product/${product?.barCode}/edit`)
                    }
                  >
                    <EditOutlined />
                  </Button>
                  <Dropdown.Button
                    className="bg-white"
                    overlay={
                      <Menu>
                        {Object?.values(ProductStatus)?.map((status) => (
                          <Menu.Item
                            key={status}
                            onClick={(val) => {
                              showConfirm(val?.key);
                            }}
                          >
                            {status?.split("_").join(" ") || "Status"}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    {product?.status?.split("_").join(" ") || "Status"}
                  </Dropdown.Button>
                </>
              }
            />

            <nav className="flex mb-0" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-2">
                <li className="inline-flex items-center">
                  <Link
                    to="#"
                    className="inline-flex items-center text-gray-700 hover:text-gray-900"
                  >
                    Catalogue
                  </Link>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span
                      className="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                      aria-current="page"
                    >
                      Details
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-span-full xl:col-auto">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <div className="xl:block sm:space-x-4 xl:space-x-0">
                <img
                  src={getImgUrl(product?.productImage)}
                  className="mb-5 rounded-2xl _shadow-lg shadow-gray-300"
                />

                <div>
                  <h2 className="text-md font-medium">
                    {product?.name?.toUpperCase()}
                  </h2>

                  <Rate
                    value={product?.rating || 1}
                    className="mt-1"
                    disabled
                  />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                    {product?.numberOfRating || 0}
                  </span>
                  <ul className="mt-2 space-y-1">
                    <li className="flex items-center text-sm font-normal text-gray-500">
                      <span className="font-semibold mr-3">Total Visitor:</span>{" "}
                      <span className="text-xl">{product?.visitors || 0}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <div className="flow-root">
                <h3 className="text-xl font-bold mb-4">Images</h3>

                <div className="flex flex-wrap gap-4">
                  {product?.images?.length ? (
                    product?.images?.map((image: any, index: string) => (
                      <>
                        <Avatar
                          key={index}
                          src={image}
                          size={100}
                          shape="square"
                          className="rounded-lg shadow-md"
                        />
                      </>
                    ))
                  ) : (
                    <Empty />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <Layout>
              <Content className="main-content-layout">
                <div className="content-body_ rounded-2xl">
                  <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
                    <Tabs tabPosition="top">
                      <TabPane tab="GENERAL INFORMATION" key="GENERAL">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          <div className="col-span-1">
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Product Name
                            </dt>
                            <dd className="text-sm font-medium text-gray-800">
                              {product?.name?.trim()}
                            </dd>
                          </div>
                          <div className="">
                            <dt className="text-sm font-medium text-gray-900 mb-1">
                              Slug
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.slug}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Product ID
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.id}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Barcode
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.barCode}
                            </dd>
                          </div>
                          {product?.sku && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                SKU
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.sku}
                              </dd>
                            </div>
                          )}
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Category:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <Avatar src={product?.category?.image} />{" "}
                              {product?.category?.title}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Category Slug:
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              {product?.category?.slug}
                            </dd>
                          </div>

                          {product?.brand && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Brand:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                <Avatar src={product?.brand?.logo} />{" "}
                                {product?.brand?.name}
                              </dd>
                            </div>
                          )}

                          {product?.campaign && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Campaign:
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                <Avatar src={product?.campaign?.logo} />{" "}
                                {product?.campaign?.name}
                              </dd>
                            </div>
                          )}

                          {product?.city && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                City
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.city}
                              </dd>
                            </div>
                          )}

                          {product?.zone && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Zone
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.zone}
                              </dd>
                            </div>
                          )}

                          {product?.type && (
                            <div>
                              <dt className="text-sm font-medium text-gray-800 mb-1">
                                Type
                              </dt>
                              <dd className="text-sm font-medium text-gray-500">
                                {product?.type}
                              </dd>
                            </div>
                          )}

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Rattings
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <Rate value={product?.rating || 1} disabled />
                              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                                {product?.numberOfRating || 0}
                              </span>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Allow Warranty?
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isAllowWarranty ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isAllowWarranty
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Is Returnable?
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isReturnable ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isReturnable ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Exclude From COD
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.excludeCOD ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.excludeCOD ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Exclude From Promo
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.excludeFromPromo ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.excludeFromPromo
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              Booking Enabled
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.bookingEnabled ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.bookingEnabled
                                  ? "Enabled"
                                  : "Disabled"}
                              </div>
                            </dd>
                          </div>

                          <div>
                            <dt className="text-sm font-medium text-gray-800 mb-1">
                              isPopular
                            </dt>
                            <dd className="text-sm font-medium text-gray-500">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full bg-${
                                    product?.isPopular ? "green" : "red"
                                  }-500 mr-2`}
                                ></div>{" "}
                                {product?.isPopular ? "Active" : "Inactive"}
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </TabPane>

                      <TabPane tab="VARIATIONS" key="VARIATIONS">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                          {product?.variations?.length ? (
                            product?.variations?.map(
                              (variant: any, index: string) => (
                                <>
                                  <div
                                    key={index}
                                    className="flex flex-col p-3 max-w-md rounded-2xl shadow-sm transform cursor-move mb-5 bg-slate-50"
                                  >
                                    <div className="flex justify-start items- ncenter pb-4">
                                      <Avatar
                                        size={100}
                                        className="bg-contain rounded-2xl"
                                        src={variant?.imageUrl}
                                        alt="attachment"
                                      />
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <div className="font-medium">
                                        Variation Name:
                                      </div>
                                      <div className="text-sm font-normal text-gray-700">
                                        {variant?.variationName}
                                      </div>
                                    </div>

                                    {variant?.barCode && (
                                      <div className="flex justify-between py-1">
                                        <div className="font-medium">
                                          Barcode
                                        </div>
                                        <div className="text-sm font-normal text-gray-700">
                                          {variant?.barCode}
                                        </div>
                                      </div>
                                    )}

                                    {variant?.sku && (
                                      <div className="flex justify-between py-1">
                                        <div className="font-medium">SKU</div>
                                        <div className="text-sm font-normal text-gray-700">
                                          {variant?.sku}
                                        </div>
                                      </div>
                                    )}

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Variation Id
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.variationId}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">MRP</span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.price?.mrp}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">TP</span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.price?.tp}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>

                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">Stock</span>
                                      <span className="text-sm font-normal text-gray-700">
                                        <span
                                          className={`font-bold bg-white border rounded-full py-1 px-3 ${
                                            variant?.stock < 10
                                              ? `text-red-600`
                                              : `text-green-600`
                                          }`}
                                        >
                                          {variant?.stock}
                                        </span>
                                      </span>
                                    </div>

                                    {variant?.price?.discountedPrice && (
                                      <div className="flex justify-between py-1">
                                        <span className="font-medium">
                                          Discounted Price
                                        </span>
                                        <span className="text-sm font-normal text-gray-700">
                                          {variant?.price?.discountedPrice}{" "}
                                          {variant?.price?.currencyCode}
                                        </span>
                                      </div>
                                    )}

                                    {variant?.maximumOrderLimit && (
                                      <div className="flex justify-between py-1">
                                        <span className="font-medium">
                                          Maximum Order Limit
                                        </span>
                                        <span className="text-sm font-normal text-gray-700">
                                          {variant?.maximumOrderLimit}
                                        </span>
                                      </div>
                                    )}

                                    <Divider orientation="left">
                                      Product Discount
                                    </Divider>
                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Discount in Amount
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.productDiscount?.flat || 0}{" "}
                                        {variant?.price?.currencyCode}
                                      </span>
                                    </div>
                                    <div className="flex justify-between py-1">
                                      <span className="font-medium">
                                        Discount in Percentage
                                      </span>
                                      <span className="text-sm font-normal text-gray-700">
                                        {variant?.productDiscount?.percentage ||
                                          0}{" "}
                                        {"%"}
                                      </span>
                                    </div>
                                    {
                                      // data.item?.products?.length ?
                                      variant?.quantitativeProductDiscount
                                        ?.freeProduct ? (
                                        <div className="">
                                          <h6 className="mt-5 border-bottom pb-2">
                                            <b>
                                              Quantitative Product Discount:
                                            </b>
                                          </h6>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Free Product
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.freeProduct
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Minimum Quantity
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.minimumQuantity
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Product Discount in Amount
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.productDiscount?.flat
                                              }
                                            </span>
                                          </div>
                                          <div className="flex justify-between py-1">
                                            <span className="font-medium">
                                              Product Discount in Percent
                                            </span>
                                            <span className="text-sm font-normal text-gray-700">
                                              {
                                                variant
                                                  ?.quantitativeProductDiscount
                                                  ?.productDiscount?.percentage
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <Empty />
                          )}
                        </div>
                      </TabPane>

                      {product?.description && (
                        <TabPane tab="DESCRIPTION" key="DESCRIPTION">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Description
                              </dt>
                              <dd
                                className="mt-1 space-y-3 text-sm text-gray-500"
                                dangerouslySetInnerHTML={{
                                  __html: product?.description,
                                }}
                              ></dd>
                            </div>
                          </dl>
                        </TabPane>
                      )}

                      {product?.faq && (
                        <TabPane tab="FAQ" key="FAQ">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                FAQ's
                              </dt>
                              <dd
                                className="mt-1 space-y-3 text-sm text-gray-500"
                                dangerouslySetInnerHTML={{
                                  __html: product?.faq,
                                }}
                              ></dd>
                            </div>
                          </dl>
                        </TabPane>
                      )}

                      <TabPane tab="SEO" key="SEO">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                          {product?.metaTitle && (
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Meta Title
                              </dt>
                              <dd className="text-sm font-light text-gray-500">
                                {product?.metaTitle}
                              </dd>
                            </div>
                          )}
                          <div>
                            <dt className="text-md font-bold text-gray-700">
                              Meta Keyword
                            </dt>
                            <dd className="text-sm font-light text-gray-500">
                              {product?.keyword}
                            </dd>
                          </div>
                          {product?.metaDescription && (
                            <div>
                              <dt className="text-md font-bold text-gray-700">
                                Meta Description
                              </dt>
                              <dd className="text-sm font-light text-gray-500">
                                {product?.metaDescription}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </TabPane>

                      <TabPane tab="LOGS" key="LOGS">
                        <div className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 mb-10">
                          {product?.createdBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Created By
                              </div>
                              <Avatar
                                src={product?.createdBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.createdBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.createdBy?.mobileNumber?.replace(
                                  "+88",
                                  ""
                                )}
                              </div>
                            </div>
                          )}

                          {product?.updatedBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Updated By
                              </div>
                              <Avatar
                                src={product?.updatedBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.updatedBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.updatedBy?.mobileNumber}
                              </div>
                            </div>
                          )}

                          {product?.approvedBy && (
                            <div className="rounded-md border text-center p-4 max-w-5xl">
                              <div className="text-lg font-bold text-gray-700">
                                Approved By
                              </div>
                              <Avatar
                                src={product?.approvedBy?.profilePicture}
                                size={80}
                                className="shadow-md border my-4"
                              />
                              <div className="text-sm font-semibold text-gray-500">
                                {product?.approvedBy?.name}
                              </div>
                              <div className="text-sm font-light text-gray-500">
                                {product?.approvedBy?.mobileNumber}
                              </div>
                            </div>
                          )}
                        </div>
                        {product?.updatedLogs && (
                          <>
                            <h4 className="mb-4 font-bold text-lg text-sky-900 border-b pb-2 ">
                              Updated Logs
                            </h4>
                            <Steps direction="vertical" size="default">
                              {product?.updatedLogs?.map(
                                (data: any, i: string) => (
                                  <Step
                                    key={i}
                                    icon={
                                      <Avatar
                                        src={data?.updatedBy?.profilePicture}
                                        className="border shadow-md"
                                      />
                                    }
                                    title={data?.updatedBy?.name}
                                    subTitle={moment(data?.updatedAt).format(
                                      "lll"
                                    )}
                                    description={data?.updatedBy?.mobileNumber}
                                  />
                                )
                              )}
                            </Steps>
                          </>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </Content>
            </Layout>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

GlobalProductDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  selectedProduct: PropTypes.string,
};
export default GlobalProductDetails;
