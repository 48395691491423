import { Card, Col, Form, Input, Row, Switch } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { SubmitResetBtn } from "../common";
import BreadCrumb from "../Layouts/Breadcrumb";

const MerchantAppVersion = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [singleAppInfo, setSingleAppInfo] = useState<any>();
  const [form] = Form.useForm();

  const fetchMerchantAppDetails = useCallback(() => {
    try {
      setSingleAppInfo(null);
      axios
        .get(`${process.env.REACT_APP_AUTH_API}/app-version/merchant`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setSingleAppInfo(res.data);
            resetData();
          } else {
            setSingleAppInfo(null);
            responseNotification(
              res.statusText || "something went wrong",
              "error"
            );
          }
        })
        .catch((err) => {
          setSingleAppInfo(null);
          console.log(err);
        });
    } catch (error) {
      setSingleAppInfo(null);
      console.log(error, "error");
    }
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      currentAndroidAppVersion: data.currentAndroidAppVersion,
      forceUpdateRequiredForAndroid: data.forceUpdateRequiredForAndroid,
    };

    await fetch(`${process.env.REACT_APP_AUTH_API}/app-version/merchant`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Merchant App Version Update Successfully",
            "success"
          );
          fetchMerchantAppDetails();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    fetchMerchantAppDetails();
  }, [fetchMerchantAppDetails]);

  const resetData = () => {
    form?.resetFields();
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Merchant App Version" />
      <div>
        <Row
          gutter={25}
          align="middle"
          justify="center"
          className="product-table"
        >
          <Col span={12}>
            <Card>
              <Form
                name="control-hooks"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onSubmit}
                form={form} // like ref
                layout="vertical"
                initialValues={{
                  ...singleAppInfo?.appVersion,
                }}
              >
                <Form.Item
                  hasFeedback
                  label="Current Android App Version"
                  name="currentAndroidAppVersion"
                  rules={[
                    {
                      required: true,
                      message: "Current Android App Version required",
                    },
                  ]}
                >
                  <Input
                    id="currentAndroidAppVersion"
                    type="text"
                    placeholder="Current Android App Version"
                  />
                </Form.Item>

                <div className="">
                  <Form.Item
                    hasFeedback
                    label="Force Update Required For Android"
                    name="forceUpdateRequiredForAndroid"
                  >
                    <Switch
                      checkedChildren={`true`}
                      unCheckedChildren={`flase`}
                      defaultChecked={
                        singleAppInfo?.appVersion?.forceUpdateRequiredForAndroid
                      }
                    />
                  </Form.Item>
                </div>

                <SubmitResetBtn />
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MerchantAppVersion;
