import { Button, Empty, Popconfirm } from "antd";
import { ReactElement, useCallback, useEffect, useState } from "react";
import Loading from "../../common/Loader";
import axios from "axios";
import { responseNotification } from "../../../utils/notify";
import moment from "moment";
import { useSelector } from "react-redux";

const OrderPaymentLogs = ({ orderDetails }: any): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [paymentLogs, setPaymentLogs] = useState<any>({
    loading: false,
    data: [],
  });

  const getPaymentLogs = useCallback(async () => {
    setPaymentLogs({ loading: true, data: [] });
    const encodedUri = `${process.env.REACT_APP_PAY_API}`;
    axios
      .get(
        `${encodedUri}/admin/payment/transactions?orderId=${orderDetails?.orderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPaymentLogs({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPaymentLogs({ loading: false, data: [] });
        console.error("Payments: Error", err);
      });
  }, []);

  const onPaymentStatus = async (data: any) => {
    setLoading(true);

    await fetch(`${process.env.REACT_APP_PAY_API}/bkash/manual/success`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Payment Status Updated Successfully",
            "success"
          );
          getPaymentLogs();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  useEffect(() => {
    getPaymentLogs();
  }, [getPaymentLogs]);

  return (
    <>
      <div>
        {paymentLogs?.loading ? (
          <Loading />
        ) : (
          <table className="table-auto w-100">
            <thead>
              <tr className="bg-slate-50 py-4">
                <th className="p-2 text-left">Date & Time</th>
                <th className="p-2">Amount</th>
                <th className="p-2">Gateway</th>
                <th className="p-2">External Ref</th>
                <th className="p-2">Payment Ref ID</th>
                <th className="p-2">Status</th>
                <th className="p-2">Action</th>
                <th className="p-2">Approved By</th>
              </tr>
            </thead>
            {paymentLogs?.data?.payments?.length ? (
              paymentLogs?.data?.payments.map((payment: any, index: string) => (
                <tr key={index} className="py-3 border-t">
                  <td className="p-2">
                    {moment(payment?.createdAt).format("lll")}
                  </td>
                  <td className="p-2">&#2547;{payment?.amount}</td>
                  <td className="p-2">{payment?.gatewayName}</td>
                  <td className="p-2">{payment?.externalRef}</td>
                  <td className="p-2">{payment?.paymentRefId}</td>
                  <td className="p-2">
                    <span
                      className={`radius-btn ${payment?.status?.toLowerCase()}-btn`}
                    >
                      {payment?.status}
                    </span>
                  </td>
                  <td className="p-2">
                    <Popconfirm
                      title="Do you confirm, that you have received this amount of money?"
                      onConfirm={() =>
                        onPaymentStatus({
                          orderId: payment?.orderId,
                          amount: parseFloat(payment?.amount),
                          transactionId: payment?.paymentRefId,
                        })
                      }
                      okText="CONFIRM"
                      cancelText="NOT SURE"
                      disabled={
                        payment?.status === "FAILED" ||
                        payment?.status === "PAID" ||
                        parseFloat(orderDetails?.totalPaid) >=
                          parseFloat(orderDetails?.total)
                      }
                    >
                      <Button
                        size="small"
                        shape="round"
                        type="dashed"
                        // danger
                        disabled={
                          payment?.status === "FAILED" ||
                          payment?.status === "PAID" ||
                          parseFloat(orderDetails?.totalPaid) >=
                            parseFloat(orderDetails?.total)
                        }
                      >
                        APPROVE
                      </Button>
                    </Popconfirm>
                  </td>
                  <td className="p-2">
                    ...
                    {/* {payment?.user?.name} */}
                  </td>
                </tr>
              ))
            ) : (
              <Empty />
            )}
          </table>
        )}
      </div>
    </>
  );
};

export default OrderPaymentLogs;
