import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { Avatar, Button, Drawer, Empty } from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import { getPage } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddFeedbackType from "./AddFeedbackType";

const FeedbackTypeList = () => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);

  const [selectedFeedbackTypeForEdit, setSelectedFeedbackTypeForEdit] =
    useState(undefined);

  const [FeedbackTypeData, setFeedbackTypeData] = useState<any>({
    loading: false,
    data: null,
  });

  const getFeedbackType = useCallback(async () => {
    setFeedbackTypeData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/feedback-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFeedbackTypeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setFeedbackTypeData({ loading: false, data: [] });
        console.error("Feedback Type: Error", err);
      });
  }, []);

  useEffect(() => {
    getFeedbackType();
  }, [getFeedbackType]);

  useEffect(() => {
    if (visible) {
      setSelectedFeedbackTypeForEdit(undefined);
    }
  }, [selectedFeedbackTypeForEdit]);

  useEffect(() => {
    setSelectedFeedbackTypeForEdit(undefined);
  }, [visible]);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSelectedFeedbackTypeForEdit(null);
    setVisible(false);
    getFeedbackType();
  };

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(undefined);
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={`Feedback Types List`}
        subTitle={`${
          FeedbackTypeData?.data?.totalElements ||
          FeedbackTypeData?.data?.feedbackTypes?.length ||
          0
        } Feedback Types(s)`}
        extra={[
          // <Button
          //   type="dashed"
          //   shape="circle"
          //   onClick={() => setShowSearch(!showSearch)}
          //   key={1}
          // >
          //   <SearchOutlined />
          // </Button>,

          <AddBtn onClick={showDrawer} />,
          <Drawer
            destroyOnClose={true}
            title="Add Feedback Type"
            width={600}
            onClose={onClose}
            open={!!visible}
          >
            <AddFeedbackType onCloseMethod={onClose} />
          </Drawer>,
        ]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {FeedbackTypeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Feedback Type</th>
                      <th scope="col">Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {FeedbackTypeData?.data?.feedbackTypes?.length ? (
                      FeedbackTypeData?.data?.feedbackTypes?.map(
                        (feedbackType: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <Avatar
                                shape="square"
                                size={45}
                                src={feedbackType?.icon}
                                className="mr-3"
                              />
                              <span className="name">{feedbackType?.name}</span>
                            </td>

                            <td>
                              <span className="name">
                                {feedbackType?.type?.replace("_", " ")}
                              </span>
                            </td>

                            <td>
                              <Button
                                onClick={() =>
                                  setSelectedFeedbackTypeForEdit(feedbackType)
                                }
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...FeedbackTypeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Edit FeedbackType"
        width={500}
        onClose={onClose}
        open={selectedFeedbackTypeForEdit}
      >
        <AddFeedbackType
          visibleData={selectedFeedbackTypeForEdit}
          onCloseMethod={onClose}
        />
      </Drawer>
    </>
  );
};

export default FeedbackTypeList;
