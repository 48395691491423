import { Image, Button, Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";

const AddIssueType = ({ visibleData, onCloseMethod }: any) => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [typeData, setTypeData] = useState(undefined);
  const [iconUrl, setIconUrl] = useState(undefined);
  const [error, setError] = useState("");

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      icon: data.icon,
      name: data.name,
      displayOrder: data.displayOrder,
      type: data.type,
    };

    if (typeData) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/feedback-type`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: typeData?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Type Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/feedback-type`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Type Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setTypeData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (typeData) {
      form.resetFields(Object.keys(visibleData));
      setIconUrl(visibleData?.icon);
    }
  }, [typeData]);

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...typeData,
          }}
        >
          <Form.Item
            hasFeedback
            label="Type Name"
            rules={[
              {
                required: true,
                message: "Name Title is Required!",
              },
            ]}
            name="name"
            style={{ minWidth: "100%" }}
          >
            <Input id="name" type="text" placeholder="Enter Type Name" />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Type"
            rules={[
              {
                required: true,
                message: "Type is required!",
              },
            ]}
            name="type"
          >
            <Select placeholder="Type">
              <Select.Option value="TEXT">TEXT</Select.Option>
              <Select.Option value="LIST">LIST</Select.Option>
              <Select.Option value="BOOL">BOOL</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Display Order"
            rules={[
              {
                required: true,
                message: "Display Order is Required!",
              },
            ]}
            name="displayOrder"
          >
            <Input
              id="displayOrder"
              type="number"
              placeholder="Enter Display Order"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Icon"
            rules={[
              {
                required: true,
                message: "Icon Image is required!",
              },
            ]}
            name="icon"
          >
            <Input
              id="icon"
              type="text"
              placeholder="Enter icon Url"
              onChange={(e) => {
                setIconUrl(e.target.value);
              }}
            />
          </Form.Item>

          {iconUrl && (
            <div className="mt-2" style={{ minWidth: "100%" }}>
              <Image src={iconUrl} height={150} className="img img-thumbnail" />
              <p className="red-txt mt-1">{error}</p>
            </div>
          )}
          <div className="buttons-container">
            <Button
              disabled={loading || !!error}
              loading={(loading ? "loading" : undefined) as any}
              type="primary"
              htmlType="submit"
              className="add-submit-btn text-center mr-2"
            >
              Submit
            </Button>
            <Button
              type="ghost"
              htmlType="button"
              onClick={() => {
                form?.resetFields();
                setIconUrl(undefined);
              }}
              className="reset-submit-btn text-center mr-2"
            >
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddIssueType;
